import React, {useEffect, useState} from "react";
// import GrafImg from "../src/images/main-banner.png";
import Banner1 from "../src/imgs/banner-1.jpg";
import Banner2 from "../src/imgs/banner-2.jpg";
import Banner3 from "../src/imgs/banner-3.jpg";
import Banner4 from "../src/imgs/banner-4.jpg";

import promotionBanner1 from "../src/imgs/winkmarmo_banner1.jpg";
import unigrowBanner from "../src/imgs/unigrow_banner_1.jpg";
import medgenpharmaBanner from "../src/imgs/med_gen_pharam.jpg";

import axios from "axios";
import API_URL from "./config/apiConfig";
import { isEmpty } from 'lodash';
function Sliders() {

  const [sliderAll,setSliderAll] =  useState([]);

  const fetchPromotioMediaBanner = async () => {
    await axios
      .get(API_URL + "/promotion-media")
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === true) {
            setSliderAll(response.data.data);
          }
        }
      })
      .catch((err) => {
        console.log("Error ========== ", err);
      });
  };

    useEffect(() => {
      fetchPromotioMediaBanner();
    }, []);
  

  return (
    <section className="banner-wrape">
      <div className="carousel slide">
        {/* <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div> */}
        <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">



          <div className="carousel-inner text-center">
          {/* <div className="carousel-item active">
              <a href="https://medgenpharma.com/" target="_blank" rel="noreferrer">
                <img src={medgenpharmaBanner} className=" mx-auto slider-img" alt="..." />
              </a>
            </div>
            <div className="carousel-item">
              <a href="https://medgenpharma.com/" target="_blank" rel="noreferrer">
                <img src={medgenpharmaBanner} className=" mx-auto slider-img" alt="..." />
              </a>
            </div>

            <div className="carousel-item">
              <a href="https://winkmarmo.com/" target="_blank" rel="noreferrer">
                <img src={promotionBanner1} className=" mx-auto slider-img" alt="..." />
              </a>
            </div>
            <div className="carousel-item">
              <img src={Banner1} className=" mx-auto slider-img" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={Banner2} className=" mx-auto slider-img " alt="..." />
            </div>
            <div className="carousel-item">
              <img src={Banner3} className=" mx-auto slider-img " alt="..." />
            </div>
            <div className="carousel-item">
              <img src={Banner4} className=" mx-auto slider-img " alt="..." />
            </div>              */}




            {sliderAll && sliderAll.length > 0 && sliderAll.map((item, index) => {
              let anchorUrl = ""; 
              if(item.resource == 'url-link') {
              if (!isEmpty(item.url)) {
                if (item.url.startsWith("http://") || item.url.startsWith("https://")) {
                  anchorUrl = item.url; 
                } else {
                  anchorUrl = `https://${item.url}`;
                }
              }
            }
            
            if(item.resource == 'email-address') {
              if (!isEmpty(item.url)) {
                  anchorUrl = `mailto:${item.url}`;
              }
            }
              const isActive = index === 0 ? "active" : "";
              return (
                <div className={`carousel-item ${isActive}`} key={index}>
                  {anchorUrl !== "" ? (
                    <a href={anchorUrl}
                    onClick={(e) => {
                        e.preventDefault();
                        window.open(anchorUrl, "_blank");
                    }}
                    target="_blank" rel="noreferrer">
                      <img
                        src={item.image}
                        className="mx-auto slider-img"
                        alt="Promotion"
                      />
                    </a>
                  ) : (
                    <img
                      src={item.image}
                      className="mx-auto slider-img"
                      alt="Promotion"
                    />
                  )}
                </div>
              );
            })}


            <div className={`carousel-item ${sliderAll.length == 0 ? 'active' : ''}`}>
              <img src={Banner1} className=" mx-auto slider-img" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={Banner2} className=" mx-auto slider-img " alt="..." />
            </div>
            <div className="carousel-item">
              <img src={Banner3} className=" mx-auto slider-img " alt="..." />
            </div>
            <div className="carousel-item">
              <img src={Banner4} className=" mx-auto slider-img " alt="..." />
            </div>
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              className="sprite carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
          </button>

          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              className="sprite carousel-control-next-icon"
              aria-hidden="true"
            ></span>
          </button>
        </div>

      </div>



      <span className="sprite decoration"></span>
    </section>
  );
}

export default Sliders;
