import React, { useEffect, useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";

function MapChart({ countryData }) {

  const chartRef = useRef(null)

  useLayoutEffect(() => {

    let chartContainer = chartRef.current;


    let root = am5.Root.new(chartContainer);

    root._logo.dispose(); //remove logo

    root.setThemes([
      am5themes_Animated.new(root)
    ]);


    let chart = root.container.children.push(am5map.MapChart.new(root, {
      panX: "rotateX",
      projection: am5map.geoMercator(),
      layout: root.horizontalLayout
    }));


    let polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
      calculateAggregates: true,
      valueField: "value"
    }));


    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
      cursorOverStyle: "pointer"
    });

    // polygonSeries.mapPolygons.template.states.create("hover", {
    //   fill: am5.color(0x93e9e9)
    // });

    polygonSeries.mapPolygons.template.set("fill", am5.color(0xd4d4d4)); // by default gray color

    polygonSeries.set("heatRules", [{
      target: polygonSeries.mapPolygons.template,
      dataField: "value",
      min: am5.color(0x0b4471),
      max: am5.color(0xdf0024),
      key: "fill"
    }]);

    // polygonSeries.mapPolygons.template.events.on("pointerover", function (ev) {
    //   heatLegend.showValue(ev.target.dataItem.get("value"));
    // });


    let heatLegend = chart.children.push(
      am5.HeatLegend.new(root, {
        orientation: "vertical",
        startColor: am5.color(0x0b4471),
        endColor: am5.color(0xdf0024),
        startText: "Lowest",
        endText: "Highest",
        stepCount: 1
      })
    );

    heatLegend.startLabel.setAll({
      fontSize: 12,
      fill: heatLegend.get("startColor")
    });

    heatLegend.endLabel.setAll({
      fontSize: 12,
      fill: heatLegend.get("endColor")
    });


    polygonSeries.events.on("datavalidated", function () {
      heatLegend.set("startValue", polygonSeries.getPrivate("valueLow"));
      heatLegend.set("endValue", polygonSeries.getPrivate("valueHigh"));
    });

    //   function****


    const loadGeodata = () => {

      let defaultMap = "usaLow";

      chart.set("projection", am5map.geoEqualEarth());

      // let data = [];

      // for (var i = 0; i < am5geodata_worldLow.features.length; i++) {
      //   data.push({
      //     id: am5geodata_worldLow.features[i].id,
      //     value: Math.round(Math.random() * 10000)
      //   });
      // }

      polygonSeries.set("geoJSON", am5geodata_worldLow);
      //  polygonSeries.data.setAll(data);
      polygonSeries.data.setAll(countryData);






      let currentMap = defaultMap;
      let title = "";


      chart.seriesContainer.children.push(am5.Label.new(root, {
        x: 5,
        y: 5,
        text: title,
        background: am5.RoundedRectangle.new(root, {
          fill: am5.color(0x000000),
          fillOpacity: 0.2
        })
      }))

    }

    loadGeodata("");

    return () => root.dispose();

  }, [countryData]);



  return (

    <div id="chartdiv" ref={chartRef} style={{ width: "100%", height: "350px" }}>

    </div>
  )
}

export default MapChart
