import React from "react";

function ButtonAdminpanel({
  children,
  style,
  onClick,
  className = "",
  type,
  ...props
}) {
  return (
    <button
      type={type}
      className={`btn-regi ${className}`}
      style={style}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}

export default ButtonAdminpanel;
