import React,{useEffect} from 'react'
import Header from '../Header'
import Footer from '../../Footer'
import GlobalAdvertisingIMG from "../../imgs/inner-global-advertisement.jpg"

function GlobalAdvertising() {

  useEffect(() => {
         window.scrollTo({
           top: 0,
           left: 0,
           behavior: 'smooth',
         });
       }, []);

  return (
    <section className='benefits global-advertising-wrape page  para-head-text-align'>
      {/* <Header /> */}
        <section className="inner-banner-wrape global-advertising banner-wrape">
          <div className="inner"> Global Advertising</div>
          <span class="sprite decoration"></span>
        </section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5'>
              <div className='img-wrape'>
                <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                      Global <span>Advertising</span>
                    </h2>
                  </div>

                  <p className="text">
                    Expand Your Reach Worldwide
                  </p>
                </div>
                <img src={GlobalAdvertisingIMG} alt='' />
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='content-wrape'>
                <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                      Global <span>Advertising</span>
                    </h2>
                  </div>

                  <p className="text">
                    Expand Your Reach Worldwide
                  </p>
                </div>

                <p>In today’s competitive market, being seen is crucial. Orex Trade World INC offers <b>Global Advertising</b> to help your business stand out around the world. We create targeted advertising campaigns across different platforms to make sure your brand reaches the right buyers and suppliers in key markets.</p>
                <p>With our <b>Global Advertising</b> service, you can connect directly with potential trade partners who are looking for products or services like yours. Whether you want to attract buyers or find reliable suppliers, we adjust your advertising to focus on the right regions, helping you reach more people and get the best value for your advertising.</p>
                <p>Our platform covers over <b>80 countries</b>, so your business will be visible to decision-makers all over the world. We use digital platforms like search engines, trade websites and industry networks to ensure your message gets to the right people. This focused approach saves you from wasting money on broad campaigns and makes sure you’re reaching those most likely to become valuable partners.</p>
                <p><b>Global Advertising</b> also builds your brand’s trust by showing it on respected platforms, helping you build strong relationships with international partners. It also helps you understand different market needs, so you can adjust your message for each market.</p>
                <p>Orex Trade’s <b>Global Advertising</b> helps your business get noticed, connect with the right partners and open doors to new international opportunities.</p>
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </section>
  )
}

export default GlobalAdvertising
