import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import axios from "axios";

import trim from "lodash/trim";
import API_URL from "./config/apiConfig";

import errorMessages from "./constant/errorMessages";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-number-input/style.css";

// import CreatableSelect from "react-select/creatable";
import Button from "./components/Button";
import { Link } from "react-router-dom";

const invalidEmails = [
  "yopmail.com",
  "mailinator.com",
  "10minutemail.com",
  "guerrillamail.com",
  "trashmail.com",
  "temp-mail.org",
  "dispostable.com",
  "fakeinbox.com",
  "sharklasers.com",
  "getairmail.com",
  "maildrop.cc",
  "spambog.com",
  "mytemp.email",
  "throwawaymail.com",
  "tempmailaddress.com",
  "mailcatch.com",
  "moakt.com",
  "temp-mail.io",
  "tutanota.com",
];

function Contact() {
  var [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [formData, setFormData] = useState({
    company_name: "",
    website_url: "",
    name: "",
    email: "",
    phone: "",
    mobile: "",
    country_id: "",
    state_id: "",
    city_id: "",
    inquiry_message: "",
    isNewVal: false,
  });

  const [countryList, setCountryList] = useState([]);
  // const [statesList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  // const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [successmsg, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [verrors, setVErrors] = useState({});

  const styleError = {
    display: "block",
  };
  const [countryCode, setSelectedCountryCode] = useState(null);
  const [phoneValue, setphoneValue] = useState();
  const [mobileValue, setmobileValue] = useState();

  const [inputValue, setInputValue] = useState("");

  const recaptcha = useRef();

  const handleInputChange = (newValue) => {
    if (newValue === "" || (newValue === null && inputValue > 0)) {
      setCityList([]);
      axios
        .get(API_URL + "/cities/" + inputValue)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === true) {
              const options = response.data.data.map((ct) => ({
                value: ct.id,
                label:
                  ct.name + (ct.state_code ? " (" + ct.state_code + ")" : ""),
              }));
              setCityList(options);
            }
          }
        })
        .catch((err) => {
          console.log("Error ============= ", err);
        });
    }
    if (newValue !== "" && inputValue > 0) {
      if (newValue.length >= 3) {
        setCityList([]);
        axios
          .post(API_URL + "/cities/search", {
            search_txt: newValue,
            country_id: inputValue,
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === true) {
                const options = response.data.data.map((ct) => ({
                  value: ct.id,
                  label:
                    ct.name + (ct.state_code ? " (" + ct.state_code + ")" : ""),
                }));
                setCityList(options);
              }
            }
          });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      await axios
        .post(API_URL + "/inquiry/add", formData, {
          headers: {
            Accept: "application/json",
          },
          timeout: 10000,
        })
        .then((response) => {
          recaptcha.current.reset();
          if (response.status === 200) {
            if (response.data.status === true) {
              setMessage(response.data.message);
              const frmdata = {
                company_name: "",
                website_url: "",
                name: "",
                email: "",
                phone: "",
                mobile: "",
                country_id: "",
                state_id: "",
                city_id: "",
                inquiry_message: "",
                isNewVal: false,
              };
              setFormData(frmdata);
              setSelectedCity(null);
              setSelectedCountry(null);
              setSelectedCountryCode(null);
              setphoneValue(null);
              setmobileValue(null);
              setErrors({});
              // setSelectedState(null);
              setTimeout(() => {
                setMessage("");
              }, 3000);
            } else {
              setVErrors([]);
              if (response.data.status === false) {
                const newErrors = {};
                if (response.data.error) {
                  if (response.data.error.company_name) {
                    newErrors.company_name =
                      response.data.error.company_name[0];
                  }
                  if (response.data.error.website_url) {
                    newErrors.website_url = response.data.error.website_url[0];
                  }
                  if (response.data.error.name) {
                    newErrors.name = response.data.error.name[0];
                  }
                  if (response.data.error.email) {
                    newErrors.email = response.data.error.email[0];
                  }
                  if (response.data.error.phone) {
                    newErrors.phone = response.data.error.phone[0];
                  }
                  // if(response.data.error.mobile) {
                  //     newErrors.mobile = response.data.error.mobile[0];
                  // }
                  if (response.data.error.country_id) {
                    newErrors.country_id = response.data.error.country_id[0];
                  }
                  if (response.data.error.city_id) {
                    newErrors.city_id = response.data.error.city_id[0];
                  }
                  if (response.data.error.inquiry_message) {
                    newErrors.inquiry_message =
                      response.data.error.inquiry_message[0];
                  }
                }
                setErrors(newErrors);
              }
              setMessage("Something Went Wrong!!");
              setTimeout(() => {
                setMessage("");
              }, 3000);
            }
          } else {
            setMessage("Something Went Wrong!!");
            setTimeout(() => {
              setMessage("");
            }, 3000);
          }
        })
        .catch((err) => {
          console.log("Error === ", err);
          setMessage("Something Went Wrong!!");
          setTimeout(() => {
            setMessage("");
          }, 3000);
        });
    }
  };

  const validateForm = () => {
    setErrors({});
    let er = {};
    let flag = 0;
    if (formData.company_name === "") {
      flag = 1;
      er.company_name = errorMessages.FIELD_REQUIRED;
    }
    if (formData.website_url === "") {
      flag = 1;
      er.website_url = errorMessages.FIELD_REQUIRED;
    }
    if (formData.name === "") {
      flag = 1;
      er.name = errorMessages.FIELD_REQUIRED;
    }
    if (formData.email === "") {
      flag = 1;
      er.email = errorMessages.FIELD_REQUIRED;
    }
    // if(formData.mobile === "") {
    //     flag = 1;
    //     er.mobile = errorMessages.MOBILE_REQUIRED;
    // }
    if (formData.country_id === "") {
      flag = 1;
      er.country_id = errorMessages.FIELD_REQUIRED;
    }
    if (formData.inquiry_message === "") {
      flag = 1;
      er.inquiry_message = errorMessages.FIELD_REQUIRED;
    }
    if (
      phoneValue === undefined ||
      !phoneValue ||
      !isValidPhoneNumber(phoneValue)
    ) {
      if (phoneValue === undefined || phoneValue === "") {
        flag = 1;
        er.phone = errorMessages.FIELD_REQUIRED;
      } else {
        flag = 1;
        er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
      }
    }
    if (mobileValue) {
      if (!mobileValue || !isValidPhoneNumber(mobileValue)) flag = 1;
      er.mobile = errorMessages.MOBILE_NUMBER_VALIDATION_MSG;
    }

    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      flag = 1;
      er.recaptcha = "Please verify the reCAPTCHA!";
    }

    setVErrors([]);
    setErrors(er);
    if (flag === 1) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (e) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleFocusOut = (e) => {
    // console.log('errors', errors);
    setErrors([]);
    let flag = 0;
    let er = { ...errors };
    if (e.target.name === "company_name") {
      if (e.target.value === "") {
        flag = 1;
        er.company_name = errorMessages.FIELD_REQUIRED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9 .&@]+$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.company_name = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.company_name = "";
        }
      }
    }

    if (e.target.name === "website_url") {
      if (e.target.value === "") {
        flag = 1;
        er.website_url = errorMessages.FIELD_REQUIRED;
      } else {
        const regExp =
          /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.website_url = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.website_url = "";
        }
      }
    }

    if (e.target.name === "name") {
      if (e.target.value === "") {
        flag = 1;
        er.name = errorMessages.FIELD_REQUIRED;
      } else {
        const regExp = /^[a-zA-Z][a-zA-Z\d\s]*$/u;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.name = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.name = "";
        }
      }
    }

    if (e.target.name === "email") {
      if (e.target.value === "") {
        flag = 1;
        er.email = errorMessages.FIELD_REQUIRED;
      } else {
        const currentEmail = e.target.value.split("@")[1];
        const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else if (invalidEmails.includes(currentEmail)) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.email = "";
        }
      }
    }

    if (e.target.name === "inquiry_message") {
      if (e.target.value === "") {
        flag = 1;
        er.inquiry_message = errorMessages.FIELD_REQUIRED;
      } else {
        flag = 0;
        er.inquiry_message = "";
      }
    }

    setErrors(er);
    if (flag === 0) {
      setisButtonDisabled(false);
      return false;
    } else {
      setisButtonDisabled(true);
      return true;
    }
  };

  const fetchCountries = async () => {
    await axios
      .get(API_URL + "/countries")
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === true) {
            const options = response.data.data.map((country) => ({
              value: country.id,
              label: country.name,
              countryCode: country.iso2,
            }));
            setCountryList(options);
          }
        }
      })
      .catch((err) => {
        console.log("Error ========== ", err);
      });
  };

  const handleCountryChange = async (cval) => {
    setSelectedCountryCode(cval.countryCode);
    setphoneValue("");
    setmobileValue("");
    // setStateList([]);
    setCityList([]);
    const countryId = cval.value;
    setInputValue(countryId);
    setSelectedCountry(cval);
    //setSelectedState(null);
    setSelectedCity(null);
    let errorObj = { ...errors };
    errorObj.country_id = "";
    setErrors(errorObj);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        country_id: countryId,
      };
    });
    // await axios.get(API_URL+'/states/'+countryId).then((response) => {
    //     if(response.status === 200) {
    //         if(response.data.status === true) {
    //             const options = response.data.data.map(st => ({
    //                 value: st.id,
    //                 label: st.name
    //             }));
    //             setStateList(options);
    //         }
    //     }
    await axios
      .get(API_URL + "/cities/" + countryId)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === true) {
            const options = response.data.data.map((ct) => ({
              value: ct.id,
              label:
                ct.name + (ct.state_code ? " (" + ct.state_code + ")" : ""),
            }));
            setCityList(options);
          }
        }
      })
      .catch((err) => {
        console.log("Error ============= ", err);
      });
  };

  const handleCityChange = async (ctval) => {
    if (ctval != null) {
      const cityId = ctval.value;
      setSelectedCity(ctval);
      setFormData((prevalue) => {
        return {
          ...prevalue,
          city_id: cityId,
          isNewVal: ctval.__isNew__,
        };
      });
    }
  };

  const handlePhoneChange = (val) => {
    setphoneValue(val);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        phone: val,
      };
    });
    let errorObj = { ...errors };
    errorObj.phone = "";
    setErrors(errorObj);
  };

  const handleMobileChange = (val) => {
    setmobileValue(val);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        mobile: val,
      };
    });
  };

  const handleRecaptchaChange = (value) => {
    console.log("value ========= ", value);
  };

  const isDuplicate = (inputValue) => {
    return cityList.some((option) => {
      let tmpLabel = option.label;
      let tmpLabels = tmpLabel.split("(");
      tmpLabel = trim(tmpLabels[0]);
      return tmpLabel.toLowerCase() === inputValue.toLowerCase();
    });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <section className="contact-wrape">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="box-inner">
              <div className="up-wrape">
                <h5>
                  You need the <b>Right Direction</b> for your Business, we'll
                  help you do that.
                </h5>
              </div>
              <div className="down-wrape">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6 col-sm-12  col-md-12">
                      <div className="detail-part">
                        <div className="contact-pad">
                          <div className="address">
                            <h6>Address Business</h6>
                            <p>
                              <i className="sprite"></i>
                              304, Riley CIR NW, Milledgeville, GA, 31061-7076,
                              USA
                            </p>
                          </div>
                          <div className="other">
                            <h6>Contact With Us</h6>
                            <Link className="number" to="tel:+1 (786) 772-5447">
                              <i className="sprite"></i>
                              +1 (786) 772-5447
                            </Link>
                            <Link
                              className="email"
                              to="mailto:info@orextrade.com"
                            >
                              <i className="sprite"></i>
                              info@orextrade.com
                            </Link>
                          </div>
                        </div>
                        <div className="stats-wrape">
                          <i className="brand"></i>
                          <div className="stat one">
                            <p className="number">5.6</p>
                            <p className="text">
                              <span>+ Million </span>
                              <span className="big">
                                Global <b>Buyers</b>
                              </span>
                            </p>
                          </div>
                          <div className="stat two">
                            <p className="number">23.5</p>
                            <p className="text">
                              <span>+ Thousand</span>
                              <span className="big">
                                Global <b>Suppliers</b>
                              </span>
                            </p>
                          </div>
                          <div className="stat three">
                            <p className="number">8.7</p>
                            <p className="text">
                              <span>+ Million</span>
                              <span className="big">
                                Global <b>Shipments</b>
                              </span>
                              <span>on board</span>
                            </p>
                          </div>
                          <div className="stat four">
                            <p className="number">80</p>
                            <p className="text">
                              <span>+ Countries</span>
                              <span className="big">
                                We <b>Globaly serve</b>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12  col-md-12">
                      <div className="form-wrape">
                        <div className="section-head">
                          <div className="head">
                            <p>Orex Trade to</p>
                            <h2>
                              <span>Book</span> Demo
                            </h2>
                          </div>

                          <p className="text">
                            Complete this form to learn more about our services
                            or share your requirements.
                            <br />
                            We’ll analyse your request and get back to you
                            shortly.
                          </p>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <div className="row g-4">
                            <div className="col-md-6">
                              <label
                                className="form-label inquiry-text required"
                                for="contact-form-company_name"
                              >
                                Company Name
                              </label>
                              <input
                                type="text"
                                className="form-control inp-font-size"
                                id="contact-form-company_name"
                                autoComplete="new-off"
                                placeholder="Enter company name"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                                name="company_name"
                                value={formData.company_name}
                              />
                              {errors.company_name && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.company_name} {verrors.company_name}
                                </div>
                              )}
                              {verrors.company_name && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {" "}
                                  {verrors.company_name}
                                </div>
                              )}
                            </div>

                            <div className="col-md-6">
                              <label
                                className="form-label inquiry-text required"
                                for="contact-form-website_url"
                              >
                                Website
                              </label>
                              <input
                                type="text"
                                className="form-control inp-font-size"
                                id="contact-form-website_url"
                                autoComplete="new-off"
                                placeholder="Enter website"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                                name="website_url"
                                value={formData.website_url}
                              />
                              {errors.website_url && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.website_url}{" "}
                                </div>
                              )}
                              {verrors.website_url && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {verrors.website_url}{" "}
                                </div>
                              )}
                            </div>

                            <div className="col-md-6">
                              <label
                                className="form-label inquiry-text required"
                                for="contact-form-fullname"
                              >
                                Full Name
                              </label>
                              <input
                                type="text"
                                className="form-control inp-font-size"
                                id="contact-form-fullname"
                                autoComplete="new-off"
                                placeholder="Enter full name"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                                name="name"
                                value={formData.name}
                              />
                              {errors.name && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.name}
                                </div>
                              )}
                              {verrors.name && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {verrors.name}{" "}
                                </div>
                              )}
                            </div>

                            <div className="col-md-6">
                              <label
                                className="form-label inquiry-text required"
                                for="contact-form-email"
                              >
                                Email
                              </label>
                              <input
                                type="text"
                                id="contact-form-email"
                                className="form-control inp-font-size"
                                autoComplete="new-off"
                                placeholder="Enter email"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                                name="email"
                                value={formData.email}
                              />
                              {errors.email && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.email}
                                </div>
                              )}
                              {verrors.email && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {verrors.email}{" "}
                                </div>
                              )}
                            </div>

                            <hr className="m-b-0" />

                            <div className="col-md-6 pb-0 dd-normal">
                              <label className="form-label inquiry-text required">
                                Country
                              </label>
                              <Select
                                options={countryList}
                                value={selectedCountry}
                                onChange={handleCountryChange}
                                placeholder="Select country"
                                className="dd-pad"
                              />
                              {errors.country_id && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.country_id}
                                </div>
                              )}
                            </div>

                            {/* <div className="col-md-6 pb-0">
                              <label className=" inquiry-text">City</label>
                              <CreatableSelect
                                onChange={handleCityChange}
                                onInputChange={handleInputChange}
                                options={cityList}
                                value={selectedCity}
                                placeholder="Enter city"
                                formatCreateLabel={(inputValue) =>
                                  isDuplicate(inputValue)
                                    ? null
                                    : `Create "${inputValue}"`
                                }
                                isValidNewOption={(inputValue) =>
                                  !isDuplicate(inputValue)
                                }
                              />
                              {errors.city_id && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.city_id}
                                </div>
                              )}
                            </div> */}

                            <div className="col-md-6">
                              <label
                                className="form-label inquiry-text required"
                                for="contact-form-phone"
                              >
                                Contact
                              </label>
                              <PhoneInput
                                id="contact-form-phone"
                                className="form-control inquiry-phone inp-font-size border-dropdown contact-select-border outer_pages_phone_input"
                                name="phone"
                                international
                                limitMaxLength
                                placeholder="Enter contact"
                                defaultCountry={countryCode}
                                value={phoneValue}
                                onChange={handlePhoneChange}
                              />
                              {errors.phone && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.phone}
                                </div>
                              )}
                            </div>

                            {/* <div className="col-md-6">
                              <label
                                className="form-label inquiry-text "
                                for="contact-form-mobile"
                              >
                                Mobile{" "}
                              </label>

                              <PhoneInput
                                id="contact-form-mobile"
                                className="form-control inquiry-mobile inp-font-size border-dropdown contact-select-border"
                                name="mobile"
                                international
                                limitMaxLength
                                placeholder="Enter mobile"
                                defaultCountry={countryCode}
                                value={mobileValue}
                                onChange={handleMobileChange}
                              />
                              {errors.mobile && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.mobile}
                                </div>
                              )}
                            </div> */}

                            <hr className="m-b-0" />

                            <div className="col-12">
                              <label
                                className="form-label inquiry-text required"
                                for="contact-form-message"
                              >
                                Message
                              </label>
                              <textarea
                                id="contact-form-message"
                                className="form-control"
                                rows="3"
                                placeholder="Write a message"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                                name="inquiry_message"
                                value={formData.inquiry_message}
                              ></textarea>
                              {errors.inquiry_message && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.inquiry_message}
                                </div>
                              )}
                              {verrors.inquiry_message && (
                                <div
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {verrors.inquiry_message}{" "}
                                </div>
                              )}
                            </div>

                            <div className="col-md-6">
                              <div className="captcha-wrapes">
                                <ReCAPTCHA
                                  sitekey={process.env.REACT_APP_SITE_KEY}
                                  //sitekey="6LewPKYqAAAAACBk89y9WiHuMuQ3XHdZt146nVLG"
                                  // onChange={handleRecaptchaChange}
                                  ref={recaptcha}
                                  onExpired={() => {
                                    recaptcha.current.reset(); // here
                                  }}
                                />
                                {errors.recaptcha && (
                                  <div
                                    className="invalid-feedback"
                                    style={styleError}
                                  >
                                    {" "}
                                    {errors.recaptcha}{" "}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6 inquiry-form-btn">
                              <Button
                                className="btn secondry-btn"
                                type="submit"
                                disabled={isButtonDisabled}
                              >
                                Send Inquiry
                              </Button>
                            </div>
                          </div>
                        </form>
                        {successmsg && (
                          <div className="alert alert-success mt-3">
                            {successmsg}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* <div className="how_we">
          <div className="boost">
            <p>Inquiry Now</p>
          </div>
          <h5>
            {" "}
            <span className="fw-bold"></span>
            <span className="boost-span">
              <span className="fw-bold">Inquiry </span>Now
            </span>
          </h5>

          <p className="with-our">
            Any question or remark? just write us a message.
          </p>
        </div> */}

        {/* <div className="row g-6 contect-section">
          <div className="col-lg-5 contect-imges">
            <div className="contact-img-box border p-2 h-100">
              <img
                src={contPosImg}
                alt="contact border"
                className="contact-border-img"
              />

              <img
                src={contectImg}
                alt="contact customer service"
                className="contact-img w-100 scaleX-n1-rtl"
              />

              <div className="">
                <div className="row border-bottom mx-4 pb-4 mt-4">
                  <div className="col-lg-12 col-xl-6 mt-3 p-0">
                    <div className="d-flex align-items-center">
                      <i className="fa-regular fa-envelope email-icon fs-5 "></i>
                      <div>
                        <p className="mb-0">Email</p>
                        <h6 className="mb-0 remove-text-decoration">
                          <a href="mailto:info@orextrade.com">
                            info@orextrade.com
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-xl-6 mt-3 p-0 ">
                    <div className="d-flex align-items-center">
                      <i class="fa-brands fa-whatsapp contect-icon fs-5"></i>
                      <div className="contect-address">
                        <p className="mb-0">Phone</p>
                        <h6 className="mb-0 remove-text-decoration">
                          +1 (786) 772-5447
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mx-4 mt-3">
                  <div className="col-lg-12 mt-3 p-0">
                    <div className="d-flex align-items-center">
                      <i class="fa-solid fa-location-dot location-icon fs-5"></i>
                      <div className="contect-address">
                        <p className="mb-0">Address</p>
                        <h6 className="mb-0 remove-text-decoration">
                          304, Riley CIR NW, Milledgeville, GA, 31061-7076, USA
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 

          <div className="col-lg-7">
            <div className="card h-100 border-0 shadow ">
              <div className="card-body">
                <h4 className="mb-2">Quick Inquiry</h4>
                <p className="mb-3">
                  Complete this form to learn more about our services or share
                  your requirements.
                  <br className="d-none d-lg-block" />
                  We’ll analyse your request and get back to you shortly.
                </p>

                <form onSubmit={handleSubmit}>
                  <div className="row g-4">
                    <div className="col-md-6">
                      <label
                        className="form-label inquiry-text required"
                        for="contact-form-company_name"
                      >
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control inp-font-size"
                        id="contact-form-company_name"
                        autoComplete="new-off"
                        placeholder="Enter company name"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        name="company_name"
                        value={formData.company_name}
                      />
                      {errors.company_name && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.company_name} {verrors.company_name}
                        </div>
                      )}
                      {verrors.company_name && (
                        <div className="invalid-feedback" style={styleError}>
                          {" "}
                          {verrors.company_name}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label
                        className="form-label inquiry-text required"
                        for="contact-form-website_url"
                      >
                        Website
                      </label>
                      <input
                        type="text"
                        className="form-control inp-font-size"
                        id="contact-form-website_url"
                        autoComplete="new-off"
                        placeholder="Enter website"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        name="website_url"
                        value={formData.website_url}
                      />
                      {errors.website_url && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.website_url}{" "}
                        </div>
                      )}
                      {verrors.website_url && (
                        <div className="invalid-feedback" style={styleError}>
                          {verrors.website_url}{" "}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label
                        className="form-label inquiry-text required"
                        for="contact-form-fullname"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control inp-font-size"
                        id="contact-form-fullname"
                        autoComplete="new-off"
                        placeholder="Enter full name"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        name="name"
                        value={formData.name}
                      />
                      {errors.name && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.name}
                        </div>
                      )}
                      {verrors.name && (
                        <div className="invalid-feedback" style={styleError}>
                          {verrors.name}{" "}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label
                        className="form-label inquiry-text required"
                        for="contact-form-email"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        id="contact-form-email"
                        className="form-control inp-font-size"
                        autoComplete="new-off"
                        placeholder="Enter email"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        name="email"
                        value={formData.email}
                      />
                      {errors.email && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.email}
                        </div>
                      )}
                      {verrors.email && (
                        <div className="invalid-feedback" style={styleError}>
                          {verrors.email}{" "}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6 pb-0">
                      <label className="inquiry-text required">Country</label>
                      <Select
                        options={countryList}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        placeholder="Select country"
                      />
                      {errors.country_id && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.country_id}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6 pb-0">
                      <label className=" inquiry-text">City</label>
                      <CreatableSelect
                        onChange={handleCityChange}
                        onInputChange={handleInputChange}
                        options={cityList}
                        value={selectedCity}
                        placeholder="Enter city"
                        formatCreateLabel={(inputValue) =>
                          isDuplicate(inputValue)
                            ? null
                            : `Create "${inputValue}"`
                        }
                        isValidNewOption={(inputValue) =>
                          !isDuplicate(inputValue)
                        }
                      />
                      {errors.city_id && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.city_id}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label
                        className="form-label inquiry-text required"
                        for="contact-form-phone"
                      >
                        Contact
                      </label>
                      <PhoneInput
                        id="contact-form-phone"
                        className="form-control inquiry-phone inp-font-size border-dropdown contact-select-border"
                        name="phone"
                        international
                        limitMaxLength
                        placeholder="Enter contact"
                        defaultCountry={countryCode}
                        value={phoneValue}
                        onChange={handlePhoneChange}
                      />
                      {errors.phone && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.phone}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label
                        className="form-label inquiry-text "
                        for="contact-form-mobile"
                      >
                        Mobile{" "}
                      </label>

                      <PhoneInput
                        id="contact-form-mobile"
                        className="form-control inquiry-mobile inp-font-size border-dropdown contact-select-border"
                        name="mobile"
                        international
                        limitMaxLength
                        placeholder="Enter mobile"
                        defaultCountry={countryCode}
                        value={mobileValue}
                        onChange={handleMobileChange}
                      />
                      {errors.mobile && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.mobile}
                        </div>
                      )}
                    </div>

                    <div className="col-12">
                      <label
                        className="form-label inquiry-text required"
                        for="contact-form-message"
                      >
                        Message
                      </label>
                      <textarea
                        id="contact-form-message"
                        className="form-control"
                        rows="3"
                        placeholder="Write a message"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        name="inquiry_message"
                        value={formData.inquiry_message}
                      ></textarea>
                      {errors.inquiry_message && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.inquiry_message}
                        </div>
                      )}
                      {verrors.inquiry_message && (
                        <div className="invalid-feedback" style={styleError}>
                          {verrors.inquiry_message}{" "}
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        // onChange={handleRecaptchaChange}
                        ref={recaptcha}
                        onExpired={() => {
                          recaptcha.current.reset(); // here
                        }}
                      />
                      {errors.recaptcha && (
                        <div className="invalid-feedback" style={styleError}>
                          {" "}
                          {errors.recaptcha}{" "}
                        </div>
                      )}
                    </div>

                    <div className="col-12 text-center mt-4 pt-3">
                      <Button
                        className="contact-inquiry-button"
                        type="submit"
                        disabled={isButtonDisabled}
                      >
                        Send Inquiry
                      </Button>
                    </div>
                  </div>
                </form>
                {successmsg && (
                  <div className="alert alert-success mt-3">{successmsg}</div>
                )}
              </div>
            </div>
          </div>
        </div>*/}
      </div>
    </section>
  );
}

export default Contact;
