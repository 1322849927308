import axios from "axios";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import API_URL from "../../config/apiConfig";

import "react-multi-carousel/lib/styles.css";

const PromotionMedias = (props) => {

    const [pmedia, setPMedia] = useState([]);

    const getPromotionMedias = async () => {
        await axios
            .get(API_URL + "/promotion-media")
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.status === true) {
                        //setSliderAll(response.data.data);
                        setPMedia(response.data.data);
                    }
                }
            })
            .catch((err) => {
                console.log("Error ========== ", err);
            });
    }

    useEffect(() => {
        getPromotionMedias();
    }, []);

    return (<>
        <h5 className='m-3 border-bottom pb-2'>{props.title}</h5>
        <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            autoPlay={true}
            centerMode={false}
            //className="pl-1 pr-1"
            containerClass="dashboard-banner"
            dotListClass=""
            draggable={true}
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={true}
            renderButtonGroupOutside={false}
            renderDotsOutside={true}
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                    items: 1
                },
                mobile: {
                    breakpoint: {
                        max: 464,
                        min: 0
                    },
                    items: 1
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 1
                }
            }}
            rewind={true}
            rewindWithAnimation={true}
            rtl={false}
            shouldResetAutoplay
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {pmedia && pmedia.map((val) => {
                return (<a href={`/company/${val.member_id}`} key={`link-${val.id}`} target="_blank" rel="noreferrer">
                    <img src={`${val.image}`} key={val.id} style={{ maxWidth: "100%", height: "auto" }} alt="" /> </a>)
            })}
        </Carousel>


    </>)
}

export default PromotionMedias;