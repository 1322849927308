import { createContext, useState, useEffect, useRef } from "react";
import { Route, Routes, useLocation, matchPath } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "rsuite/dist/rsuite.min.css";
import { MENU_ROUTES } from "./config/menuRoutes";

import Headers from "./V2/Header";
// import Registraion from './Registraion';
// import AcountPayment from './AcountPayment';
import About from "./About";
import Login from "./pages/Login";
import ResetPasswordForm from "./pages/ResetpasswordForm";
import ForgotPasswordForm from "./pages/ForgotPasswordForm";

import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Maintenance from "./pages/Maintenance";
import Construction from "./pages/Construction";

// import SimpleForm from "./pages/SimpleForm";
// import Toasty from "./components/Toasty";
// import Popupbox from "./pages/Popupbox";
// import FilePicker from "./components/FilePicker";
// import UpdateProfile from "./pages/Company/UpdateProfile";

// import AccountDetail from './AccountDetail';
// import CompanyRepresentative from './CompanyRepresentative';
// import ContactDetail from './ContactDetail';

import RegistrationDetail from "./RegistrationDetail";

import OrexSidebarsProviders from "./components/OrexSidebarsProviders";

import GlobalAdvertising from "./V2/benifits/GlobalAdvertising";
import GlobalEmailPromotions from "./V2/benifits/GlobalEmailPromotions";
import GuaranteeProspectiveBusiness from "./V2/benifits/GuaranteeProspectiveBusiness";
import LeadReports from "./V2/benifits/LeadReports";
import LogisticsTrade from "./V2/benifits/LogisticsTrade";
import ReferenceVerification from "./V2/benifits/ReferenceVerification";
import SeasonalPromotion from "./V2/benifits/SeasonalPromotion";
import TradeSafetyGuarantee from "./V2/benifits/TradeSafetyGuarantee";

import OurServicePage from "./V2/OurServicePage";
import ContactUsPage from "./V2/ContactUs"
import InquiryUsPage from "./V2/InquiryUs"

// import './App.css';
// import './developer.css';
import "./css/style_v2.css";
import "./css/dharmesh.css";
import { AuthProvider } from "./AuthProvider";
import RegistrationSuscessMassage from "./components/RegistrationMassage";
import ROUTES from "./config/routePath";
import Index from "./V2/Index";
import BuyerSuplierData from "./V2/BuyerSuplierData";
import OurGallery from "./V2/OurGallery";
import TermsOfService from "./V2/TermsOfService";
import TermsOfConditionsSupplier from "./V2/TermsOfConditionsSupplier";

export let property = createContext("");

function App(props) {
  const [account, setaccount] = useState("");
  const [compney, setcompney] = useState("");
  const [compnytext, setcompnytext] = useState("");
  const [RegiIcon, setRegiIcon] = useState("");
  const [profileName, setProfileName] = useState();

  const [visibleSidebar, setSidebar] = useState(false);
  const location = useLocation();

  const validRoute = useRef(true);

  const [checkRoute, setCheckRoute] = useState(true);

  useEffect(() => {

    // const match = Object.values(MENU_ROUTES).some((route) =>
    //   matchPath(route, location.pathname)
    // );
    // setSidebar(match);

    // const match1 = Object.values(ROUTES).some((route) =>
    //   matchPath(route, location.pathname)
    // );
    

    // if(match == true){
    //   setCheckRoute(false);
    // }

    // if(match1 == true){
    //   setCheckRoute(true);
    // }

    // if (!match && !match1) {
    //   validRoute.current = false;
     
    // } else {
    //   validRoute.current = true;
     
    // }

    const match = Object.values(MENU_ROUTES).some((route) =>
      matchPath(route, location.pathname)
    );
    setSidebar(match);

    const match1 = Object.values(ROUTES).some((route) =>
      matchPath(route, location.pathname)
    );

    if (!match && !match1) {
      setCheckRoute(false); 
    } else {
      setCheckRoute(true);  
    }
  }, [location.pathname]);

  return (
    <AuthProvider>
      <div className="orexTrade">

         {/* {visibleSidebar === false && <Headers />}

        {visibleSidebar && <OrexSidebarsProviders />} */}

        {visibleSidebar ? (
          <>
            <OrexSidebarsProviders />
            <div className="outer-pages">
              <property.Provider
                value={{
                  account,
                  setaccount,
                  compney,
                  setcompney,
                  compnytext,
                  setcompnytext,
                  RegiIcon,
                  setRegiIcon,
                  profileName,
                  setProfileName,
                }}
              >
              </property.Provider>
            </div>
          </>
        ) : (
          checkRoute ? (
            <>
              <div className="outer-pages">
              <property.Provider value={{
                  account,
                  setaccount,
                  compney,
                  setcompney,
                  compnytext,
                  setcompnytext,
                  RegiIcon,
                  setRegiIcon,
                  profileName,
                  setProfileName,
                }}>
                <Routes>
               
                  <Route exact path="/" element={<div><Headers /><Index /></div>} />
                  <Route exact path="/about" element={<div><Headers /><About /></div>} />
                  <Route exact path="/find-buyer-supplier" element={<div><Headers /><BuyerSuplierData /></div>} />
                  <Route
                    exact
                    path="/registration-success"
                    element={<div><Headers /><RegistrationSuscessMassage /></div>}
                  />
                  <Route path="/registration" element={<div><Headers /><RegistrationDetail /></div>} />
                  <Route path="/login" element={<div><Headers /><Login /></div>} reloadDocument />
                  <Route path="/resetpassword" element={<div><Headers /><ResetPasswordForm /></div>} />
                  <Route path="/forgotpassword" element={<div><Headers /><ForgotPasswordForm /></div>} />

                  {/* <Route exact path="/500" element={<Error500 />} /> */}
                  <Route exact path="/maintenance" element={<Maintenance />} />
                  <Route exact path="/construction" element={<Construction />} />

                  <Route
                    exact
                    path="/benefits/global-advertising"
                    element={<div><Headers /><GlobalAdvertising /></div>}
                  />
                  <Route
                    exact
                    path="/benefits/global-email-promotions"
                    element={<div><Headers /><GlobalEmailPromotions /></div>}
                  />
                  <Route
                    exact
                    path="/benefits/guarantee-prospective-business"
                    element={<div><Headers /><GuaranteeProspectiveBusiness /></div>}
                  />
                  <Route
                    exact
                    path="/benefits/logistics-trade"
                    element={<div><Headers /><LogisticsTrade /></div>}
                  />
                  <Route
                    exact
                    path="/benefits/lead-reports"
                    element={<div><Headers /><LeadReports /></div>}
                  />
                  <Route
                    exact
                    path="/benefits/reference-verification"
                    element={<div><Headers /><ReferenceVerification /></div>}
                  />
                  <Route
                    exact
                    path="/benefits/seasonal-promotion"
                    element={<div><Headers /><SeasonalPromotion /></div>}
                  />
                  <Route
                    exact
                    path="/benefits/trade-safety-guarantee"
                    element={<div><Headers /><TradeSafetyGuarantee /></div>}
                  />
                  <Route
                    exact
                    path="/our-services"
                    element={<div><Headers /><OurServicePage /></div>}
                  />

                  <Route
                    exact
                    path="/contact-us"
                    element={<div><Headers /><ContactUsPage /></div>}
                  />

		              <Route
                    exact
                    path="/event-highlights"
                    element={<div><Headers /><OurGallery /></div>}
                  />

                  <Route
                    exact
                    path="/terms-and-conditions"
                    element={<div><Headers /><TermsOfService /></div>}
                  />

                  <Route
                    exact
                    path="/terms-and-conditions-supplier"
                    element={<div>
                      <Headers />
                      <TermsOfService />
                      </div>}
                  />

                    <Route
                    exact
                    path="/terms-and-conditions-buyer"
                    element={<div>
                      <Headers />
                      <TermsOfService />
                    </div>}
                  />

                    <Route
                    exact
                    path="/terms-and-conditions-logistic"
                    element={<div>
                      <Headers />
                      <TermsOfService />
                      </div>}
                  />
		              <Route
                    exact
                    path="/inquiry"
                    element={<div><Headers /><InquiryUsPage /></div>}
                  />
                  

                  {/* <Route path='/personal' element={<CompanyRepresentative />}></Route> */}
                  {/* <Route path='/payment' element={<AcountPayment />}></Route> */}
                  {/* <Route path='/account' element={<Registraion />}></Route> */}

                  {/* <Route exact path='/form' element={<SimpleForm />}></Route> */}
                  {/* <Route exact path='/toasty' element={<Toasty />}></Route>
                      <Route exact path='/popup' element={<Popupbox />}></Route> */}
                  {/* <Route path='/account-detail' element={<AccountDetail />}></Route> */}

                  {/* <Route exact path='/filepicker' element={<FilePicker />}></Route> */}
                  {/* <Route exact path='/dashboard' element={<OrexSidebarsProviders />}></Route> */}
                  {/* <Route exact path='/company-update-profile' element={<UpdateProfile />} ></Route> */}
                  {/* <Route path="*" element={<Error404 />} /> */}

                  {/* <Route path="/registration" element={<AccountDetail />}></Route> */}
                  {/* <Route
                      path="/company-representative"
                      element={<CompanyRepresentative />}
                      ></Route> */}
                  {/* <Route path="/contact-detail" element={<ContactDetail />}></Route> */}

                </Routes>
              </property.Provider>
              </div>
            </>
          ) : (
            <property.Provider>
              <Error404 />
            </property.Provider>
          )
        )}
      </div>
    </AuthProvider>
  );
}

export default App;
