import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../../Footer";
import SeasonalPromotionIMG from "../../imgs/inner-seasonal-promotion.jpg";

function SeasonalPromotion() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="benefits seasonal-promotion page  para-head-text-align">
      {/* <Header /> */}
      <section className="inner-banner-wrape seasonal-promotion-wrape banner-wrape">
        <div className="inner"> Seasonal Promotions</div>
        <span class="sprite decoration"></span>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="img-wrape">
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Seasonal <span>Promotions</span>
                  </h2>
                </div>

                <p className="text">
                  Maximize Opportunities with Timely Campaigns
                </p>
              </div>
              <img src={SeasonalPromotionIMG} alt="" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="content-wrape">
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Seasonal <span>Promotions</span>
                  </h2>
                </div>

                <p className="text">
                  Maximize Opportunities with Timely Campaigns
                </p>
              </div>

              <p>
                Orex Trade World INC offers Seasonal Promotions to help
                businesses capitalize on peak trade seasons and market trends.
                These promotions align with global business cycles, ensuring
                your products or services get maximum visibility during
                high-demand periods.
              </p>
              <p>
                By participating, you can showcase your offerings to a larger
                audience and attract more potential buyers or suppliers during
                key times like holidays or industry events. Seasonal promotions
                boost brand recognition, increase the chances of profitable
                deals and help you stay competitive in a crowded market.
              </p>

              <div className="icon-text">
                <i className="sprite trend "></i>
                <h3>Follow Market Trends</h3>
                <p>
                  Use special seasonal promotions that match global business
                  cycles and trends.
                </p>
              </div>
              <div className="icon-text">
                <i className="sprite visibility "></i>
                <h3>Increase Visibility</h3>
                <p>
                  These promotions help make your products or services more
                  visible during busy trade seasons, reaching more people.
                </p>
              </div>
              <div className="icon-text">
                <i className="sprite times "></i>
                <h3>Make the Most of Peak Times</h3>
                <p>
                  Join campaigns during high-demand times, like holidays or key
                  business periods, to attract more buyers or suppliers.
                </p>
              </div>
              <div className="icon-text">
                <i className="sprite deals "></i>
                <h3>Get More Profitable Deals</h3>
                <p>
                  By promoting your business at the right time, you increase the
                  chances of getting profitable deals and growing your business.
                </p>
              </div>
              <div className="icon-text">
                <i className="sprite partners "></i>
                <h3>Connect with New and Current Partners</h3>
                <p>
                  Seasonal promotions help bring in new clients and strengthen
                  your relationships with existing partners during key times.
                </p>
              </div>
              <div className="icon-text">
                <i className="sprite competitors "></i>
                <h3>Stay Ahead of Competitors</h3>
                <p>
                  These promotions keep your business visible and relevant
                  during busy periods, giving you an advantage in a crowded
                  market.
                </p>
              </div>
              <div className="icon-text">
                <i className="sprite grow "></i>
                <h3>Grow Your Business</h3>
                <p>
                  Boost your brand and performance by taking advantage of timely
                  trade opportunities through seasonal campaigns.
                </p>
              </div>

              <p>
                Orex Trade’s Seasonal Promotions help businesses take advantage
                of peak trade times, increase visibility, get profitable deals
                and grow globally.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default SeasonalPromotion;
