import API_URL from "../config/apiConfig";
import { isEmpty, trim } from "lodash";
import axiosInstance from '../axiosHttp';
import { setAuthHeaders } from "../utils/CustomNotification";
import axios from "axios";

export const ListShipments = (offset, limit, columnName = "created_at", sortDirection = "DESC", searchTxt = null) => async (dispatch) => {

  const headers = setAuthHeaders();
  searchTxt = trim(searchTxt);
  searchTxt = btoa(searchTxt);
  const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);

  return await axiosInstance.get(API_URL + '/shipments/' + offset + '/' + limit + '/' + columnName + '/' + sortDirection + searchQueryString, {
    headers
  }).then((response) => {
    if (response.status === true) {
      dispatch({ type: "LIST_SHIPMENTS", payload: response });
    }
    return response.data;
  }).catch((err) => {
    console.log("Error ==== ", err);
  })
}

export const saveShipment = (data) => async (dispatch) => {
  const headers = setAuthHeaders();
  return await axiosInstance.post(API_URL + '/shipment/add', data, { headers }).then((response) => {
    // console.log("saveShipment ============= ", response);
    return response.data;
  }).catch((err) => {
    console.log("Error ========== ", err);
  });
}

export const deleteShipment = (id) => (dispatch) => {
  const headers = setAuthHeaders();
  return axiosInstance.delete(API_URL + '/shipment/' + id, { headers }).then((response) => {
    return response.data;
  }).catch((err) => {
    console.log("Error ========== ", err);
  });
}

export const FilterBuyersSupplier = (data) => (dispatch) => {
  return axios.post(API_URL + '/explore-buyer-supplier', data).then((response) => {
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  }).catch((err) => {
    console.log("Error ========== ", err);
  });
}

export const getTradeDetail = (type, company_name) => async (dispatch) => {
  const headers = setAuthHeaders();
  return await axiosInstance.get(API_URL + '/get-trade-detail/' + type + '/' + company_name, { headers }).then((response) => {
    return response.data;
  }).catch((err) => {
    console.log("Error ========== ", err);
  });
}
