import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "../config/routePath";

function GlobalTrade() {

  const nav = useNavigate();

  return (
    <section className="global-trade-wrape">
      <div className="section-head center">
        <div className="head">
          <p>Orex Trade to</p>
          <h2>
            <span>Make smart decisions through</span> our Global Trade Database
          </h2>
        </div>

        <p className="text">
          Access Real-Time Buyers/Suppliers Data for <b>80+ Countries!</b>{" "}
          Discover active Buyers & Suppliers and Explore Product and
          Country-wise Customs Records with Top Importers and Exporters Names,
          Values, Quantities and HS Codes from our <b>Orex Trade Database</b>.
          Analysis of Shipments Across Key Regions like.
        </p>
      </div>

      <div className="country-wrape">
        <div className="country us" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag "></i>
          <h6>US</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country china" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>CHINA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country india" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>INDIA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country srilanka" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>SRI LANKA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country vietnam" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>VIETNAM</h6>
          <p>Buyer/Supplier</p>
        </div>

        <div className="country argentina" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>ARGENTINA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country chile" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>CHILE</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country columbia" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>COLUMBIA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country ecuador" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>ECUADOR</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country mexico" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>MEXICO</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country panama" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>PANAMA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country paraguay" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>PARAGUAY</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country philippines" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>PHILIPPINES</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country uruguay" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>URUGUAY</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country venezuela" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>VENEZUELA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country bangladesh" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>BANGLADESH</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country cameroon" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>CAMEROON</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country costarica" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>COSTARICA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country ghana" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>GHANA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country kazakhstan" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>KAZAKHSTAN</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country pakistan" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>PAKISTAN</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country tanzania" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>TANZANIA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country turkey" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          <i className="flag"></i>
          <h6>TURKEY</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country" onClick={() => nav(ROUTES.FIND_BUYER_AND_SUPPLIER)}>
          {/* <div className="explore_contant"> */}
          <h6 className="explore_more">
            EXPLORE <br /> MORE
          </h6>
          {/* <Link className="export" to="/find-buyer-supplier"> */}
          <div className="export">
            <span className="sprite"></span>
          </div>

          {/* </Link> */}
          {/* <Link className='export' to="javascript:;"><span className='sprite'></span></Link> */}
          {/* </div> */}
        </div>
      </div>
    </section>
  );
}

export default GlobalTrade;
