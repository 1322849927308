import React from "react";

function ButtonCancell({
  children,
  style,
  onClick,
  className = "",
  type,
  ...props
}) {
  return (

    <button
      type={type}
      className={`btn button-cancell ${className}`}
      style={style}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}

export default ButtonCancell;
