import { useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5/index";
import * as am5map from "@amcharts/amcharts5/map";
//import am4geodata_continentsLow from "@amcharts/amcharts4-geodata/continentsLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";

const MapWithPinBullets = (props) => {

    const chartRef = useRef(null);

    useLayoutEffect(() => {
        const chartContainer = chartRef.current;

        const root = am5.Root.new(chartContainer);
        root._logo.dispose(); //remove logo

        // Set themes
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let data = [
            {
                id: "IN",
                name: "India",
                color: "0x0b4471"
                //value: 100
            },
            {
                id: "CN",
                name: "China",
                color: "0x0b4471"
                //value: 100
            },
            {
                id: "US",
                name: "United States",
                //value: 100
                color: "0x4caf46"
            },
            {
                id: "LK",
                name: "Sri Lanka",
                //value: 100
                color: "0x4caf46"
            }, {
                id: "VN",
                name: "Vietnam",
                //value: 100
                color: "0x4caf46"
            }, {
                id: "AR",
                name: "Argentina",
                //value: 100
                color: "0x4caf46"
            }, {
                id: "CL",
                name: "Chille",
                //value: 100
                color: "0xfab72d"
            }, {
                id: "EC",
                name: "Ecuador",
                // value: 100
                color: "0x4caf46"
            }, {
                id: "MX",
                name: "Mexico",
                // value: 100
                color: "0x4caf46"
            }, {
                id: "PA",
                name: "Panama",
                // value: 100
                color: "0x4caf46"
            }, {
                id: "PY",
                name: "Paraguay",
                // value: 100
                color: "0x4caf46"
            }, {
                id: "PI",
                name: "Philippines",
                // value: 100
                color: "0x4caf46"
            }, {
                id: "UY",
                name: "Uruguay",
                //value: 100
                color: "0xfab72d"
            }, {
                id: "VE",
                name: "Venezuela",
                //value: 100
                color: "0xfab72d"
            }, {
                id: "BD",
                name: "Bangladesh",
                //value: 100
                color: "0xfab72d"
            }, {
                id: "CM",
                name: "Cameroon",
                //value: 100
                color: "0xfab72d"
            }, {
                id: "CR",
                name: "Costa Rica",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "GH",
                name: "Ghana",
                //value: 100
                color: "0xfab72d"
            }, {
                id: "KZ",
                name: "Kazakhstan",
                //value: 100
                color: "0xfab72d"
            }, {
                id: "TZ",
                name: "Tanzania",
                //value: 100
                color: "0x69a6db"
            }, {
                id: "CR",
                name: "Costa Rica",
                //value: 100
                color: "0xfab72d"
            }, {
                id: "GB",
                name: "United Kingdom",
                //value: 100
                color: "0xfab72d"
            }, {
                id: "CN",
                name: "China",
                //value: 100
                color: "0xf0e31a"
            }, {
                id: "AU",
                name: "Australia",
                // value: 100
                color: "0x69a6db"
            }, {
                id: "CO",
                name: "Colombia",
                // value: 100
                color: "0xee7859"
            }, {
                id: "BR",
                name: "Brazil",
                // value: 100
                color: "0xee7859"
            }, {
                id: "ZA",
                name: "South Africa",
                //value: 100
                color: "0x0b4471"
            },
            {
                id: "CA",
                name: "Canada",
                //value: 100
                color: "0x0b4471"
            },
            {
                id: "RU",
                name: "Russia",
                //value: 100
                color: "0x4caf46"
            },
            {
                id: "PK",
                name: "Pakistan",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "TR",
                name: "Turkey",
                //value: 100
                color: "0x4caf46"
            },
            {
                id: "EG",
                name: "Egypt",
                //value: 100
                color: "0xf0e31a"
            },
            {
                id: "IT",
                name: "Italy",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "AF",
                name: "Afghanistan",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "AM",
                name: "Armenia",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "BE",
                name: "Belgium",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "BT",
                name: "Bhutan",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "CU",
                name: "Cuba",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "DK",
                name: "Denmark",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "ET",
                name: "Ethiopia",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "FJ",
                name: "Fiji",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "FI",
                name: "Finland",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "FR",
                name: "France",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "GA",
                name: "Gabon",
                //value: 100
                color: "0x0b4471"
            },
            {
                id: "GE",
                name: "Georgia",
                //value: 100
                color: "0x0b4471"
            },
            {
                id: "DE",
                name: "Germany",
                //value: 100
                color: "0x0b4471"
            },
            {
                id: "GR",
                name: "Greece",
                //value: 100
                color: "0x0b4471"
            },
            {
                id: "GL",
                name: "Greenland",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "HN",
                name: "Honduras",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "HK",
                name: "Hong Kong",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "HU",
                name: "Hungary",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "IS",
                name: "Iceland",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "ID",
                name: "Indonesia",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "IR",
                name: "Iran",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "IQ",
                name: "Iraq",
                //value: 100
                color: "0x0b4471"
            },
            {
                id: "IE",
                name: "Ireland",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "IL",
                name: "Israel",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "JM",
                name: "Jamaica",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "JE",
                name: "Jersey",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "JO",
                name: "Jordan",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "KE",
                name: "Kenya",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "KR",
                name: "Korea",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "KW",
                name: "Kuwait",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "LB",
                name: "Lebanon",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "LY",
                name: "Libya",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "LT",
                name: "Lithuania",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "MO",
                name: "Macao",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "MG",
                name: "Madagascar",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "MY",
                name: "Malaysia",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "MV",
                name: "Maldives",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "MU",
                name: "Mauritius",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "MN",
                name: "Mongolia",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "NP",
                name: "Nepal",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "NL",
                name: "Netherlands",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "NZ",
                name: "New Zealand",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "NG",
                name: "Nigeria",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "OM",
                name: "Oman",
                //value: 100
                color: "0xfab72d"
            },
            {
                id: "SA",
                name: "Saudi Arabia",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "SG",
                name: "Singapore",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "ZA",
                name: "South Africa",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "ES",
                name: "Spain",
                //value: 100
                color: "0x69a6db"
            },
            {
                id: "CH",
                name: "Switzerland",
                //value: 100
                color: "0xee7859"
            },
            {
                id: "TH",
                name: "Thailand",
                //value: 100
                color: "0xee7859"
            },
            {
                id: "UA",
                name: "Ukraine",
                //value: 100
                color: "0xee7859"
            },
            {
                id: "AE",
                name: "United Arab Emirates",
                //value: 100
                color: "0xee7859"
            },
            {
                id: "ZM",
                name: "Zambia",
                //value: 100
                color: "0xee7859"
            },
        ];

        let chart = root.container.children.push(am5map.MapChart.new(root, {
            panX: "none",
            panY: "none",
            projection: am5map.geoMercator(),
            maxZoomLevel: 1
        }));

        let polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: am5geodata_worldLow,
                exclude: ["AQ"],
            })
        );

        const countriesWithPins = data.map((item) => item.id);

        polygonSeries.mapPolygons.template.set("fill", am5.color(0xeef1f3)); // by default gray color

        polygonSeries.mapPolygons.template.adapters.add("fill", function (fill, target) {
            if (countriesWithPins.includes(target.dataItem.dataContext.id)) {
                let x = countriesWithPins.indexOf(target.dataItem.dataContext.id);
                return am5.color(parseInt(data[x]['color'])); // Color for countries with pins (green in this case)
            }
            return fill;
        });

        polygonSeries.mapPolygons.template.adapters.add("tooltipHTML", function (fill, target) {
            if (countriesWithPins.includes(target.dataItem.dataContext.id)) {
                return `<span style="color: white;">${target.dataItem.dataContext.name}</span>`; // Color for countries with pins (green in this case)
            }
            return "";
        });

        let bubbleSeries = chart.series.push(
            am5map.MapPointSeries.new(root, {
                // valueField: "value",
                //calculateAggregates: true,
                polygonIdField: "id"
            })
        );

        bubbleSeries.set("fill", am5.color(0xff0000));

        let circleTemplate = am5.Template.new({});

        bubbleSeries.bullets.push(function (root, series, dataItem) {
            let container = am5.Container.new(root, {});

            // let circle = container.children.push(
            //     am5.Circle.new(root, {
            //         radius: 5,
            //         fillOpacity: 0.7,
            //         fill: am5.color(0x0b4471),
            //         cursorOverStyle: "pointer",
            //         //tooltipText: `{name}`,
            //         stroke: am5.color(0x585858)
            //     }, circleTemplate)
            // );

            // let countryLabel = container.children.push(
            //     am5.Label.new(root, {
            //         text: " {name}",
            //         paddingLeft: 5,
            //         populateText: true,
            //         //fontWeight: "bold",
            //         fontSize: 13,
            //         centerY: am5.p50,
            //         fill: am5.color(0x585858)
            //     })
            // );

            // circle.on("radius", function (radius) {
            //     countryLabel.set("x", radius);
            // })

            return am5.Bullet.new(root, {
                sprite: container,
                dynamic: true
            });
        });

        bubbleSeries.bullets.push(function (root, series, dataItem) {
            return am5.Bullet.new(root, {
                sprite: am5.Label.new(root, {
                    text: "{value.formatNumber('#.')}",
                    fill: am5.color(0xffffff),
                    populateText: true,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    textAlign: "center"
                }),
                dynamic: true
            });
        });

        bubbleSeries.set("heatRules", [
            {
                target: circleTemplate,
                dataField: "value",
                min: 10,
                max: 50,
                minValue: 0,
                maxValue: 100,
                key: "radius",
            }
        ]);

        bubbleSeries.data.setAll(data);

        return () => {
            root.dispose();
        };
    },);

    return (<div>
        <h5 className='m-3 border-bottom pb-2'>{props.title}</h5>

        <div ref={chartRef} style={{ width: "90%", height: "500px", paddingLeft: "5%", paddingRight: "5%" }}>
        </div>
    </div>)
}

export default MapWithPinBullets;