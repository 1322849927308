import { useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5/index";
import * as am5map from "@amcharts/amcharts5/map";
import am5index from "@amcharts/amcharts5/index";
import am5percent from "@amcharts/amcharts5/percent";
//import am4geodata_continentsLow from "@amcharts/amcharts4-geodata/continentsLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";


const MapWithPins = (props) => {

    const chartRef = useRef(null);

    useLayoutEffect(() => {
        const chartContainer = chartRef.current;

        const root = am5.Root.new(chartContainer);
        root._logo.dispose(); //remove logo

        // Set themes
        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let data = props.data;

        // [
        //     {
        //         id: "US",
        //         name: "United States",
        //         //value: 100
        //     }, {
        //         id: "GB",
        //         name: "United Kingdom",
        //         //value: 100
        //     }, {
        //         id: "CN",
        //         name: "China",
        //         //value: 100
        //     }, {
        //         id: "IN",
        //         name: "India",
        //         //value: 100
        //     }, {
        //         id: "AU",
        //         name: "Australia",
        //         // value: 100
        //     }, {
        //         id: "CA",
        //         name: "Canada",
        //         // value: 100
        //     }, {
        //         id: "BR",
        //         name: "Brasil",
        //         // value: 100
        //     }, {
        //         id: "ZA",
        //         name: "South Africa",
        //         //value: 100
        //     }
        // ];

        let chart = root.container.children.push(am5map.MapChart.new(root, {
            panX: "none",
            panY: "none",
            projection: am5map.geoMercator(),
            maxZoomLevel: 1
        }));

        let polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: am5geodata_worldLow,
                exclude: ["AQ"],
            })
        );

        const countriesWithPins = data.map((item) => item.id);

        polygonSeries.mapPolygons.template.set("fill", am5.color(0xd4d4d4)); // by default gray color

        polygonSeries.mapPolygons.template.adapters.add("fill", function (fill, target) {
            if (countriesWithPins.includes(target.dataItem.dataContext.id)) {
                return am5.color(0x0b4471); // Color for countries with pins (green in this case)
            }
            return fill;
        });

        polygonSeries.mapPolygons.template.adapters.add("tooltipHTML", function (fill, target) {
            if (countriesWithPins.includes(target.dataItem.dataContext.id)) {
                return `<span style="color: white;">${target.dataItem.dataContext.name}</span>`; // Color for countries with pins (green in this case)
            }
            return "";
        });

        let bubbleSeries = chart.series.push(
            am5map.MapPointSeries.new(root, {
                // valueField: "value",
                //calculateAggregates: true,
                polygonIdField: "id"
            })
        );

        bubbleSeries.set("fill", am5.color(0xff0000));

        let circleTemplate = am5.Template.new({});

        bubbleSeries.bullets.push(function (root, series, dataItem) {
            let container = am5.Container.new(root, {});

            /* let circle = container.children.push(
                am5.Circle.new(root, {
                    radius: 5,
                    fillOpacity: 0.7,
                    fill: am5.color(0xdf0024),
                    cursorOverStyle: "pointer",
                    tooltipText: `{name}`
                }, circleTemplate)
            ); */

            /* let countryLabel = container.children.push(
                am5.Label.new(root, {
                    text: "{name}",
                    paddingLeft: 5,
                    populateText: true,
                    fontWeight: "bold",
                    fontSize: 13,
                    centerY: am5.p50
                })
            ); */

            /*circle.on("radius", function (radius) {
                countryLabel.set("x", radius);
            }) */

            return am5.Bullet.new(root, {
                sprite: container,
                dynamic: true
            });
        });

        bubbleSeries.bullets.push(function (root, series, dataItem) {
            return am5.Bullet.new(root, {
                sprite: am5.Label.new(root, {
                    text: "{value.formatNumber('#.')}",
                    fill: am5.color(0xffffff),
                    populateText: true,
                    centerX: am5.p50,
                    centerY: am5.p50,
                    textAlign: "center"
                }),
                dynamic: true
            });
        });

        bubbleSeries.set("heatRules", [
            {
                target: circleTemplate,
                dataField: "value",
                min: 10,
                max: 50,
                minValue: 0,
                maxValue: 100,
                key: "radius",
            }
        ]);

        bubbleSeries.data.setAll(data);

        return () => {
            root.dispose();
        };
    },);


    return (
        <div>
            <h5 className='m-3 border-bottom pb-2'>{props.title}</h5>

            <div ref={chartRef} style={{ width: "100%", height: "500px" }}>
            </div>
        </div>


    );
}

export default MapWithPins;
