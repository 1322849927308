import React from "react";
import { Link } from "react-router-dom";

function TermsOfConditionsSupplier() {
  return (
    <div>      
      
      <p>
        <b>Effective Date:</b> 25 Jan 2025
      </p>
      <p>
        <b>Last Updated:</b> 2 Dec 2024
      </p>
      <p>
        <strong>Website:</strong>{" "}
       <Link to="/">
               www.orextrade.com </Link>{" "}
      </p>

      <h2>1. Introduction</h2>
      <p>
        These <b>Supplier Terms</b> and Conditions govern the rights, obligations and
        responsibilities of suppliers who register and operate on <b>Orex Trade World</b> ("Company", "we", "our" or "us"). By accessing and using our
        platform, you agree to comply with these terms. If you do not agree, you
        must discontinue use immediately.
      </p>

      <h2>2. Supplier Registration & Eligibility</h2>
      <p>To register as a supplier on <b>Orex Trade</b>, you must:</p>
      <ul>
        <li>
          Provide accurate business details, including a valid business license,
          tax identification number and contact information.
        </li>
        <li>
          Ensure that your business complies with international trade laws and
          the regulations of the jurisdictions in which you operate.
        </li>
        <li>
          Agree to undergo verification to confirm your business legitimacy.
        </li>
      </ul>

      <h2>3. Supplier Membership Plans</h2>
      <p>Orex Trade offers three supplier membership plans:</p>

      <h3>3.1 Starter Plan</h3>
      <ul>
        <li>Suitable for small businesses and startups.</li>
        <li>Access to the global advertising website.</li>
        <li>Limited buyer and supplier connections.</li>
        <li>Basic customer support and social media marketing.</li>
      </ul>

      <h3>3.2 Advanced Business Plan</h3>
      <ul>
        <li>Ideal for growing businesses and exporters.</li>
        <li>Verified business leads and targeted global email campaigns.</li>
        <li>Enhanced social media marketing and shipment analytics.</li>
        <li>High-priority customer support.</li>
      </ul>

      <h3>3.3 Ultimate Growth Plan</h3>
      <ul>
        <li>
          Designed for large enterprises seeking full-scale trade solutions.
        </li>
        <li>Priority <b>Top 10 Search Ranking</b> on Orex Trade.</li>
        <li>Dedicated account manager and influencer brand promotions.</li>
        <li>Access to reference buyer & supplier background checks.</li>
        <li>On-site business verification.</li>
      </ul>

      <h2>4. Supplier Responsibilities</h2>
      <p>As a supplier on Orex Trade, you agree to:</p>
      <ul>
        <li>
          Provide accurate product descriptions, pricing and availability.
        </li>
        <li>
          Deliver high-quality products and comply with shipment timelines.
        </li>
        <li>
          Abide by international trade laws, import/export regulations and
          anti-fraud policies.
        </li>
        <li>
          Maintain ethical business practices and avoid false advertising.
        </li>
        <li>
          Respond promptly to inquiries from buyers and the Orex Trade support
          team.
        </li>
      </ul>

      <h2>5. Detailed Plan Terms & Conditions</h2>

      <ul className="terms-of-order">
        <li>
          <strong>Global Advertising Website:</strong> Orex Trade reserves the
          right to modify or remove this service at any time. Promotional
          banners, advertisements and supplier listings are displayed at Orex's
          discretion and may be adjusted based on user engagement, visibility
          algorithms and seasonal marketing strategies. Placement in
          high-visibility areas of the website is subject to availability and
          plan subscription levels.
        </li>
        <li>
          <strong>Priority Top 10 Search Ranking:</strong> Suppliers under
          applicable plans will be ranked within the top search results based on
          factors such as transaction volume, engagement and industry category.
          Orex's ranking algorithm prioritizes active, verified and high-volume
          suppliers while ensuring fairness in marketplace visibility.
          Adjustments to rankings may occur periodically to reflect market
          trends and system updates.
        </li>
        <li>
          <strong>Targeted Global Email Campaigns:</strong> Suppliers receive
          exposure through Orex's email marketing campaigns, targeting verified
          buyers and industry professionals. Emails are segmented by geographic
          location, trade interest and purchasing history to maximize
          conversion rates. Suppliers' product listings, promotional offers and
          company profiles may be featured in these emails based on relevance
          and buyer demand.
        </li>
        <li>
          <strong>Dedicated Manager:</strong> Suppliers enrolled in the Ultimate
          Growth Plan receive access to a dedicated account manager responsible
          for personalized business support, lead nurturing strategies and
          account optimization. The manager assists in addressing trade-related
          concerns, refining supplier profiles and ensuring compliance with
          best marketplace practices.
        </li>
        <li>
          <strong>Business Certification:</strong> This is a branding feature
          designed to enhance supplier credibility. It does not constitute a
          trade license, regulatory approval or legal certification. The
          certification is issued solely for marketing and verification purposes
          within the Orex Trade platform.
        </li>
        <li>
          <strong>Strategic Social Media Marketing:</strong> Orex promotes
          supplier content via its owned and operated social media channels,
          including LinkedIn, Twitter and Instagram. Promotional posts,
          supplier highlights and featured product advertisements are curated
          based on platform engagement metrics. Suppliers are encouraged to
          optimize their product descriptions and company profiles to enhance
          their visibility through social media promotions.
        </li>
        <li>
          <strong>Secure Trade Guarantee:</strong> This guarantee applies
          exclusively to transactions facilitated through <b>Orex-verified
          logistics partners</b>. Suppliers must notify Orex in advance about their
          chosen transportation method. If an unverified logistics provider is
          used, Orex assumes no liability for trade security. The guarantee
          ensures a layer of protection by offering dispute resolution
          assistance and trade insurance options for qualified transactions.
        </li>
        <li>
          <strong>Verified Business Leads:</strong> The volume and frequency of
          verified business leads depend on industry demand, product category
          popularity and seasonal market fluctuations. Suppliers in premium
          plans receive priority access to high-quality, vetted leads generated
          through Orex’s data-driven lead generation system.
        </li>
        <li>
          <strong>Exclusive Seasonal Trade Promotions:</strong> Orex runs
          targeted seasonal promotions featuring select supplier products on
          high-traffic sections of the platform. Participation in these
          promotions is based on product relevance, supplier engagement levels
          and promotional campaign themes. While Orex facilitates exposure,
          business conversion remains subject to buyer demand.
        </li>
        <li>
          <strong>Unlimited Buyer & Supplier Access:</strong> Suppliers gain
          unrestricted access to buyer profiles, contact details and trade
          preferences, subject to Orex’s data-sharing policies. Buyers receive
          similar access to supplier profiles within the system, fostering
          transparency and direct engagement.
        </li>
        <li>
          <strong>Advanced Shipment Analytics & Insights:</strong> Suppliers
          receive access to trade data analytics, historical shipment trends
          and predictive insights based on Orex’s database of manufacturer,
          buyer and supplier interactions. This feature aids suppliers in
          strategic pricing and market positioning.
        </li>
        <li>
          <strong>Worldwide Business Exposure:</strong> Orex leverages its
          global buyer network to enhance supplier visibility. The platform
          actively promotes supplier profiles, ensuring exposure in multiple
          international markets.
        </li>
        <li>
          <strong>Influencer Brand Promotions:</strong> Influencer marketing
          campaigns are executed based on supplier industry focus, social media
          trends and influencer availability. Orex collaborates with
          niche-specific influencers to maximize supplier branding reach.
        </li>
        <li>
          <strong>Customized Business Landing Page:</strong> A fully branded
          supplier landing page is designed to showcase company details, product
          offerings and trade history. Updates to verified pages require
          additional review to maintain credibility and prevent fraudulent
          modifications.
        </li>
        <li>
          <strong>Hot Products, Companies & Business Leads:</strong> This
          section highlights trending businesses and products based on demand
          analytics, engagement rates and real-time market data. Suppliers
          featured in this section benefit from increased visibility and
          potential business leads. Placement in this section is determined by
          Orex’s proprietary ranking algorithms and user interest levels.
        </li>
        <li>
          <strong>Company Registration Assistance:</strong> Orex provides
          guidance on company registration processes in select jurisdictions,
          ensuring compliance with international trade regulations. This service
          is subject to availability and may vary depending on the country’s
          legal requirements and business nature.
        </li>
        <li>
          <strong>Exclusive HSN Code Database Access:</strong> Suppliers gain
          access to an extensive database of Harmonized System Nomenclature
          (HSN) codes, aiding them in accurate classification and tariff
          determination for global trade. This feature is subject to database
          availability and regular updates.
        </li>
        <li>
          <strong>Global Trade News Updates:</strong> Orex provides the latest
          insights, news and trade policy updates relevant to suppliers. These
          updates help suppliers stay informed about industry shifts, regulatory
          changes and emerging opportunities in global trade.
        </li>
        <li>
          <strong>Comprehensive Company Profile:</strong> Suppliers receive a
          detailed company profile showcasing their business, products and
          trade history. This profile is visible to potential buyers and can be
          used as a credibility tool to enhance trust in transactions.
        </li>
        <li>
          <strong>Downloadable Verified Contact Profiles:</strong> This feature
          allows suppliers to access verified contact details of buyers and
          business partners for networking and lead generation. Availability of
          verified contacts is subject to Orex’s data verification protocols.
        </li>
        <li>
          <strong>Expert Brand Strategy & Guidance:</strong> Suppliers receive
          strategic insights and marketing recommendations from Orex’s trade
          experts. This guidance is designed to optimize supplier branding,
          positioning and lead conversion effectiveness.
        </li>
        <li>
          <strong>Reference Buyer & Supplier Background Checks:</strong> This
          service is exclusively available to <b>Ultimate Growth Plan</b> members. Orex
          conducts background verification on buyers and suppliers upon request.
          The requesting party must cover all costs associated with the
          verification process.
        </li>
        <li>
          <strong>On-Site Business Verification:</strong> Suppliers in the
          <b> Ultimate Growth Plan</b> can opt for on-site verification services to
          authenticate their business operations. Orex will conduct due
          diligence through physical site inspections and associated costs must
          be borne by the requesting supplier or buyer.
        </li>
        <li>
          <strong>User Access:</strong> This feature allows businesses to grant
          account access to two authorized users, facilitating seamless team
          collaboration and operational efficiency. If the plan is for one user,
          then only one user will be able to access at a time. If another user
          logs in, the system will automatically log out the first user to
          ensure security and prevent simultaneous access.
        </li>
      </ul>

      <h2>6. Fees, Payments & Refunds:</h2>

      <ul>
        <li> <b>Membership fees are non-refundable</b> unless explicitly stated.</li>
        <li>Payments must be made through approved payment gateways.</li>
        <li>
          Failure to pay membership fees may result in <b>account suspension or
          termination</b>.
        </li>
        <li>
          Orex Trade reserves the right to modify pricing and service features
          with notice.
        </li>
      </ul>

      <h2>7. Dispute Resolution & Liability:</h2>
      <ul>
        <li>
          Suppliers are responsible for handling buyer disputes related to
          product quality, pricing and delivery.
        </li>
        <li>
          Orex Trade acts as a <b>B2B platform</b> and is <b>not</b> liable for transactions
          between suppliers and buyers.
        </li>
        <li>
          Any disputes between suppliers and Orex Trade must be resolved through
          <b>binding arbitration</b>.
        </li>
      </ul>

      <h2>8. Termination & Compliance:</h2>
      <ul>
        <li>
          Orex Trade reserves the right to <b>suspend or terminate supplier
          accounts</b> for violating these terms.
        </li>
        <li>
          Termination may occur due to fraud, policy violations or repeated
          buyer complaints.
        </li>
        <li>
          Suppliers must adhere to international business laws and ethical trade
          practices.
        </li>
      </ul>

      <h2>9. Contact Information:</h2>
      <li>
        <p>
          For any questions or concerns regarding these <b>Supplier Terms and
          Conditions</b>, contact us:
        </p>
        <ul>
          <li>
            <b>Email</b>:{" "}
            <a href="mailto:support@orextrade.com">support@orextrade.com</a>
          </li>
          <li><b>WhatsApp</b>: <b>+1 (786) 772-5447</b> </li>
          <li>
            <b>Website</b>: <Link to="/">
                    www.orextrade.com </Link>{" "}
          </li>
        </ul>
      </li>
      <p>
        These <b>Supplier Terms and Conditions</b> ensure a fair, secure and
        professional trade environment for all businesses using <b>Orex Trade
        World.</b>
      </p>
    </div>
  );
}

export default TermsOfConditionsSupplier;
