import React from 'react'

import { includes, isEmpty, toLower } from 'lodash';
import defaultValues from '../../constant/defaultValues';

import companylogo from "../../images/Company-logo.svg"
import veryfied from "../../images/veryfied_sticker.svg"
import NotveryfileProfile from "../../images/Not_verified_profile.png"

import Facebook from "../../images/fb.svg";
import linkedIn from "../../images/linkedIn.svg";
import insta from "../../images/insta.svg";
import youtube from "../../images/youTube.svg";
import twitter from "../../images/twitter.svg";
import Other from "../../images/Other.svg";


function CompanyInfo(props) {

  const cInfo = props.data;

  const { IMAGE_PATH } = defaultValues;

  const getWebsiteURL = () => {

    if (cInfo && cInfo.website) {
      return cInfo && cInfo.website && isEmpty(cInfo.website) ? "-" :
        cInfo && cInfo.website && !isEmpty(cInfo.website) && (cInfo.website.startsWith("http://") || cInfo.website.startsWith("https://"))
          ? <a href={cInfo.website} target='_blank' rel="noreferrer">{cInfo.website}</a>
          : <a href={`https://${cInfo.website}`} target='_blank' rel="noreferrer">{cInfo.website}</a>
    } else {
      return "-";
    }
  }

  return (

    <div className='company-card company-card-info'>

      <div className='company-info-logo'>

        <div className=' company-prifile-logo mt-4'>

          <div className={` ${cInfo && cInfo.company_logo ? `viewprofile-img-effect` : ``}`}>

            <img src={cInfo && cInfo.company_logo ? IMAGE_PATH.BASE_URL + '/company_logo/' + cInfo.company_logo : companylogo} alt="" height={150} className='border p-1 profile-imges' />

          </div>


          <div className='company-socialmedia-main'>
            <div className='company-socialmedia'>

              {
                cInfo && cInfo.social_medias && cInfo.social_medias.map((val) => {
                  let imgSrc = Other;
                  if (!isEmpty(val.detail)) {
                    let detail = JSON.parse(val.detail);
                    let l = detail.label;
                    let lbl = l;
                    if (!isEmpty(l)) {
                      lbl = toLower(l);
                    }

                    if (!isEmpty(lbl)) {
                      switch (lbl) {
                        case "facebook":
                          imgSrc = Facebook;
                          break;
                        case "twitter":
                          imgSrc = twitter;
                          break;
                        case "instagram":
                          imgSrc = insta;
                          break;
                        case "youtube":
                          imgSrc = youtube;
                          break;
                        case "linkedin":
                          imgSrc = linkedIn;
                          break;
                        default:
                          imgSrc = Other;
                          break;
                      }

                      let link = detail.Link;

                      if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
                        return (<a href={`${link}`} target='_blank'><img src={imgSrc} alt="" className='m-1' /></a>)
                      } else {
                        return (<a href={`http://${detail.Link}`} target='_blank'><img src={imgSrc} alt="" className='m-1' /></a>)
                      }

                    }
                  }
                })
              }

            </div>
          </div>

        </div>

        <div className='NFT_Roadway mt-4'>

          <div className='NFT-company-name'>
            <h3 >{cInfo && cInfo.company_name}</h3>
            <span>{cInfo && cInfo.city && `${cInfo.city}, `} {cInfo && cInfo.country}</span>
            {cInfo && (cInfo.status === 1) && <h6>  <img src={veryfied} alt="" /> Verified </h6>}
            {/* (OTB100003) */}
          </div>

          {/* <div className='row mt-4'>

            <p className='col-xl-6  company-roadway-detials'>Company Name</p>
            <p className='col-xl-6  company-roadway-detials-info'>{cInfo && cInfo.company_name}</p>

          </div> */}

          <div className='mt-4'>
            <div className='row'>

              <p className='col-md-5 company-roadway-detials'>Orex ID</p>
              <p className='col-md-7 company-roadway-detials-info'>{(cInfo && !isEmpty(cInfo.orexID)) ? cInfo.orexID : "-"}</p>

            </div>
            <div className='row '>

              <p className='col-md-5 company-roadway-detials'>Established Year</p>
              <p className='col-md-7 company-roadway-detials-info'>{(cInfo && cInfo.est_dt > 0) ? (cInfo.est_dt) : '-'}</p>

            </div>

            <div className='row'>

              <p className='col-md-5 company-roadway-detials'>Industry of Work</p>
              <p className='col-md-7 company-roadway-detials-info'> {(cInfo && !isEmpty(cInfo.industry)) ? cInfo.industry : '-'}</p>

            </div>

            <div className='row'>

              <p className='col-md-5 company-roadway-detials'>Company Size</p>
              <p className='col-md-7 company-roadway-detials-info'>{(cInfo && !isEmpty(cInfo.company_size)) ? cInfo.company_size : '-'}</p>

            </div>

            <div className='row'>

              <p className='col-md-5 company-roadway-detials'>Trade Mode</p>
              <p className='col-md-7 company-roadway-detials-info'>{(cInfo && !isEmpty(cInfo.shipment_mode)) ? cInfo.shipment_mode : '-'}
                {/* {(cInfo && includes(["Trader", "Manufacturer"], cInfo.shipment_mode) &&
                  <span>
                    <span> {`(`}</span>
                    <span className='text-capitalize'>{(toLower(cInfo.trade_mode_type))}</span>
                    <span>{`)`}</span>
                  </span>
                )} */}
              </p>

            </div>


            <div className='row'>

              <p className='col-md-5 company-roadway-detials'>Website</p>
              <p className='col-md-7 company-roadway-detials-info'>
                {
                  getWebsiteURL()
                }

                {/* {(cInfo && !isEmpty(cInfo.website)) ? cInfo.website && <a href={`https://${cInfo.website}`} target='_blank' rel="noreferrer">{cInfo.website}</a> : '-'} */}
              </p>

            </div>
          </div>

        </div>
        {/* {
          cInfo && (cInfo.verfied_company === false) &&
          <div className='col-xl-3'>
            <img src={NotveryfileProfile} alt="" />
          </div>
        } */}
      </div>

    </div >

  )
}

export default CompanyInfo;
