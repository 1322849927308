import React from "react";
import regImg from "../imgs/reg-1.svg";
import { Link, useNavigate } from "react-router-dom";

function Register() {
  return (
    <section className="register-wrape strip">
      <span className="decorative">
        <i className="sprite"></i>
      </span>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-1 col-md-12 col-sm-12"></div>
          <div className="col-lg-10 col-md-12 col-sm-12">
            <div className="register-section">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-8 col-md-12 col-sm-12 col-xl-8">
                    <div className="section-head dark">
                      <div className="head">
                        <p>JOIN US IN</p>
                        <h2>
                        TRANSFORMING TRADE
                        </h2>
                      </div>

                      <p className="text">
                        Orex Trade is more than a platform—it's a community of businesses working together to achieve success. Whether you're looking to expand your market, find new Buyers/Suppliers or explore global opportunities, we are here to make it happen.<br />
                        Any query email us on <Link to="mailto:support@orextrade.com">support@orextrade.com</Link>
                        </p>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 col-xl-4 row align-items-center">
                    <Link to={"/registration"} className="register-btn-big">
                      <span><i className="sprite"></i></span> Register Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-1 col-md-12 col-sm-12"></div>
        </div>
      </div>
    </section>
  );
}

export default Register;
