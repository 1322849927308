import React, { useEffect, useState } from "react";
import Footer from "../Footer";

import "../../src/css/lightbox/lightboxstyle.css";
import fjGallery from "flickr-justified-gallery";

import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

import axios from "axios";
import API_URL from "../config/apiConfig";

function OurGallery() {
  const [allImages, setAllImages] = useState(null);
  const [imageAvailable, setImageAvailable] = useState(false);

  useEffect(() => {
    if (imageAvailable) {
      fjGallery(document.querySelectorAll(".gallery"), {
        itemSelector: ".gallery__item",
        rowHeight: 180,
        lastRow: "start",
        gutter: 2,
        rowHeightTolerance: 0.1,
        calculateItemsHeight: false,
      });
    }
  }, [imageAvailable]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const fetchImages = async () => {
    await axios
      .get(API_URL + "/image-gallery")
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === true) {
            setAllImages(response.data.data);
            setImageAvailable(true);
          }
        }
      })
      .catch((err) => {
        console.log("Error ========== ", err);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <div className="gallery-wraper">
      <section className="inner-pad page contact-us-wrape">
        <section className="inner-banner-wrape global-advertising our-gallery banner-wrape">
          <div className="inner"> Event Highlights</div>
          <span className="sprite decoration"></span>
        </section>
      </section>

      <div className="image-gallery-wrape container">
        <div className="section-head">
          <div className="head">
            <p>Orex Trade to</p>
            <h2>
              <span>Showcase of</span> Excellence
            </h2>
          </div>
          <p className="text">
            Step into our gallery and explore a curated selection of product
            visuals. Whether through crisp images or engaging videos, get an
            up-close experience of the quality, functionality and innovation we
            bring.
          </p>
        </div>

        {imageAvailable && (
          <LightGallery
            plugins={[lgZoom, lgVideo]}
            mode="lg-fade"
            pager={false}
            thumbnail={true}
            galleryId={"nature"}
            autoplayFirstVideo={false}
            elementClassNames={"gallery"}
            mobileSettings={{
              controls: false,
              showCloseIcon: false,
              download: false,
              rotate: false,
            }}
          >
            {allImages &&
              allImages.gulfood.map((gImg) => {
                return (
                  <div
                    data-pinterest-text="Pin it2"
                    data-tweet-text="lightGallery slide  2"
                    className="gallery__item"
                    data-src={gImg.image}
                    data-sub-html="<h4>Gulfood Dubai 2025</h4>"
                  >
                    <a
                      data-pinterest-text="Pin it2"
                      data-tweet-text="lightGallery slide  2"
                      data-src={gImg.image}
                      data-sub-html="<h4>Gulfood Dubai 2025</h4>"
                    >
                      <img className="img-responsive" src={gImg.image} />
                    </a>
                  </div>
                );
              })}

            {allImages &&
              allImages.conference.map((conImg) => {
                return (
                  <div
                    data-pinterest-text="Pin it2"
                    data-tweet-text="lightGallery slide  2"
                    className="gallery__item"
                    data-src={conImg.image}
                    data-sub-html="<h4>WTC AGM 2025 at Dubai</h4>"
                  >
                    <a
                      data-pinterest-text="Pin it2"
                      data-tweet-text="lightGallery slide  2"
                      //className="gallery__item1"
                      data-src={conImg.image}
                      data-sub-html="<h4>WTC AGM 2025 at Dubai</h4>"
                    >
                      <img className="img-responsive" src={conImg.image} />
                    </a>
                  </div>
                );
              })}
          </LightGallery>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default OurGallery;
