import React from "react";
import FindBuyerIMG from "../imgs/find-buyer-img.svg";

function FindBuyer() {
  return (
    <section className="find-buyer-wrape">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-12 col-sm-12">
            <div className="img-wrape">
              <div className="buble-lable">
                <h5>4.3M</h5>
                <p>Verified Clients Worldwide</p>
              </div>
              <img src={FindBuyerIMG} alt="" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="section-head">
              <div className="head">
                <p>Orex Trade to</p>
                <h2>
                  <span>Find</span> Buyers
                </h2>
              </div>

              <p className="text">
                Finding buyers for your products is essential for a successful
                trading business. Here's how Orex Trade can be used effectively
                to identify potential buyers
              </p>
            </div>

            <div className="container-fluid usp-wrape">
              <div className="row">
                <div className="col-lg-6 col-sm-12 col-md-12">
                  <div className="usp one">
                    <i className="sprite search"></i>
                    <div className="detail">
                      <h3>Keyword Search</h3>
                      <p>
                        Orex Trade allows you to search for buyers by using
                        specific keywords related to your product. Simply input
                        the relevant product keywords and Orex Trade will
                        provide you with a <b>list of potential buyers</b> that
                        match your criteria. This feature helps you target
                        buyers who are actively searching for products like
                        yours.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-md-12">
                  <div className="usp two">
                    <i className="sprite analytic"></i>
                    <div className="detail">
                      <h3>Analytics and Insights</h3>
                      <p>
                        Orex Trade provides valuable analytics and insights into
                        global trade trends. By <b>analyzing trade data</b>, you
                        can identify countries or regions with high demand for
                        your product. This knowledge can help you target
                        potential buyers more effectively and expand your
                        customer base.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-md-12">
                  <div className="usp three">
                    <i className="sprite certified"></i>
                    <div className="detail">
                      <h3>Certified Buyer Profiles</h3>
                      <p>
                        Orex Trade offers detailed <b>Certified profiles</b> of
                        buyers, including information on their product
                        preferences. These profiles provide valuable insights
                        into buyer behavior and help you tailor your marketing
                        strategies accordingly.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-md-12">
                  <div className="usp four">
                    <i className="sprite identify"></i>
                    <div className="detail">
                      <h3>Identify The Right Market</h3>
                      <p>
                        Our AI system first analyzes your products using the HSN
                        code and identifies the <b>target market and country</b>{" "}
                        based on import/export data. Then, our system helps you
                        find buyers in that region while uncovering promising
                        opportunities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FindBuyer;
