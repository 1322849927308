import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import Button from "../Button";
import { useEffect, useState } from "react";
import InputField from "../InputField";
import defaultValues from "../../constant/defaultValues";
import { connect } from "react-redux";
import { getCountryList } from "../../actions/defaultDataAction";
import errorMessages from "../../constant/errorMessages";
import axios from "axios";
import API_URL from "../../config/apiConfig";
import { setAuthHeaders, showErrorMessage, showSuccessMessage } from "../../utils/CustomNotification";
import { useAuth } from "../../AuthProvider";
import axiosHttp from "../../axiosHttp";
import ButtonCancell from "../ButtonCancell";
import ButtonAdminpanel from "../ButtonAdminpanel";
import { trim } from "lodash";
import { hideLoading, showLoading } from "../../actions/loaderAction";

function SupplierInquiry(props) {

  const { user } = useAuth();

  const initialState = {
    company_name: user.company_name,
    contact_person: user.name,
    email: user.email,
    phone: "",
    industry: "",
    country: "",
    product: "",
    quantity: "",
    unit: "",
    message: "",
    country_from: "",
    country_to: "",
    freight_type: "",
    port_of_destination: "",
    port_of_origin: "",
    ocean_type: "",
    lwh: "",
    cbpp: "",
    total_weight: "",
    term: "",
    no_of_containers: "",
    type: "Supplier",
    commodity: "",
    emailIds: "",
    member_id: user && user.email === "demo@orextrade.com" ? 0 : parseInt(props.member_id)
  };

  const invalidEmails = defaultValues.IGNORE_EMAILS;
  const [formData, setFormData] = useState(initialState);
  const [countryList, setCountryList] = useState([]);
  const industryList = defaultValues.INDUSTRIES_LIST;
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [phoneValue, setphoneValue] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);


  const handleChange = (e) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handlePhoneChange = (val) => {
    setphoneValue(val);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        phone: val,
      };
    });
  };

  const handleCountryChange = (cval) => {
    const countryId = cval.value;
    setSelectedCountry(cval);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        country: countryId,
      };
    });

  };

  const handleIndustryChange = (cval) => {
    setSelectedIndustry(cval);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        industry: cval.value,
      };
    });

  };




  const fetchCountries = async () => {
    await props.getCountryList().then((data) => {
      const options = data.map((country) => ({
        value: country.name,
        label: country.name,
        countryCode: country.iso2,
      }));
      setCountryList(options);
    }).catch((err) => {
      console.log("Error >>>> ", err);
    })
  }

  useEffect(() => {
    fetchCountries();
  }, []);

  const styleError = {
    display: "block",
  };


  const handleFocusOut = async (e) => {

    let flag = 0;
    let er = { ...errors };

    if (e.target.name === 'company_name') {
      if (e.target.value === "") {
        flag = 1;
        er.company_name = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.company_name = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9 .&@]+$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.company_name = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.company_name = "";
        }
      }
    }

    if (e.target.name === 'contact_person') {
      if (e.target.value === "") {
        flag = 1;
        er.contact_person = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.contact_person = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.contact_person = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.contact_person = "";
        }
      }
    }

    if (e.target.name === 'email') {
      if (e.target.value === "") {
        flag = 1;
        er.email = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.email = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const currentEmail = e.target.value.split("@")[1];
        const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else if (invalidEmails.includes(currentEmail)) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.email = "";
        }
      }
    }

    if (e.target.name === "phone") {
      let phoneValue = e.target.value.trim();
      let numberWithoutCountryCode = phoneValue.replace(/^\+(\d{1,4})\s?/, "").replace(/\s/g, "");
      if (numberWithoutCountryCode === '+') {
        flag = 0;
        er.phone = "";
      }
      if (phoneValue !== '' && phoneValue.length > 4 && numberWithoutCountryCode > 0) {
        if (!isValidPhoneNumber(phoneValue)) {
          flag = 1;
          er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
        } else {
          flag = 0;
          er.phone = "";
        }
      }
    }

    if (e.target.name === 'industry') {
      if (e.target.value === '') {
        flag = 1;
        er.industry = errorMessages.FIELD_REQUIRED;
      } else {
        flag = 0;
        er.industry = "";
      }
    }

    if (e.target.name === 'country') {
      if (e.target.value === '') {
        flag = 1;
        er.country = errorMessages.FIELD_REQUIRED;
      } else {
        flag = 0;
        er.country = "";
      }
    }

    if (e.target.name === 'product') {
      if (e.target.value === "") {
        flag = 1;
        er.product = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.product = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.product = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.product = "";
        }
      }

    }

    if (e.target.name === 'quantity') {
      let quantity = e.target.value;
      let quantityReg = /^(?!0(\.0+)?$)(\d+(\.\d+)?|\.\d+)$/
      if (e.target.value !== '' && quantity.length > 0) {
        const check = quantityReg.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.quantity = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.quantity = '';
        }
      }
    }

    if (e.target.name === 'unit') {
      let unit = e.target.value;
      let untiEpr = /^[a-zA-Z]+(\s*[-&]?\s*[a-zA-Z]+)*(\s*\(\s*[a-zA-Z]+\s*\))?$/
      if (e.target.value !== '' && unit.length > 0) {
        const check = untiEpr.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.unit = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.unit = '';
        }

      }
    }

    if (e.target.name === "emailIds") {
      if (e.target.value === '') {
        flag = 1;
        er.emailIds = errorMessages.FIELD_REQUIRED;
      } else {
        let fEmails = e.target.value;
        fEmails = fEmails.split(",");
        if (fEmails.length > 0) {
          for (let fe of fEmails) {
            const currentEmail = fe.split("@")[1];
            const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const check = regExp.test(fe);
            if (check === false) {
              flag = 1;
              er.emailIds = errorMessages.IN_VALID_FIELD_FORMAT;
            } else if (invalidEmails.includes(currentEmail)) {
              flag = 1;
              er.emailIds = errorMessages.IN_VALID_FIELD_FORMAT;
            } else {
              flag = 0;
              er.emailIds = "";
            }
          }
        }

      }
    }



    setErrors(er);
    if (flag === 0) {
      setisButtonDisabled(false);
      return false;
    } else {

      return true;
    }
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    props.setMediumModalShow(false);
  }

  const validation = () => {
    let er = { ...errors };
    let flag = 0;
    let isValid = true;

    if (!formData.company_name || formData.company_name === "") {
      flag = 1;
      isValid = false;
      er.company_name = errorMessages.FIELD_REQUIRED;
    } else if (formData.company_name.length > 40) {
      flag = 1;
      isValid = false;
      er.company_name = errorMessages.MAX_LENGTH_EXCEEDED;
    } else {
      const regExp = /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9 .&@]+$/;
      const check = regExp.test(formData.company_name);
      if (check === false) {
        isValid = false;
        flag = 1;
        er.company_name = errorMessages.IN_VALID_FIELD_FORMAT;
      } else {
        flag = 0;
        er.company_name = "";
      }
    }


    if (formData.contact_person === "") {
      isValid = false;
      flag = 1;
      er.contact_person = errorMessages.FIELD_REQUIRED;
    } else if (formData.contact_person.length > 40) {
      isValid = false;
      flag = 1;
      er.contact_person = errorMessages.MAX_LENGTH_EXCEEDED;
    } else {
      const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

      const check = regExp.test(formData.contact_person);
      if (check === false) {
        isValid = false;
        flag = 1;
        er.contact_person = errorMessages.IN_VALID_FIELD_FORMAT;
      } else {
        flag = 0;
        er.contact_person = "";
      }
    }

    if (formData.email === "") {
      isValid = false;
      flag = 1;
      er.email = errorMessages.FIELD_REQUIRED;
    } else if (formData.email.length > 40) {
      isValid = false;
      flag = 1;
      er.email = errorMessages.MAX_LENGTH_EXCEEDED;
    } else {
      const currentEmail = formData.email.split("@")[1];
      const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const check = regExp.test(formData.email);
      if (check === false) {
        isValid = false;
        flag = 1;
        er.email = errorMessages.IN_VALID_FIELD_FORMAT;
      } else if (invalidEmails.includes(currentEmail)) {
        isValid = false;
        flag = 1;
        er.email = errorMessages.IN_VALID_FIELD_FORMAT;
      } else {
        flag = 0;
        er.email = "";
      }
    }


    if (phoneValue) {
      let numberWithoutCountryCode = phoneValue.replace(/^\+(\d{1,4})\s?/, '');
      numberWithoutCountryCode = numberWithoutCountryCode.replace(/\s/g, '');
      if (!phoneValue || !isValidPhoneNumber(phoneValue) && numberWithoutCountryCode.length > 0) {
        flag = 1;
        isValid = false;
        er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
      }
    }


    if (formData.industry === '') {
      isValid = false;
      flag = 1;
      er.industry = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.industry = "";
    }

    if (formData.country === '') {
      isValid = false;
      flag = 1;
      er.country = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.country = "";
    }


    if (formData.product === "") {
      isValid = false;
      flag = 1;
      er.product = errorMessages.FIELD_REQUIRED;
    } else if (formData.product.length > 40) {
      isValid = false;
      flag = 1;
      er.product = errorMessages.MAX_LENGTH_EXCEEDED;
    } else {
      const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

      const check = regExp.test(formData.product);
      if (check === false) {
        isValid = false;
        flag = 1;
        er.product = errorMessages.IN_VALID_FIELD_FORMAT;
      } else {
        flag = 0;
        er.product = "";
      }
    }



    let quantity = formData.quantity;
    let quantityReg = /^(?!0(\.0+)?$)(\d+(\.\d+)?|\.\d+)$/;
    if (quantity !== '' && quantity.length > 0) {
      const check = quantityReg.test(quantity);
      if (check === false) {
        isValid = false;
        flag = 1;
        er.quantity = errorMessages.IN_VALID_FIELD_FORMAT;
      } else {
        flag = 0;
        er.quantity = '';
      }
    }

    let unit = formData.unit;
    let untiEpr = /^[a-zA-Z]+(\s*[-&]?\s*[a-zA-Z]+)*(\s*\(\s*[a-zA-Z]+\s*\))?$/;
    if (unit !== '' && unit.length > 0) {
      const check = untiEpr.test(unit);
      if (check === false) {
        isValid = false;
        flag = 1;
        er.unit = errorMessages.IN_VALID_FIELD_FORMAT;
      } else {
        flag = 0;
        er.unit = '';
      }

    }


    if (user && user.email === "demo@orextrade.com") {
      if (formData.emailIds === '') {
        flag = 1;
        isValid = false;
        er.emailIds = errorMessages.FIELD_REQUIRED;
      } else {
        let fEmails = formData.emailIds.split(",");
        if (fEmails.length > 0) {
          for (let fe of fEmails) {
            let fe1 = trim(fe);
            const currentEmail = fe1.split("@")[1];
            const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const check = regExp.test(fe1);
            if (check === false) {
              isValid = false;
              flag = 1;
              er.emailIds = errorMessages.IN_VALID_FIELD_FORMAT;
            } else if (invalidEmails.includes(currentEmail)) {
              isValid = false;
              flag = 1;
              er.emailIds = errorMessages.IN_VALID_FIELD_FORMAT;
            } else {
              flag = 0;
              er.emailIds = "";
            }
          }
        }
      }
    }

    setErrors(er);

    if (!isValid) {
      return false;
    }

    return true;



  };


  const handleSupplierInquirySubmit = async (e) => {
    e.preventDefault();
    const headers = setAuthHeaders();

    if (validation()) {
      await props.showLoading();
      try {
        setisButtonDisabled(true);
        await axiosHttp
          .post(API_URL + "/post-inquiry", formData, {
            headers,
            timeout: 10000,
          })
          .then((response) => {
            props.hideLoading();
            if (response.status === 200) {
              if (response.data.status == true) {
                showSuccessMessage("Inquiry Posted Successfully!");
                setTimeout(() => {
                  setFormData(initialState);
                  setErrors({});
                  setphoneValue("");
                  setSelectedIndustry(null);
                  setSelectedCountry(null);
                  setisButtonDisabled(false);
                  props.setMediumModalShow(false);
                }, 1000);

              } else {
                setisButtonDisabled(false);
                setErrors([]);
                const errors = {};
                if (response.data.errors.company_name) {
                  errors.company_name = response.data.errors.company_name;
                }
                if (response.data.errors.contact_person) {
                  errors.contact_person = response.data.errors.contact_person;
                }
                if (response.data.errors.email) {
                  errors.email = response.data.errors.email;
                }
                if (response.data.errors.country) {
                  errors.country = response.data.errors.country;
                }
                if (response.data.errors.industry) {
                  errors.industry = response.data.errors.industry;
                }
                if (response.data.errors.product) {
                  errors.product = response.data.errors.product;
                }

                if (response.data.errors.quantity) {
                  errors.quantity = response.data.errors.quantity;
                }

                if (response.data.errors.unit) {
                  errors.unit = response.data.errors.unit;
                }

                setErrors(errors);
                return Object.keys(errors).length > 0;

              }
            }

          }).catch((err) => {
            props.hideLoading();
            setisButtonDisabled(false);
            console.log("Eror =============", err);
            showErrorMessage("Something Went Wrong!");
          })
      } catch (err) {
        props.hideLoading();
        console.log("Eror =============", err);
        showErrorMessage("Something Went Wrong!");
      }
    }

  }

  return (<>
    <form onSubmit={handleSupplierInquirySubmit}>
      <div className="row">
        <div className="col-sm-6 ">
          <div className="input-group">
            <label className="form-label required">Company Name</label>
            <div className="input-pad">
              <InputField name="company_name"
                className="form-control"
                autoComplete="off"
                placeholder="Enter company name"
                onChange={handleChange}
                value={formData.company_name}
                onBlur={handleFocusOut}
                readOnly={true}
              />

              {errors.company_name && (
                <span
                  className="company-required"
                  style={styleError}
                >
                  {errors.company_name}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="input-group">
            <label className="form-label required">Contact Person</label>
            <div className="input-pad">
              <InputField
                type="text"
                name="contact_person"
                value={formData.contact_person}
                className="form-control"
                autoComplete="off"
                placeholder="Enter contact person"
                onChange={handleChange}
                onBlur={handleFocusOut}
                readOnly={true}
              />
              {errors.contact_person && (
                <span
                  className="company-required"
                  style={styleError}
                >
                  {errors.contact_person}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-sm-6 ">
          <div className="input-group">
            <label className="form-label required">Email</label>
            <div className="input-pad">
              <InputField
                type="text"
                name="email"
                className="form-control"
                value={formData.email}
                autoComplete="off"
                placeholder="Enter email"
                onChange={handleChange}
                onBlur={handleFocusOut}
                readOnly={true}
              />
              {errors.email && (
                <span
                  className="company-required"
                  style={styleError}
                >
                  {errors.email}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="col-sm-6 ">
          <div className="input-wrape">
            <label className="form-label">
              Phone Number
            </label>
            <div className="input-pad">
              <PhoneInput
                id="contact-form-phone"
                className="form-control inquiry-phone inp-font-size border-dropdown company-representive-border outer_pages_phone_input"
                name="phone"
                international
                limitMaxLength
                placeholder="Enter phone number"
                autoComplete="off"
                value={phoneValue}
                onChange={handlePhoneChange}
                onBlur={handleFocusOut}

              />
              {errors.phone && (
                <span
                  className="company-required"
                  style={styleError}
                >
                  {errors.phone}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="col-sm-6 ">
          <div className="input-group">
            <label className="form-label required">Industry</label>
            <div className="input-pad">
              <Select
                name="industry"
                options={industryList}
                value={selectedIndustry}
                onChange={handleIndustryChange}
                placeholder="Select industry"
                className="dd-pad"
                noOptionsMessage={() => "No Industry Found"}
                onBlur={handleFocusOut}
              />
              {errors.industry && (
                <span
                  className="company-required"
                  style={styleError}
                >
                  {errors.industry}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="col-sm-6 ">
          <div className="input-group">
            <label className="form-label required">Country</label>
            <div className="input-pad">
              <Select
                name="country"
                options={countryList}
                value={selectedCountry}
                onChange={handleCountryChange}
                placeholder="Select country"
                className="dd-pad"
                onBlur={handleFocusOut}
                noOptionsMessage={() => "No Country Found"}
              />
              {errors.country && (
                <span
                  className="company-required"
                  style={styleError}
                >
                  {errors.country}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="col-sm-6 ">
          <div className="input-group">
            <label className="form-label required">Product</label>
            <div className="input-pad">

              <InputField
                type="text"
                name="product"
                className="form-control"
                value={formData.product}
                autoComplete="off"
                placeholder="Enter product"
                onChange={handleChange}
                onBlur={handleFocusOut}
              />

              {errors.product && (
                <span
                  className="company-required"
                  style={styleError}
                >
                  {errors.product}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="col-sm-3 ">
          <div className="input-group">
            <label className="form-label">Quantity</label>
            <div className="input-pad">
              <InputField
                type="text"
                name="quantity"
                value={formData.quantity}
                className="form-control"
                autoComplete="off"
                placeholder="Enter quantity"
                onChange={handleChange}
                onBlur={handleFocusOut}
              />
              {errors.quantity && (
                <span
                  className="company-required"
                  style={styleError}
                >
                  {errors.quantity}
                </span>
              )}
            </div>
          </div>
        </div>



        <div className="col-sm-3 ">
          <div className="input-group">
            <label className="form-label">Unit</label>
            <div className="input-pad">
              <InputField
                type="text"
                name="unit"
                value={formData.unit}
                className="form-control"
                autoComplete="off"
                placeholder="Enter unit"
                onChange={handleChange}
                onBlur={handleFocusOut}
              />
              {errors.unit && (
                <span
                  className="company-required"
                  style={styleError}
                >
                  {errors.unit}
                </span>
              )}
            </div>
          </div>
        </div>




        <div className="col-sm-12 ">
          <hr />
        </div>
        <div className="col-12">
          <div className="input-wrape">
            <label
              className="form-label inquiry-text"
              for="contact-form-message"
            >
              Remarks
            </label>
            <div className="input-pad">
              <textarea
                id="contact-form-message"
                className="form-control"
                rows="3"
                placeholder="Remarks...."
                name="message"
                value={formData.message}
                onChange={handleChange}

              ></textarea>
              {errors.message && (
                <span
                  className="company-required"
                  style={styleError}
                >
                  {errors.message}
                </span>
              )}
            </div>
          </div>
        </div>

        {
          user && user.email === "demo@orextrade.com" && (<><div className="col-md-12">
            <label className="form-label required">Recipient Email</label>  <InputField name="emailIds" type="text" value={formData.emailIds} onChange={handleChange} onBlur={handleFocusOut} />
            <em>(Multiple emails can be added using comma separated)</em>
          </div>{errors.emailIds && (
            <span
              className="company-required"
              style={styleError}
            >
              {errors.emailIds}
            </span>
          )}</>)
        }


        <div className="col-md-12 inquiry-form-btn ">
          {
            props.pageView === "pop_up" &&
            <ButtonCancell
              className="inq-form-btn"
              type="button"
              // disabled={isButtonDisabled}
              onClick={handleCancelClick}
            > Cancel
            </ButtonCancell>
          }
          <ButtonAdminpanel type="submit"
            className="inq-form-btn"
            disabled={isButtonDisabled}>
            Send Inquiry
          </ButtonAdminpanel>


        </div>

      </div>
    </form>
  </>)
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    getCountryList: () => dispatch(getCountryList()),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierInquiry); 
