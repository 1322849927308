import React, { useEffect, useState } from "react";

function OurAchivment() {
  const [counter80, setCounter80] = useState(0);
  const [counter56, setcounter56] = useState(0);
  const [counter5, setCounter5] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Start the counting when scroll position is >= 500 and counter is less than 100
      if (scrollPosition >= 1700) {
        setInterval(() => {
          setCounter80((prevCounter) => {
            if (prevCounter < 80) {
              return prevCounter + 1;
            }
            return 80; // Stop at 100
          });
        }, 100);

        setInterval(() => {
          setcounter56((prevCounter) => {
            if (prevCounter < 1.7) {
              return prevCounter + 0.1;
            }
            return 5.6; // Stop at 100
          });
        }, 100);

        setInterval(() => {
          setCounter5((prevCounter) => {
            if (prevCounter < 1.2) {
              return prevCounter + 0.1;
            }
            return 1.2; // Stop at 100
          });
        }, 0);

        // setInterval(() => {
        //   setCounter32((prevCounter) => {
        //     if (prevCounter < 1.2) {
        //       return prevCounter + 1;
        //     }
        //     return 32; // Stop at 100
        //   });
        // }, 500);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [counter80, counter56, counter5]); // Dependency on counter ensures the effect triggers when counter changes

  return (
    <section className="our-achievement-wrape container">
      <div className="best-fact">
        <div className="container medium">
          <div className="up-wrape">
            <div className="text animate__animated animate__slideInLeft">
              <h2>
                Our Achievements <br />
                <span className="normal-bold">Some counts that matters</span>
              </h2>
              <p>
                We are presenting some valuable counts that transform your
                business
              </p>
            </div>
            <div className="stat animate__animated animate__slideInDown counter80">
              <h6>
                <span className="count">{counter80}</span>
              </h6>
              <div className="countries100">
                <span className="achive-contry-text">+ Countries</span>
                <p>
                  <span className="normal-bold">we</span> <br /> Globaly serve
                </p>
              </div>
            </div>
          </div>
          <div className="bottom-stat">
            <div className="stat text animate__animated animate__slideInUp">
              <h6 className="count-hide">
                <span className="count ">{counter56}</span>
              </h6>
              <span className="number-text">+ Million</span>
              <p>
                {" "}
                <span className="normal-bold">Global</span> <br /> Buyers
              </p>
            </div>
            <div className="stat text animate__animated animate__slideInUp">
              <h6 className="count-hide">
                <span className="count ">23.5</span>
              </h6>
              <span className="number-text">+ Thousand</span>

              <p>
                {" "}
                <span className="normal-bold">global</span> <br /> Suppliers
              </p>
            </div>
            <div className="stat text animate__animated animate__slideInUp">
              <h6 className="count-hide">
                <span className="count ">8.7</span>
              </h6>
              <span className="number-text">+ Million</span>


              <p className="mb-1">
                {" "}
                <span className="normal-bold">global</span> <br /> Shipments

              </p>
              <span className="number-text">on board</span>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurAchivment;
