import React from "react";
import { Link } from "react-router-dom";

function TermsOfConditionsLogistic() {
  return (
    <div>
      <p>
        <b>Effective Date:</b> 25 Jan 2025
      </p>
      <p>
        <b>Last Updated:</b> 2 Dec 2024
      </p>

      <p>
        Welcome to Orex Trade World INC. These Terms and Conditions outline the
        obligations, rights and responsibilities of logistic partners utilizing
        our platform.
      </p>
      <h2>1. Registration and Verification</h2>
      <p>Logistic partners must:</p>
      <ul>
        <li>
          Logistic partners must accurately and truthfully complete the
          registration form.
        </li>
        <li>
          Provide necessary legal and business documentation for verification.
        </li>
        <li>
          Immediately inform Orex Trade of any updates or changes to registered
          information.
        </li>
      </ul>

      <h2>2. Roles and Responsibilities</h2>
      <p>Logistic partners agree to:</p>
      <ul>
        <li>Offer transparent, reliable and secure transportation services.</li>
        <li>
          Adhere strictly to all local, national and international shipping
          regulations and standards.
        </li>
        <li>
          Clearly communicate pricing, terms and conditions with all involved
          parties.
        </li>
        <li>
          Responsibly and promptly handle all leads provided by Orex Trade.
        </li>
        <li>
          Obtain explicit approval from Orex Trade before subcontracting any
          provided assignments.
        </li>
        <li>
          Provide comprehensive, upfront pricing without hidden charges; all
          costs must be explicitly stated initially.
        </li>
        <li>
          Notify Orex Trade in advance before undertaking any shipment based on
          leads received through the Orex platform.
        </li>
        <li>
          Inform Orex Trade immediately of any service disruption, shipment
          delays or changes in transportation arrangements.
        </li>
        <li>
          Ensure all rates and transportation arrangements are communicated
          clearly and transparently without alteration after confirmation.
        </li>
        <li>
          Provide valid rate quotations clearly indicating transportation
          details without hidden costs and refrain from holding cargo or
          demanding additional payments after shipment commencement.
        </li>
      </ul>

      <h2>3. Pricing and Rates</h2>
      <ul>
        <li>
          Logistic partners must provide a detailed breakdown of pricing to
          ensure absolute transparency.
        </li>
        <li>
          Rate quotations and associated costs must remain consistent and
          unchanged post-confirmation unless expressly approved by Orex Trade.
        </li>
        <li>
          All quoted rates and conditions must be transparent, comprehensive and
          explicitly disclosed upfront.
        </li>
        <li>
          Once an offer rate is submitted, it will be considered final and
          binding. Logistic partners must not alter the offer rate or introduce
          additional charges thereafter.
        </li>
        <li>
          Any changes or additional charges requested by the buyer or supplier
          must be clearly communicated and agreed upon in advance. Logistic
          partners will not be responsible for payment of these additional
          charges; they must confirm explicitly that the buyer or supplier will
          bear any additional costs.
        </li>
      </ul>

      <h2>4. Benefits Provided</h2>
      <p>Logistic partners will receive:</p>
      <ul>
        <li>Direct and exclusive leads from verified buyers and suppliers.</li>
        <li>Global brand promotion on Orex Trade platforms.</li>
        <li>
          Access to Orex Trade's advanced shipment analytics and detailed
          insights.
        </li>
        <li>
          Dedicated business landing pages to effectively showcase services.
        </li>
        <li>
          Limited exclusive rights to operate at specific port locations,
          subject to agreement and availability.
        </li>
      </ul>

      <h2>5. Secure Trade Guarantee</h2>
      <ul>
        <li>
          Secure trade guarantees apply exclusively when logistics services are
          performed by Orex-verified partners.
        </li>
        <li>
          Logistic partners must transparently communicate their logistics
          operations and terms clearly to maintain eligibility for the secure
          trade guarantee.
        </li>
      </ul>

      <h2>6. Ethical Conduct</h2>
      <p>Logistic partners must:</p>
      <ul>
        <li>Uphold high ethical standards in all business interactions.</li>
        <li>
          Prohibit bribery, corruption, unethical trade practices and
          discrimination.
        </li>
        <li>
          Maintain integrity and honesty in service delivery and communications.
        </li>
        <li>
          Respect confidentiality and protect all client-sensitive information.
        </li>
      </ul>

      <h2>7. Safety Plan</h2>
      <ul>
        <li>
          Orex Trade implements a strict safety protocol aimed at ensuring the
          security and integrity of all transactions. Logistic partners must
          adhere to all instructions provided by Orex Trade to ensure the safety
          and security of buyers and suppliers.
        </li>
        <li>
          Cargo must not be released or handled without explicit authorization
          from Orex Trade.
        </li>
      </ul>

      <h2>8. Shipment and Pricing Transparency</h2>
      <ul>
        <li>
          Rate validity and transportation details must be communicated
          explicitly, transparently and comprehensively. No hidden charges or
          conditions should be applied.
        </li>
        <li>
          Logistic partners cannot hold the cargo nor demand additional payment
          from the buyer or supplier after shipment commencement.
        </li>
      </ul>

      <h2>9. Direct Communication Protocol</h2>
      <ul>
        <li>
          If Orex Trade sends an inquiry to the logistic partner, the logistic
          partner must not contact the buyer or supplier directly without
          explicit approval from Orex Trade. All communications must occur
          through or with prior knowledge of Orex Trade.
        </li>
      </ul>

      <h2>10. Termination of Partnership</h2>


      <p>
        Orex Trade reserves the right to terminate logistic partnerships due to:
      </p>

        <ul>
          <li>Non-compliance with these Terms and Conditions.</li>
          <li>Provision of misleading, fraudulent or incorrect information.</li>
          <li>Violation of ethical standards.</li>
          <li>
            Repeated service failures, unresolved complaints or breaches of
            client trust.
          </li>
        </ul>

      <h2>11. Dispute Resolution</h2>
      <ul>
        <li>
          All disputes must first undergo negotiation between involved parties.
        </li>
        <li>
          Unresolved disputes will proceed to binding arbitration under the
          jurisdiction of Ahmedabad, Gujarat, India.
        </li>
      </ul>

      <h2>12. Amendments and Updates</h2>
      <ul>
        <li>
          Orex Trade reserves the right to amend these Terms periodically.
        </li>
        <li>
          Logistic partners will be informed via email or platform notifications
          of significant updates.
        </li>
        <li>
          Continued use following notifications constitutes acceptance of
          revised terms.
        </li>
      </ul>

      <h2>13. Indemnification</h2>
      <ul>
        <li>
          Logistic partners agree to indemnify Orex Trade against any claims,
          liabilities, damages or expenses arising from their operations or
          violations of these Terms.
        </li>
      </ul>

      <h2>14. Force Majeure</h2>
      <ul>
        <li>
          Orex Trade shall not be liable for disruptions or delays due to
          uncontrollable circumstances such as natural disasters, governmental
          actions, pandemics, technological failures or similar events.
        </li>
      </ul>

      <h2>15. Confidentiality</h2>
      <ul>
        <li>
          Logistic partners must keep all sensitive business and proprietary
          information confidential unless explicitly authorized to disclose.
        </li>
        <li>
          Violation of confidentiality obligations may result in immediate
          termination and potential legal action.
        </li>
      </ul>

      <h2>Contact Information</h2>
      <ul>
        <li>
          <b>Email</b>:{" "}
          <a href="mailto:support@orextrade.com">support@orextrade.com</a>
        </li>
        <li>
          <b>WhatsApp</b>: +1 (786) 772-5447
        </li>
        <li>
          <b>Website</b>: <Link to="/">www.orextrade.com </Link>{" "}
        </li>
      </ul>

      <p>Thank you for your cooperation and commitment to excellence.</p>
    </div>
  );
}

export default TermsOfConditionsLogistic;
