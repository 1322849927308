import React from "react";
import FindSupplierIMG from "../imgs/about_ValueImage.png";

function AboutOurvalue() {
  return (
    <section className="find-supplier-wrape">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="section-head dark">
                    <div className="head">
                      <h2>
                        Our <span>Core Values</span>
                      </h2>
                      <p>with Orex Trade</p>
                    </div>

                    <p className="text">
                    Built on trust, innovation, teamwork and quality to support businesses and create strong connections.
                    </p>
                  </div>

                  <div className="container-fluid usp-wrape dark">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="usp one">
                          <i className="sprite integrity"></i>
                          <div className="detail">
                            <h3>Integrity</h3>
                            <p>
                            Trust forms the foundation of every transaction we facilitate. We are committed to transparency and accountability, ensuring that every step of your trade journey is secure and based on solid, reliable information.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="usp two">
                          <i className="sprite innovation"></i>
                          <div className="detail">
                            <h3>Innovation</h3>
                            <p>
                            We continually enhance our platform with cutting-edge features to meet evolving business needs. By staying ahead of industry trends, we ensure our users have the tools necessary for success in a dynamic market.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="usp three">
                          <i className="sprite collaboration"></i>
                          <div className="detail">
                            <h3>Collaboration</h3>
                            <p>
                            Building strong, reliable partnerships is at the heart of what we do. By fostering trust and mutual growth, we create opportunities for businesses to succeed together, ensuring lasting, meaningful relationships.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="usp four">
                          <i className="sprite excellence"></i>
                          <div className="detail">
                            <h3>Excellence</h3>
                            <p>
                            We strive to exceed expectations by delivering unparalleled services and results. Our focus on quality and continuous improvement ensures we provide solutions that add value, build trust and drive success.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="img-wrape ">
                    {/* <div className="buble-lable">
                      <div className="inner">
                        <h5>56K</h5>
                        <p>Clients around the world</p>
                      </div>
                    </div> */}
                    <img src={FindSupplierIMG} alt="" className="border-0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-7">
            <div className="section-head dark">
              <div className="head">
                <h2>
                  <span>Finding</span> Suppliers
                </h2>
                <p>with Orex Trade</p>
              </div>

              <p className="text">
                Finding buyers for your products is essential for a successful trading business. Here's how Orex Trade can be used effectively to identify potential buyers
              </p>
            </div>

            <div className="container-fluid usp-wrape dark">
              <div className="row">
                <div className="col-lg-6">
                  <div className="usp one">
                    <i className="sprite search"></i>
                    <div className="detail">
                      <h3>Keywords Search</h3>
                      <p>Orex Trade allows you to search for buyers by using specific keywords related to your product.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp two">
                    <i className="sprite analytic"></i>
                    <div className="detail">
                      <h3>Analytics and Insights</h3>
                      <p>Orex Trade provides valuable analytics and insights into global trade trends.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp three">
                    <i className="sprite certified"></i>
                    <div className="detail">
                      <h3>Certified Buyer Profiles</h3>
                      <p>Orex Trade offers detailed Certified profiles of buyers, including information on their product preferences.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp four">
                    <i className="sprite identify"></i>
                    <div className="detail">
                      <h3>Identify The Right Market</h3>
                      <p>Orex Trade provides valuable analytics and insights into global trade trends.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-5">
            <div className="img-wrape">
              <img src={FindSupplierIMG} alt="" />
            </div>
          </div>           */}
        </div>
      </div>
    </section>
  );
}

export default AboutOurvalue;
