import React, { useEffect, useLayoutEffect, useRef } from 'react'
import * as am5 from "@amcharts/amcharts5/index";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const PreKYCIndustry = ({ data, title }) => {
    let donutchart = useRef(null)

    useLayoutEffect(() => {

        let chartContainer = donutchart.current;

        let root = am5.Root.new(chartContainer);
        root._logo.dispose(); //remove logo

        root.setThemes([
            am5themes_Animated.new(root)
        ]);


        let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                endAngle: 270,
                innerRadius: am5.percent(40),
                radius: am5.percent(90),
                layout: root.verticalLayout
            })
        );


        let series = chart.series.push(
            am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "category",
                endAngle: 270
            })
        );

        // **** set color ***

        var colorSet = am5.ColorSet.new(root, {
            colors: [
                am5.color(0x0b4471),
                am5.color(0xdf0024),
                am5.color(0x69a6db),
                am5.color(0xee7859),
                am5.color(0x4caf46),
                am5.color(0xfab72d),
                am5.color(0xf0e31a)
            ]
        });

        series.set("colors", colorSet);

        series.labels.template.set("fill", am5.color(0x979797));  // text color
        series.slices.template.setAll({
            cursorOverStyle: "pointer"   // apply cursor pointer
        });

        //series.slices.template.set("tooltipText", "{tooltipText}");

        series.slices.template.set("tooltipText", "{category}: {valuePercentTotal}%");

        const tooltip = am5.Tooltip.new(root, {});
        series.slices.template.set("tooltip", tooltip);
        tooltip.label.setAll({
            oversizedBehavior: "wrap",
            maxWidth: 350,
            textAlign: "justify",
        });

        series.data.setAll(data);

        series.states.create("hidden", {
            endAngle: -90
        });


        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        // Adding gradients
        /* series.slices.template.set("strokeOpacity", 0);
        series.slices.template.set("fillGradient", am5.RadialGradient.new(root, {
            stops: [{
                brighten: -0.8
            }, {
                brighten: -0.8
            }, {
                brighten: -0.5
            }, {
                brighten: 0
            }, {
                brighten: -0.5
            }]
        })); */



        // Create legend
        // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
        let legend = chart.children.push(am5.Legend.new(root, {
            //centerY: am5.percent(50),
            //y: am5.percent(50),
            centerX: am5.percent(50),
            x: am5.percent(50),
            layout: am5.GridLayout.new(root, {
                maxColumns: 2,
                fixedWidthGrid: true
            }),
            clickTarget: "none",
            // height: am5.percent(90), // Set fixed height for scrolling
            // verticalScrollbar: am5.Scrollbar.new(root, { orientation: "vertical" }) // Enable vertical scrollbar
            marginTop: 15,
            marginBottom: 15,
        }));

        // Disable click events on legend items
        legend.itemContainers.template.set("interactive", false);

        // set value labels align to right
        //legend.valueLabels.template.setAll({ textAlign: "right" })

        legend.valueLabels.template.adapters.add("text", (text, target) => {
            const dataItem = target.dataItem;
            if (dataItem) {
                //return dataItem.get("valuePercentTotal").toFixed(0) + "%"; // Convert percentage to whole number
                return "";
            }
            return text;
        });
        // set width and max width of labels
        // legend.labels.template.setAll({
        //     maxWidth: 140,
        //     width: 140,
        //     oversizedBehavior: "wrap"
        // });

        legend.data.setAll(series.dataItems);


        series.appear(1000, 100);


        return () => root.dispose();
    }, [data]);

    return (
        <div>
            <h5 className='m-3 border-bottom pb-2'>{title}</h5>
            <div ref={donutchart} style={{ width: "100%", height: "500px" }}>
            </div>
        </div>

    )
}

export default PreKYCIndustry;