import React from "react";
import { Link } from "react-router-dom";

function TermsOfCondition() {
  return (
    <div>
     
      <p>
        <b>Effective Date:</b> 25 Jan 2025
      </p>
      <p>
        <b>Last Updated:</b> 2 Dec 2024
      </p>

      <h2>1. AGREEMENT TO TERMS</h2>
      <p>
        These Terms of Use constitute a legally binding agreement made between
        you, whether personally or on behalf of an entity (“you”) and Orex Trade
        World ("Company", “we", “us" or “our”) concerning your access to and
        use of the <Link to="/">
        www.orextrade.com </Link>{" "}
        website as well as any other media form, media channel, mobile website
        or mobile application related, linked or otherwise connected thereto
        (collectively the “Site”). By accessing the Site, you acknowledge
        having read, understood and agreed to be bound by these Terms of Use.
      </p>
      <p>
       
          IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
          EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE
          IMMEDIATELY.
       
      </p>
      <p>
        We reserve the right, at our sole discretion, to make changes or
        modifications to these Terms of Use at any time. It is your
        responsibility to periodically review these Terms of Use to stay
        informed of updates.
      </p>

      <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>
        Unless otherwise indicated, the Site and its content, trademarks,
        service marks logos and intellectual property are owned or licensed by
        Orex Trade World. Reproduction, distribution or exploitation for any
        commercial purposes without our express prior written permission is
        prohibited.
      </p>

      <h2>3. USER REPRESENTATIONS</h2>
      <p>By using the Site, you represent and warrant that:</p>
      <ul>
        <li>
          All registration information you provide is true, accurate, current
          and complete.
        </li>
        <li>
          You will maintain the accuracy of the information provided and
          promptly update registration information as necessary.
        </li>
        <li>
          You possess the legal capacity and agree to comply with these Terms of
          Use.
        </li>
        <li>You are not a minor in your jurisdiction of residence.</li>
        <li>
          You will not access the Site through automated or non-human means,
          such as bots, scripts or similar methods.
        </li>
        <li>
          You will not use the Site for any illegal or unauthorized purposes.
        </li>
        <li>
          Your use of the Site will not violate any applicable laws or
          regulations.
        </li>
      </ul>
      <p>
        If you provide false, inaccurate, outdated or incomplete information,
        we reserve the right to suspend or terminate your account and refuse any
        current or future use of the Site (or any portion thereof).
      </p>

      <h2>4. USER REGISTRATION</h2>
      <p>
        You may be required to register with Orex Trade to access certain
        features of the platform. When registering, you agree to:
      </p>
      <ul>
        <li>Provide truthful, accurate, current and complete information.</li>
        <li>
          Maintain and promptly update your registration information to keep it
          accurate and current.
        </li>
        <li>
          Keep your password confidential and assume full responsibility for all
          activities conducted under your account.
        </li>
      </ul>
      <p>
        We reserve the right to remove, reclaim or alter any username selected
        by you if, in our sole discretion, we determine that such username is
        inappropriate, obscene, offensive, misleading or otherwise
        objectionable.
      </p>

      <h2>5. PROHIBITED ACTIVITIES</h2>
      <p>
        You may not access or use the Site for any purpose other than that for
        which we make the Site available. The Site may not be used in connection
        with any commercial endeavors except those specifically endorsed or
        approved by Orex Trade. As a user, you agree not to:
      </p>
      <ul>
        <li>
          Systematically retrieve data or other content from the Site to create
          or compile, directly or indirectly, a collection, compilation,
          database or directory without written permission from us.
        </li>
        <li>
          Trick, defraud or mislead us or other users, especially in attempts
          to obtain sensitive account information like user passwords.
        </li>
        <li>
          Circumvent, disable or interfere with security-related features of
          the Site.
        </li>
        <li>
          Disparage, tarnish or otherwise harm, in our opinion, Orex Trade or
          the Site.
        </li>
        <li>
          Use information obtained from the Site to harass, abuse or harm
          others.
        </li>
        <li>
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
        </li>
        <li>Engage in unauthorized framing or linking to the Site.</li>
        <li>
          Upload or transmit viruses, Trojan horses or other disruptive
          material.
        </li>
        <li>
          Engage in automated use of the system, such as using scripts or data
          mining tools.
        </li>
        <li>Remove copyright or proprietary notices from any content.</li>
        <li>
          Attempt to impersonate another user or use the username of another
          person.
        </li>
        <li>
          Upload or transmit materials acting as passive or active information
          collection mechanisms (“spyware”).
        </li>
        <li>
          Interfere with, disrupt or create an undue burden on the Site or
          networks connected to it.
        </li>
        <li>Harass, annoy, intimidate or threaten our employees or agents.</li>
        <li>
          Bypass measures designed to restrict access to any part of the Site.
        </li>
        <li>Copy or adapt the Site's software code.</li>
        <li>
          Decipher, decompile, disassemble or reverse engineer software making
          up the Site.
        </li>
        <li>
          Use unauthorized scripts or software to access the Site, excluding
          standard search engine and internet browser usage.
        </li>
        <li>Use buying or purchasing agents for transactions on the Site.</li>
        <li>
          Make unauthorized use of the Site, including collecting usernames or
          email addresses for unsolicited communication.
        </li>
        <li>
          Use the Site as part of any effort to compete with Orex Trade or
          exploit the Site or its content for revenue-generating or commercial
          purposes.
        </li>
      </ul>

      <h2>6. USER GENERATED CONTRIBUTIONS</h2>
      <p>
        The Site may invite you to chat, contribute or participate in blogs,
        message boards, online forums and other interactive features. You may
        also have opportunities to create, submit, post, display, transmit,
        perform, publish, distribute or broadcast content and materials to Orex
        Trade or on the Site, including but not limited to text, writings,
        videos, audio, photographs, graphics, comments, suggestions, personal
        information or other materials (collectively, "Contributions").
      </p>
      <p>
        Your Contributions may be viewable by other users of the Site and
        potentially through third-party websites. As such, any Contributions you
        transmit will be considered non-confidential and non-proprietary. When
        creating or sharing Contributions, you represent and warrant that:
      </p>
      <ul>
        <li>
          The creation, distribution, transmission, public display or
          performance and the accessing, downloading or copying of your
          Contributions do not infringe upon the proprietary rights, including
          copyrights, patents, trademarks, trade secrets or moral rights of any
          third party.
        </li>
        <li>
          You are the creator and owner or have the necessary licenses, rights,
          consents, releases and permissions to authorize us, the Site and
          other users of the Site to use your Contributions as intended by these
          Terms.
        </li>
        <li>
          You have obtained written consent, release and/or permission from
          each identifiable individual in your Contributions to use their name
          or likeness.
        </li>
        <li>Your Contributions are truthful, accurate and not misleading.</li>
        <li>
          Your Contributions do not consist of unsolicited or unauthorized
          advertising, promotional materials, pyramid schemes, chain letters,
          spam, mass mailings or other solicitation.
        </li>
        <li>
          Your Contributions are free from obscene, lewd, violent, harassing,
          libelous, slanderous or otherwise objectionable content, as
          determined by us.
        </li>
        <li>
          Your Contributions do not ridicule, mock, disparage, intimidate or
          abuse anyone.
        </li>
        <li>
          Your Contributions do not harass or threaten others or promote
          violence against individuals or groups.
        </li>
        <li>
          Your Contributions comply with all applicable laws, regulations and
          rules.
        </li>
        <li>
          Your Contributions respect the privacy and publicity rights of all
          third parties.
        </li>
        <li>
          Your Contributions comply with laws concerning child protection and do
          not include offensive comments related to race, national origin,
          gender, sexual preference or physical handicap.
        </li>
        <li>
          Your Contributions do not otherwise violate or link to material that
          violates, any provision of these Terms of Use or any applicable law or
          regulation.
        </li>
      </ul>
      <p>
        Any use of the Site in violation of the above conditions constitutes a
        breach of these Terms and may result in termination or suspension of
        your access to the Site.
      </p>

      <h2>7. CONTRIBUTION LICENSE</h2>
      <p>
        By posting Contributions to any area of the Site, you automatically
        grant and represent and warrant that you have the right to grant, to us
        an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
        worldwide, royalty-free, fully paid-up, assignable and sublicensable
        license to use, reproduce, distribute, publicly display, publicly
        perform, modify, create derivative works from, translate, transmit by
        any means, incorporate into other works and otherwise exploit such
        Contributions (including your image and voice) in any form or media, now
        known or later developed for any purpose including commercial,
        promotional, or otherwise.
      </p>
      <p>
        You further grant us the right to use your Contributions in connection
        with our business and operations, including the promotion and
        redistribution of part or all of the Site, without any compensation to
        you. You hereby waive all moral rights in your Contributions and you
        warrant that moral rights have not otherwise been asserted in your
        Contributions.
      </p>
      <p>
        You retain full ownership of your Contributions and any intellectual
        property rights or other proprietary rights associated with your
        Contributions. However, you acknowledge that Orex Trade is not obligated
        to treat any Contributions as confidential and may use your
        Contributions freely in accordance with the above terms.
      </p>
      <p>
        We are not responsible or liable for any statements or representations
        in your Contributions provided by you in any area on the Site. You are
        solely responsible for your Contributions and you expressly agree to
        indemnify and hold Orex Trade harmless from any responsibility or
        liability associated with your Contributions.
      </p>
      <p>We reserve the right, in our sole discretion, without notice, to:</p>
      <ul>
        <li>Edit, redact or otherwise alter Contributions.</li>
        <li>Re-categorize Contributions for better organizational purposes.</li>
        <li>
          Pre-screen or delete any Contributions for any reason at any time.
        </li>
      </ul>
      <p>
        We have no obligation to monitor or moderate Contributions but may do so
        at our discretion.
      </p>

      <h2>8. GUIDELINES FOR REVIEWS</h2>
      <p>
        We may provide areas on the Site for you to leave reviews or ratings.
        When posting a review, you must adhere strictly to the following
        criteria:
      </p>
      <ul>
        <li>
          You must have direct, personal experience with the person or entity
          being reviewed.
        </li>
        <li>
          Reviews must not contain offensive language, profanity, abuse, racist
          comments, hateful speech or discriminatory content.
        </li>
        <li>
          Reviews must be free of discriminatory content based on religion,
          race, gender, national origin, age, marital status, sexual
          orientation or disability.
        </li>
        <li>Reviews must not include references to illegal activities.</li>
        <li>
          You must not post negative reviews if you are affiliated with a
          competitor.
        </li>
        <li>
          Reviews must avoid drawing legal conclusions or judgments regarding
          conduct.
        </li>
        <li>All reviews must be truthful, accurate and not misleading.</li>
        <li>
          Organizing campaigns to encourage others to post reviews, whether
          positive or negative is strictly prohibited.
        </li>
      </ul>
      <p>
        We reserve the absolute right, at our sole discretion, to accept,
        reject or remove reviews for any reason. We are not obligated to screen
        or remove reviews, even if they are deemed objectionable or inaccurate
        by any party. Reviews do not necessarily reflect the views of Orex Trade
        or any of our affiliates or partners. We disclaim all liability for any
        claims, damages or losses arising from any review posted on the Site.
      </p>
      <p>
        By posting a review, you grant Orex Trade an unrestricted, perpetual,
        nonexclusive, worldwide, royalty-free, fully-paid, assignable and
        sublicensable license to reproduce, modify, translate, transmit,
        display, perform, distribute and use all content related to your
        reviews in any media formats and through any media channels currently
        existing or developed in the future.
      </p>

      <h2>9. ADVERTISERS</h2>
      <p>
        We may permit advertisers to display their advertisements and other
        relevant information in designated areas of the Site, including but not
        limited to sidebar or banner advertisements. If you are an advertiser,
        you agree to assume complete responsibility for all advertisements you
        place on the Site, as well as any services or products advertised or
        sold through these advertisements. As an advertiser, you represent and
        warrant that you have all necessary rights, licenses and authority
        required to post advertisements on the Site, including intellectual
        property rights, publicity rights and contractual rights. Our role is
        limited solely to providing the space for your advertisements and we do
        not hold any additional relationship, obligation or liability with
        respect to your advertisements.
      </p>

      <h2>10. PRIVACY AND DATA PROTECTION</h2>
      <p>
        By using the Site, you acknowledge and accept our Privacy Policy, which
        outlines our comprehensive practices concerning data collection,
        handling, storage and sharing. Protecting your privacy and safeguarding
        your sensitive information, including business documentation, financial
        details and personal data, are among our highest priorities. We ensure
        stringent security measures are in place to protect your data from
        unauthorized access, alteration, disclosure or destruction. Your
        sensitive information will never be disclosed or shared with any third
        party unless we have obtained your explicit, informed consent or are
        legally obligated to do so. For full details on how we collect, use and
        protect your information, please carefully review our complete Privacy
        Policy available on the Site.
      </p>

      <h2>11. Limitation of Liability</h2>
      <p>
        Orex Trade World will not be liable for any indirect, consequential or
        incidental damages resulting from your use or inability to use our Site.
      </p>

      <h2>12. Dispute Resolution</h2>
      <p>
        You agree to irrevocably submit all disputes related to these Terms or
        the relationship established by this Agreement exclusively to the
        jurisdiction of the Ahmedabad courts, Gujarat, India. Orex Trade retains
        the right to initiate legal proceedings in Ahmedabad courts for any
        substantive matters.
      </p>

      <h6>Clarifications on Legal & Judicial Matters:</h6>
      <ul>
        <li>
          All Orex Trade offices and branches globally are managed operationally
          by the Asia Pacific operative branch office located in Ahmedabad,
          Gujarat, India. Therefore, jurisdiction for legal matters is
          exclusively with Ahmedabad courts.
        </li>
        <li>
          Any disputes arising from membership agreements, processes, plans or
          benefits between Orex Trade and active members or any authorities
          worldwide are to be adjudicated exclusively in Ahmedabad courts.
        </li>
        <li>
          Disputes among active members or any external authorities must also be
          resolved through Ahmedabad courts.
        </li>
        <li>
          All legal processes, including initiating or completing judicial
          proceedings, must occur exclusively through Ahmedabad courts.
        </li>
        <li>
          Orex Trade is not obligated to respond to or comply with orders or
          verdicts from courts other than those in Ahmedabad.
        </li>
        <li>
          Orex Trade is under no obligation to participate in or acknowledge
          legal proceedings in jurisdictions other than Ahmedabad.
        </li>
      </ul>

      <h2>13. Termination of Membership</h2>
      <p>
        Orex Trade reserves the right to terminate your membership immediately,
        without prior notice, if any of the following circumstances occur:
      </p>
      <ul>
        <li>Any breach or violation of these Terms and Conditions.</li>
        <li>
          Failure to timely pay membership fees or any other associated fees.
        </li>
        <li>
          Engaging in unethical, abusive, discriminatory or harmful behavior
          towards other members, partners, employees or the Orex Trade platform
          itself.
        </li>
        <li>
          Submission of incorrect, misleading or intentionally false
          information.
        </li>
        <li>Provision or submission of fraudulent or forged documents.</li>
        <li>
          Creation or submission of false, misleading or deceptive inquiries
          intended to mislead or defraud.
        </li>
        <li>
          Activities that negatively impact the integrity, security, reputation
          or operational effectiveness of Orex Trade.
        </li>
      </ul>
      <p>
        Such termination will result in immediate suspension and permanent
        revocation of access to your account and all related services,
        privileges or benefits. Orex Trade shall not be obligated to issue
        refunds, reimbursements or any compensation in the event of such
        termination. Additionally, Orex Trade reserves the right to pursue legal
        actions or remedies available under applicable law against individuals
        or entities involved in serious misconduct or fraudulent activities.
      </p>

      <h2>14. Amendments</h2>
      <p>
        Orex Trade reserves the right to periodically amend, update or modify
        these Terms and Conditions at its sole discretion. Significant changes
        will be clearly communicated to all users via email notifications or
        platform alerts. It is your responsibility to review these Terms
        regularly to stay informed of any updates or changes. Continued use of
        the platform after notification of amendments indicates your acceptance
        of the revised Terms and Conditions.
      </p>

      <h2>15. Indemnification</h2>
      <p>
        You agree to indemnify, defend and hold Orex Trade, including its
        subsidiaries, affiliates, directors, employees and agents, harmless
        against any and all claims, losses, damages, liabilities, judgments,
        awards, costs, expenses, legal fees and any related charges arising
        from or connected to your:
      </p>
      <ul>
        <li>Breach or violation of these Terms and Conditions.</li>
        <li>Misuse or unauthorized use of the Orex Trade platform.</li>
        <li>Violation of any laws, rules or regulations.</li>
        <li>Submission of false, misleading or deceptive information.</li>
        <li>Engagement in fraudulent or unlawful activities.</li>
        <li>
          Any disputes or claims made by third parties arising from your actions
          or omissions on the platform.
        </li>
      </ul>
      <p>
        You agree to fully cooperate and assist Orex Trade in the defense of any
        such claims and acknowledge Orex Trade's right to assume control of
        defense strategies and settlement negotiations at our discretion.
      </p>

      <h2>16. Force Majeure</h2>
      <p>
        Orex Trade shall not be liable for disruptions or delays caused by
        circumstances beyond its reasonable control, including but not limited
        to natural disasters, governmental actions, pandemics, wars, riots,
        strikes, civil disturbances or technological failures. In such cases,
        Orex Trade's obligations shall be suspended until normal conditions
        resume.
      </p>

      <h2>17. Confidentiality</h2>
      <p>
        All sensitive business information and proprietary data shared or
        obtained through the Orex Trade platform must remain strictly
        confidential. Such information should not be disclosed to third parties
        without explicit written authorization from the rightful owner or unless
        required by law. Users are required to implement adequate security
        measures to safeguard confidential information against unauthorized
        access or disclosure. Violation of confidentiality obligations may
        result in immediate termination of membership and possible legal action.
      </p>

      <h2>18. Ethical Business Conduct</h2>
      <p>
        Members are required to maintain high ethical standards in all their
        interactions and business dealings. Members agree to strictly avoid and
        refrain from engaging in bribery, corruption, fraud, harassment,
        discrimination or any other unethical practices. Violation of ethical
        standards may result in immediate termination of membership, potential
        legal actions and other appropriate remedies as deemed necessary by
        Orex Trade.
      </p>

      <h2>19. Acknowledgement</h2>
      <p>By joining Orex Trade, I/We acknowledge the following:</p>
      <ul>
        <li>
          We have read, thoroughly understood and agree to all terms and
          conditions stipulated in the Membership Agreement Form and we fully
          commit to adhering to them.
        </li>
        <li>
          We confirm that we have carefully reviewed and clearly understood all
          the terms and conditions available on Orex Trade's official platforms,
          including but not limited to the official website and we agree to be
          fully bound by these terms.
        </li>
        <li>
          We acknowledge that we have read and comprehensively understood all
          Standard Operating Procedures (SOPs) and Terms & Conditions related to
          Orex Trade’s Secure Trade Guarantee and we fully commit to complying
          with them under all circumstances.
        </li>
        <li>
          We acknowledge that we have read and clearly understood all SOPs
          relevant to Orex Trade’s operational processes, including any part
          thereof and we are fully committed to adhering to these processes
          without exception.
        </li>
        <li>
          We hereby declare our commitment to strictly adhere to all processes
          detailed in Orex Trade’s SOPs and agree to comply with any future
          updates or changes to these processes.
        </li>
        <li>
          We consent to the Orex Trade management proceeding with our Partner
          Enrolment Application according to the agreed-upon terms.
        </li>
        <li>
          We authorize the Orex Trade management team to undertake all necessary
          steps, including but not limited to requesting or gathering relevant
          information, data or documentation from pertinent sources, for the
          purpose of processing and completing our Membership Enrolment
          Application.
        </li>
      </ul>

      <h2>Contact Information</h2>
      <p>
        For any inquiries or assistance related to these terms, please contact
        us:
      </p>
      <ul>
        <li>
         <b> Email:</b> {" "}
          <a href="mailto:support@orextrade.com">support@orextrade.com</a>
        </li>
        <li> <b>WhatsApp:</b> +1 (786) 772-5447</li>
        <li>
          <b>Website:</b>{" "}
          <Link to="/">
        www.orextrade.com </Link>{" "}
        </li>
      </ul>
      <p>Thank you for your cooperation and compliance.</p>
    </div>
  );
}

export default TermsOfCondition;
