import { useEffect, useState } from "react";
import PromotionMedias from "../../../components/preKYC/PromotionMedias";
import defaultValues from "../../../constant/defaultValues";
import PreKYCIndustry from "../../Charts/PreKYCIndustry";
import MapWithPinBullets from "./MapWithPinBullets";
import { useAuth } from "../../../AuthProvider";


const PrekycDashboard = (props) => {

    const [industries, setIndustries] = useState([]);
    const { user } = useAuth();
    useEffect(() => {
        let iData = defaultValues.DUMMY_INDUSTRIES;

        const options = iData.map((r) => ({
            tooltipText: r.industry_name && r.industry_name.length > 200 ? r.industry_name.slice(0, 200) + '...' : r.industry_name || 'No Data',
            category: r.industry_name,
            value: r.per_count,
        }));
        setIndustries(options);

    }, []);

    return (<>
        <div className="dashboart-chart">
            <div className='dashbord-welcom-text mb-3'>
                <p >Welcome back, <span className=''>
                    {user && user.name}
                </span>👋🏻</p>
            </div>


            <div className="row mb-3">
                <div className="col-md-12">
                    <div className="card">
                        <PromotionMedias title="Newly Joined Partners" />
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-lg-6 col-md-12 col-sm-12" >
                    <div className="card" >
                        <PreKYCIndustry title="Trending Industries" data={industries} />
                    </div>

                </div>
                <div className="col-lg-6 col-md-12 col-sm-12" >
                    <div className="card" >
                        <MapWithPinBullets title="World Wide Buyers/Suppliers across (80+) Countries" />
                    </div>

                </div>
            </div>
        </div>
    </>)
}

export default PrekycDashboard;