import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";

import isEmpty from "lodash/isEmpty";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { forgotPasswordAction } from "../actions/loginAction";
import InputField from "../components/InputField";
import "../Orex.css";

import loginIcoin from "../images/login_icon.png";
import errorMessages from "../constant/errorMessages";
import regExp from "../constant/regExpressions";
import Header from "../V2/Header";

function ForgotPasswordForm(props) {
  const nav = useNavigate("");
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState();
  const successMessage = (res) =>
    toast.success(res, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  var [isButtonDisabled, setisButtonDisabled] = useState(false);

  const emailPattern = regExp.email;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setisButtonDisabled(true);
    if (!validation()) {
      setisButtonDisabled(true);
      await props
        .forgotPassword(email)
        .then((response) => {
          setisButtonDisabled(false);
          if (response.message == "We have emailed your password reset link!") {
            // nav(ROUTES.HOME);
            successMessage("We have emailed your password reset link!");
          } else {
            const errors = {};
            if (!response.success) {
              // console.log('HERE222222222', response);
              if (response.message === "Validation Error.") {
                if (!isEmpty(response.data.email)) {
                  errors.email = response.data.email[0];
                }
              }
              if (response.message === "The email is not registered.") {
                errors.email = response.data.error;
              }
              if (!response) {
                errors.somethingWentWrong = "Something went wrong";
              }
            }
            setErrors(errors);
          }
        })
        .catch((error) => {
          setisButtonDisabled(false);
          console.log(error);
        });
    }
  };

  const handleKeyDown = (e) => {
    // console.log(e.code);
    if (e.code === "Space") {
      e.preventDefault();
    }
  };

  const validation = () => {
    const errors = {};
    if (email == "" || email == null) {
      errors.email = errorMessages.FIELD_REQUIRED;
    } else if (emailPattern.test(email) == false) {
      errors.email = errorMessages.INVALID_EMAIL;
    }

    setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  const styleError = {
    display: "block",
  };

  const handleFocusOut = async (e) => {
    const verrors = { ...errors };
    if (e.target.name === "email") {
      if (e.target.value == "" || e.target.value == null) {
        verrors.email = errorMessages.FIELD_REQUIRED;
      } else if (emailPattern.test(e.target.value) == false) {
        verrors.email = errorMessages.INVALID_EMAIL;
      } else {
        verrors.email = "";
      }
    }
    setErrors(verrors);
    return Object.keys(verrors).length > 0;
  };

  return (
    <section className="register-wrape page">
      {/* <Header /> */}
      <div className="login main-regi">
        {/* <div className="logindesboard_img">
          <img src={forgot} alt="" />
        </div> */}

        <div className="login-card regi-form m-0">
          <div className="section-head">
            <div className="head">
              <p>
                Welcome to{" "}
                <img className="login-icon" src={loginIcoin} alt="" />
              </p>
              <h2>
                <span>Orex</span> Trade
              </h2>
            </div>

            <p className="text">
              Enter your email and we'll send you instructions to reset your
              password.
            </p>
          </div>

          <div className="container-fluid">
            <form className="custom-form">
              <div className="row">
                <div className="col-12">
                  {/* {errors.somethingWentWrong && (
                    <div className="invalid-feedback" style={styleError}>
                      {errors.somethingWentWrong}
                    </div>
                  )} */}
                  <div className="input-wrape">
                    <label className="required">Email</label>

                    {errors.somethingWentWrong && (
                      <div className="invalid-feedback" style={styleError}>
                        {errors.somethingWentWrong}
                      </div>
                    )}

                    <div className="input-pad">
                      <InputField
                        type="email"
                        placeholder="Enter email"
                        className="w-100 mt-1"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={handleKeyDown}
                        onBlur={handleFocusOut}
                        autoComplete="off"
                      />
                      {errors.email && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <br />
                <hr />
              </div>
              <br />
              <div className="row">
                <div className="col-sm-6">
                  <button
                    type="button"
                    className="btn border-btn"
                    onClick={() => {
                      nav("/login");
                    }}
                  >
                    <i className="fa-solid fa-angle-left mx-1"></i>
                    Back to login
                  </button>
                </div>
                <div className="col-sm-6">
                  <div className="btn-wrape">
                    <button
                      type="submit"
                      className="btn primary-btn fr"
                      onClick={handleSubmit}
                      disabled={isButtonDisabled}
                    >
                      Send Reset Link
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
      <footer className="white-bg full">
        <div className="footer-bottom">
          <span className="copy-right">
            Copyright © 2025 All Rights Reserved by OREX TRADE WORLD INC
          </span>

          <span className="signature">
            Design & Developed By{" "}
            <Link
              className="footer-design"
              to="https://infinglobal.com/"
              target="_blank"
            >
              {" "}
              InfinGlobal
            </Link>
          </span>
        </div>
      </footer>
    </section>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.login,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (email) => dispatch(forgotPasswordAction(email)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
