import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { includes, isEmpty, map, size } from "lodash";

import Emitter from "../../utils/Emitter";

import { getCountryList, getHSCodeList, getHScodeSuggetions, getServicesList } from "../../actions/defaultDataAction";
import { getBuyerSupplierList } from "../../actions/memberAction";

import { default as ReactSelect, components } from "react-select";
import { WithContext as ReactTags } from "react-tag-input";

import DropDown from "../DropDown";
import Button from "../Button";
import ButtonAdminpanel from "../ButtonAdminpanel";

const MemberFilter = (props) => {

    const filterTypeOptions = [{
        label: 'HS Code',
        value: 'hs_code'
    }, {
        label: 'Buyer/Supplier',
        value: 'buyer_supplier'
    }];

    const CustomOption = ({ innerRef, innerProps, isSelected, label }) => (

        <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center' }} className={isSelected ? "div-item-selected" : "div-item-unselected"} >
            {/* <input type="checkbox" checked={isSelected} readOnly /> */}
            {isSelected && <div className={` item-selected `}><label style={{ marginLeft: '8px' }}>{label}</label></div>}
            {!isSelected && <div className={` item-selected2 `}><label style={{ marginLeft: '8px' }}>{label}</label></div>}

        </div>

    );

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            '&:hover': {
            },
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#4c9bdb', // Background of the selected multi-value items
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#fff', // Color of the text inside the multi-value
            fontSize: "20px"
        }),

        multiValueRemove: (provided) => ({
            ...provided,
            color: '#df0024', // Remove button color
            ':hover': {
                backgroundColor: '#df0024', // Background color on hover for the remove button
            },
        }),
    };

    const [filterTypeOptionVal, setFilterOptionVal] = useState(filterTypeOptions[0]);
    const [countryList, setCountryList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [hscodeList, setHScodeList] = useState([]);
    const [tags, setTags] = useState([]);
    const [suggestions, setSuggetions] = useState([]);

    const [selected_members, setSelectedMembers] = useState([]);
    const [selected_hscodes, setSelectedHSCodes] = useState([]);
    const [selected_services, setSelectedServices] = useState([]);
    const [selected_target_country, setSelectedTargetCountry] = useState([]);
    const [selected_origin_country, setSelectedOriginCountry] = useState([]);
    const [isFilteredData, setFilterData] = useState(false);
    const [searchedTxt, setSearchedTxt] = useState(null);
    const initialFilterRef = useRef(true);

    const handleFilterTypeChange = (val) => {
        if (val.value === "hs_code") {
            setSelectedMembers([]);
        } else {
            setSelectedHSCodes([]);
        }
        setFilterOptionVal(val);
    }

    const handleTagChange = (tagVal) => {
        // console.log("handleTagChange ======== ", tagVal);
        if (tagVal.value === undefined) {
            tagVal.label = tagVal.text;
        } else {
            tagVal.label = tagVal.value;
        }

        setTags((prevTags) => {
            return [...prevTags, tagVal]
        });
        setSearchedTxt(null);
    }

    const handleRemoveTag = (e, indx) => {
        // console.log("Index ============= ", indx);
        // setTags(tags.filter((_, i) => i !== indx));
        handleDelete(indx);
    }

    const handleDelete = (indx) => {
        setTags(tags.filter((_, i) => i !== indx));
    }

    const handleRemoveAllTags = () => {
        // console.log("handleRemoveAllTags is called .........");
        setTags([]);
        setSuggetions([]);
    }

    const handleInputChange = async (tag) => {
        // console.log("handleInputChange ======= ", tag);
        setSearchedTxt(tag);
        if (size(tag) > 3) {
            await getSuggetions(tag);
        }
        // setTag(tag);
    }

    const handleMemberChange = (val) => {
        // console.log("handleMemberChange >>> ", val);
        setSelectedMembers(val);
    }

    const getMembersInList = async (val) => {
        let data = await props.getBuyerSupplierList(0, 10, val);
        // console.log("Data ==== ", data);
        let x = data.data;
        let dataList = x.map((v) => {
            return {
                label: v.company_name,
                value: v.id
            }
        });
        setMemberList(dataList);
    }

    const getServiceInList = async (val) => {
        // console.log("val ========= ", val);
        let data = await props.getServicesList(0, 10, val);
        let dataList = data.map((v) => {
            return {
                label: v.service_name,
                value: v.id
            }
        });
        setServiceList(dataList);
    }

    const getHsCodeInList = async (val) => {
        // console.log("val ========= ", val);
        let data = await props.getHScodeSuggetions(0, 10, val);
        let dataList = data.map((v) => {
            return {
                label: v.description + " (" + v.code + ")",
                value: v.id
            }
        });
        setHScodeList(dataList);
    }

    const handleMemberInputChange = (val, actionMeta) => {
        // console.log("handleMemberInputChange >>> ", val);
        const { prevInputValue } = actionMeta;
        if (val.length >= 3) {
            getMembersInList(val);
        } else if (val.length === 0 && (prevInputValue !== val)) {
            getMembersInList("");
        }
    }

    const handleServiceInputChange = (val, actionMeta) => {
        // console.log("handleServiceInputChange >>> ", val);
        const { prevInputValue } = actionMeta;
        if (val.length >= 3) {
            getServiceInList(val);
        } else if (val.length === 0 && (prevInputValue !== val)) {
            getServiceInList("");
        }
    }

    const handleHsCodeInputChange = (val, actionMeta) => {
        // console.log("handleHsCodeInputChange >>> ", val);
        const { prevInputValue } = actionMeta;
        if (val.length >= 3) {
            getHsCodeInList(val);
        } else if (val.length === 0 && (prevInputValue !== val)) {
            getHsCodeInList("");
        }
    }

    const handleHsCodeChange = (val) => {
        setSelectedHSCodes(val);
    }

    const handleServiceChange = (val) => {
        // console.log("handleServiceChange >>> ", val);
        setSelectedServices(val);
    }

    const handleTargetCountryChange = (val) => {
        setSelectedTargetCountry(val);
    }

    const handleOriginCountryChange = (val) => {
        setSelectedOriginCountry(val);
    }

    const filterMembers = () => {
        // on search check any filter available
        // check previous and new state are same 
        // console.log("SearchedTxt", searchedTxt);
        const filterObj = {};
        filterObj.filterBy = filterTypeOptionVal.value;
        let filterVal = map(tags, 'id');
        if (!isEmpty(searchedTxt)) {
            filterVal.push(searchedTxt);
        }

        filterObj.filter_val = filterVal;
        filterObj.selected_companies = map(selected_members, 'value');
        filterObj.selected_hscodes = map(selected_hscodes, 'value');
        filterObj.selected_services = map(selected_services, 'value');
        filterObj.selected_target_country = map(selected_target_country, 'value');
        filterObj.selected_origin_country = map(selected_origin_country, 'value');
        // console.log("filterMembers is called..", filterObj);
        setFilterData(true);
        Emitter.emit("SET_FILTER_MEMBERS", filterObj);
        // Emitter.emit("FILTER_MEMBERS", filterObj);

        setTimeout(() => {
            Emitter.emit("FILTER_MEMBERS", filterObj);
        }, 1000);
    }

    const resetFilter = () => {
        // console.log("resetFilter is called..");

        const clearAllButton = document.getElementsByClassName("ReactTags__clearAll");
        if (clearAllButton.length > 0) {
            clearAllButton[0].click();
        } else {
            document.getElementsByClassName("search_input")[0].value = "";
        }


        const filterObj = {};
        filterObj.filterBy = filterTypeOptions[0].value;
        setFilterOptionVal(filterTypeOptions[0]);
        setSelectedMembers([]);
        setSelectedServices([]);
        setSelectedHSCodes([]);
        setSelectedTargetCountry([]);
        setSelectedOriginCountry([]);
        setSearchedTxt(null);

        filterObj.filter_val = "";
        filterObj.selected_companies = [];
        filterObj.selected_hscodes = [];
        filterObj.selected_services = [];
        filterObj.selected_target_country = [];
        filterObj.selected_origin_country = [];
        filterObj.table_search = "";
        filterObj.sortDirection = "DESC";
        filterObj.columnName = "id";
        Emitter.emit("SET_FILTER_MEMBERS", filterObj);

        setTimeout(() => {
            Emitter.emit("FILTER_MEMBERS", filterObj);
        }, 1000);
        //need to check if filter is already reset then do not call again.

        // setFilterData(false);
        // if (isFilteredData) {
        //     Emitter.emit("FILTER_MEMBERS", filterObj);
        // } else {
        //     console.log("Reset is getting called again...");
        // }

    }

    const fetchFilterSelection = async () => {

        const result = await Promise.allSettled([
            props.getCountryList(),
            props.getServicesList(0, 10, ''),
            props.getBuyerSupplierList(0, 10, ''),
            // props.getHSCodeList()
            props.getHScodeSuggetions(0, 10, '')
        ]);

        result.forEach((res, indx) => {
            let dataList = [];
            let data = [];
            if (res.status === 'fulfilled') {
                if (includes([0, 1, 3], indx)) {
                    data = res.value;
                } else if (includes([2], indx)) {
                    data = res.value.data;
                }

                if (indx === 0) {
                    dataList = data.map((v) => {
                        return {
                            label: v.name,
                            value: v.id
                        }
                    });
                    setCountryList(dataList);
                }
                if (indx === 1) {

                    dataList = data.map((v) => {
                        return {
                            label: v.service_name,
                            value: v.id
                        }
                    });
                    setServiceList(dataList);
                }
                if (indx === 2) {
                    dataList = data.map((v) => {
                        return {
                            label: v.company_name,
                            value: v.id
                        }
                    });
                    setMemberList(dataList);
                }
                if (indx === 3) {
                    // console.log("Dataaaaaaaaaaaaaaaaaaaaaa", data);
                    dataList = data.map((v) => {
                        return {
                            label: v.description + '(' + v.code + ')',
                            value: v.id
                        }
                    });
                    setHScodeList(dataList);
                }
            } else {
                console.log("Something Went Wrong while fetching index ", indx, "!! ", res.reason);
            }
        });

    }

    const getSuggetions = async (searchVal) => {
        // console.log("searchVal >>>> ", searchVal);
        setSuggetions([]);
        if (filterTypeOptionVal.value === "hs_code") {
            // console.log("get HS code suggetions ... ");
            await props.getHScodeSuggetions(0, 20, searchVal).then((data) => {
                let d = data;
                // console.log("Data ================ ", d);
                let suggetion_data = map(d, (v) => {
                    // console.log("ddddddddddddddd", v);
                    return {
                        id: v.code,
                        text: `${v.description} (${v.code})`,
                        // text: v.code,
                        value: v.code
                    };
                });
                // console.log("suggetion_data >>>>> ", suggetion_data);
                setSuggetions(suggetion_data);

            })
        } else {
            // console.log("get member suggetions ... ");
            await props.getBuyerSupplierList(0, 10, searchVal).then((data) => {
                if (data.status === true) {
                    let d = data.data;
                    // console.log("Data ================ ", d);
                    let suggetion_data = map(d, (v) => {
                        // console.log("ddddddddddddddd", v);
                        return {
                            id: v.company_name,
                            text: v.company_name,
                            value: v.company_name
                        };
                    });
                    // console.log("suggetion_data >>>>> ", suggetion_data);
                    setSuggetions(suggetion_data);
                }
            }).catch((error) => {
                console.log("Error === ", error);
            })
        }
    }

    const MoreSelectedBadge = ({ items }) => {
        const style = {
            marginLeft: "auto",
            background: "#d4eefa",
            borderRadius: "4px",
            fontFamily: "Open Sans",
            fontSize: "11px",
            padding: "3px",
            order: 99,
        };

        const title = items.join(", ");
        const length = items.length;
        const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

        return (
            <div style={style} title={title}>
                {label}
            </div>
        );
    };

    const MultiValue = ({ index, getValue, ...props }) => {
        const maxToShow = 3;
        const overflow = getValue()
            .slice(maxToShow)
            .map((x) => x.label);

        return index < maxToShow ? (
            <components.MultiValue {...props} />
        ) : index === maxToShow ? (
            <MoreSelectedBadge items={overflow} />
        ) : null;
    };

    const RemoveComponent = (props) => {
        return (
            <button onClick={props.onRemove} className={props.className}>
                {/* <img src="my-icon.png" /> */}
                <i className="fa-solid fa-x mx-1" ></i>
            </button>
        )
    }


    useEffect(() => {
        if (initialFilterRef.current === true) {
            fetchFilterSelection();
            initialFilterRef.current = false;
        }

    }, [initialFilterRef]);

    return (

        <div className="bg-white shadow rounded p-4 mb-4">

            <div className="row ">

                <div className="col-md-6 col-xxl-3 col-lg-6 my-1">
                    <ReactSelect
                        options={serviceList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CustomOption, IndicatorSeparator: () => null, MultiValue }}
                        onChange={handleServiceChange}
                        value={selected_services}
                        onInputChange={handleServiceInputChange}
                        removeSelected={false}
                        className="member-filter-container"
                        classNamePrefix="member-filter-select"
                        // isDisabled={isSuperAdmin ? false : (props.isDisable || props.disableProfile)}
                        styles={customStyles}
                        placeholder="Select services"
                        noOptionsMessage={() => "No Service Found"}
                    // menuIsOpen={false}
                    // isOptionDisabled={() => hs_code.length >= 3}
                    />
                </div>

                <div className="col-md-6 col-xxl-3 col-lg-6 my-1 ">
                    <ReactSelect
                        options={countryList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CustomOption, IndicatorSeparator: () => null, MultiValue }}
                        onChange={handleTargetCountryChange}
                        value={selected_target_country}
                        // onInputChange={handleHsCodeInputChange}
                        removeSelected={false}
                        // isDisabled={isSuperAdmin ? false : (props.isDisable || props.disableProfile)}
                        styles={customStyles}
                        placeholder="Select target country"
                        className="member-filter-container"
                        classNamePrefix="member-filter-select"
                        noOptionsMessage={() => "No Country Found"}
                    // isOptionDisabled={() => hs_code.length >= 3}
                    />
                </div>

                <div className="col-md-6 col-xxl-3 col-lg-6 my-1">
                    <ReactSelect
                        options={countryList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CustomOption, IndicatorSeparator: () => null, MultiValue }}
                        onChange={handleOriginCountryChange}
                        value={selected_origin_country}
                        // onInputChange={handleHsCodeInputChange}
                        removeSelected={false}
                        // isDisabled={isSuperAdmin ? false : (props.isDisable || props.disableProfile)}
                        styles={customStyles}
                        placeholder="Select origin country"
                        className="member-filter-container"
                        classNamePrefix="member-filter-select"
                        noOptionsMessage={() => "No Country Found"}
                    // isOptionDisabled={() => hs_code.length >= 3}
                    />
                </div>

                {filterTypeOptionVal && filterTypeOptionVal.value === "buyer_supplier" && <div className="col-md-6 col-xxl-3 col-lg-6 my-1">
                    <ReactSelect
                        options={hscodeList}
                        isMulti
                        className="member-filter-container"
                        classNamePrefix="member-filter-select"
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CustomOption, IndicatorSeparator: () => null, MultiValue }}
                        onChange={handleHsCodeChange}
                        value={selected_hscodes}
                        onInputChange={handleHsCodeInputChange}

                        removeSelected={false}
                        // isDisabled={isSuperAdmin ? false : (props.isDisable || props.disableProfile)}
                        styles={customStyles}
                        placeholder="Select HS Code"
                        noOptionsMessage={() => "No Hs Code Found"}

                    // isOptionDisabled={() => hs_code.length >= 3}
                    />
                </div>}

                {filterTypeOptionVal && filterTypeOptionVal.value === "hs_code" && <div className="col-md-6 col-xxl-3 col-lg-6 my-1">
                    <ReactSelect
                        options={memberList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option: CustomOption, IndicatorSeparator: () => null, MultiValue }}
                        onChange={handleMemberChange}
                        value={selected_members}
                        onInputChange={handleMemberInputChange}
                        removeSelected={false}
                        // isDisabled={isSuperAdmin ? false : (props.isDisable || props.disableProfile)}
                        styles={customStyles}
                        placeholder="Select company"
                        className="member-filter-container"
                        classNamePrefix="member-filter-select"
                        noOptionsMessage={() => "No Company Found"}
                    // isOptionDisabled={() => hs_code.length >= 3}
                    />
                </div>}

            </div>

            <div className="row pt-3">

                <div className="col-xl-2 col-md-6 mt-2" >
                    <DropDown placeholder="Select.." options={filterTypeOptions} value={filterTypeOptionVal} onChange={handleFilterTypeChange} />
                </div>

                <div className="col-xl-6 col-md-6 mt-2" >
                    <ReactTags
                        tags={tags}
                        name="search_txt"
                        inputFieldPosition="top"
                        suggestions={suggestions}
                        placeholder="Search Keywords"
                        handleDelete={handleDelete}
                        handleAddition={handleTagChange}
                        // handleDrag={handleDrag}
                        // handleTagClick={handleTagClick}
                        // onTagUpdate={onTagUpdate}
                        // removeComponent={RemoveComponent}
                        handleInputChange={handleInputChange}
                        editable={false}
                        clearAll
                        onClearAll={handleRemoveAllTags}
                        // maxTags={5}
                        allowAdditionFromPaste
                        classNames={{ tagInputField: "form-control form-size w-100 search_input" }}
                        inputProps={{
                            autoComplete: "off"
                        }}
                    />
                </div>

                <div className="filter-search-btn col-xl-4 col-md-6 d-flex align-items-center mt-2">
                    <ButtonAdminpanel type="button" onClick={filterMembers} className="btn-regi">  <i className="fa-solid fa-magnifying-glass text-white"></i> Search </ButtonAdminpanel>

                    <button type="button" className=" btn filter-reset-btn mx-2" onClick={resetFilter}>
                        <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="none" fillRule="evenodd" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" transform="matrix(0 1 1 0 2.5 2.5)"> <path d="m3.98652376 1.07807068c-2.38377179 1.38514556-3.98652376 3.96636605-3.98652376 6.92192932 0 4.418278 3.581722 8 8 8s8-3.581722 8-8-3.581722-8-8-8"></path> <path d="m4 1v4h-4" transform="matrix(1 0 0 -1 0 6)"></path> </g> </g></svg>
                        Reset</button>
                    {/* <p className="filter-reset-text" onClick={resetFilter}>
                    <i class="fa-solid fa-filter-circle-xmark mx-1"></i>
                        Reset</p> */}
                </div>

            </div>

            <div className="row pt-2 ">
                <div className="col-md-6 col-xl-2" ></div>

                <div className="col-md-9 col-xl-5 ">
                    <div className="selected-custom-tags">
                        {tags.map((val, indx) => {
                            // console.log("value ======= ", val);
                            return (
                                <div key={`tag_${indx}`} className="selected-custom-tags-child">
                                    <div className="selected-custom-tag-text" data-toggle="tooltip" data-placement="top" title={`${val.text}`}>{val.label} </div>
                                    <div className="selected-custom-tag-button">
                                        <i className="fa-solid fa-x mx-1" key={`remove_${indx}`} onClick={(e) => handleRemoveTag(e, indx)}></i>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>


    )
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCountryList: () => dispatch(getCountryList()),
        getServicesList: (offset, limit, searchTxt) => dispatch(getServicesList(offset, limit, searchTxt)),
        getBuyerSupplierList: (offset, limit, searchtxt) => dispatch(getBuyerSupplierList(offset, limit, searchtxt)),
        getHSCodeList: () => dispatch(getHSCodeList()),
        getHScodeSuggetions: (offset, limit, searchTxt) => dispatch(getHScodeSuggetions(offset, limit, searchTxt))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MemberFilter));
