import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { hideLoading, showLoading } from "../../actions/loaderAction";
import { useEffect, useRef, useState } from "react";
import { getTradeDetail } from "../../actions/shipmentAction";
import { capitalize, isEmpty } from "lodash";
import MapWithPins from "../Charts/mapwithPins";
import DonutChart from "../Charts/DonutChart";
import TradingCompanies from "../../components/shipment/TradingCompanies";
import { MENU_ROUTES } from "../../config/menuRoutes";
import SubHeader from "../../components/SubHeader";
import { decode } from "js-base64";

const ShipmentDetail = (props) => {

    const { type, company_name } = useParams();
    const company_name1 = useRef("");
    const type1 = useRef("");

    const [hscodeData, setHsCodeData] = useState([]);
    const [tradingPartners, setTradingPartners] = useState([]);

    const [tradingCountries, setTradingCountries] = useState([]);


    const getData = async (typ, c_name) => {
        await props.showLoading();
        await props.getTradeDetail(typ, c_name).then((response) => {
            if (response.status) {
                if (response.trading_hs_code && !isEmpty(response.trading_hs_code)) {
                    const options = response.trading_hs_code.map((r) => ({
                        tooltipText: r.product_desc && r.product_desc.length > 200 ? r.product_desc.slice(0, 200) + '...' : r.product_desc || 'No Data',
                        category: r.hs_code,
                        value: r.count,
                    }));
                    setHsCodeData(options);
                }

                if (response.trading_partners && !isEmpty(response.trading_partners)) {
                    const options = response.trading_partners.map((r) => ({
                        name: r.trading_partner
                    }));
                    setTradingPartners(options);
                }

                if (response.trading_countries && !isEmpty(response.trading_countries)) {
                    const country_options = response.trading_countries.map((r) => ({
                        name: r.country_name,
                        id: r.country_code
                    }));

                    setTradingCountries(country_options);
                }



            }
        }).catch((err) => {
            console.log("Error ====== ", err);
        });
        await props.hideLoading();
    }

    useEffect(() => {
        if (type && company_name) {
            company_name1.value = decode(company_name);
            //type1.value = type;
            if (type == "b") {
                type1.value = "buyer";
            } else {
                type1.value = "supplier";
            }
            getData(type1.value, company_name);

        } else {
            company_name1.value = "";
        }
    }, [type, company_name]);

    return (<>

        <div className='company-main'>
            {company_name1.value && type1.value && <SubHeader layer1="Buyers/Suppliers Management" layer2="Trade Detail" layer3="" pageName={`Trading Detail of ${company_name1.value} - (${capitalize(type1.value)})`} subTitle=""
                clickable={{
                    layer1: MENU_ROUTES.SHIPMENT_COMPANY_LIST,
                    layer2: "",
                    layer3: ""
                }}
            />
            }
            <div className="row">
                <div className="col-md-12">
                    <div className="card ">
                        <MapWithPins title="Trading Countries" data={tradingCountries} />
                    </div>
                </div>


            </div>
            <div className="row">
                <div className="col-md-7">
                    <div className="card mt-5">
                        <DonutChart data={hscodeData} title="Trading Hs Code"> </DonutChart>

                    </div>
                </div>
                <div className="col-md-5">
                    <div className="card mt-5">
                        <TradingCompanies title="Trading Partners" data={tradingPartners} />

                    </div>
                </div>


            </div>

        </div>
    </>)
}

const mapStateToProps = state => {

    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading()),
        getTradeDetail: (type, company_name) => dispatch(getTradeDetail(type, company_name))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDetail);