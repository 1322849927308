import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation} from "react-router-dom";
import Button from "../components/Button";
import DropDown from "../components/DropDown";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-number-input/style.css";
import Select from "react-select";
import Footer from "../Footer";
import axios from "axios";
import errorMessages from "../constant/errorMessages";
import API_URL from "../config/apiConfig";
import ROUTES from "../config/routePath";
import RadioButtonField from "../components/RadioButtonField";
import { includes, map, replace, size } from "lodash";
const invalidEmails = [
  "yopmail.com",
  "mailinator.com",
  "10minutemail.com",
  "guerrillamail.com",
  "trashmail.com",
  "temp-mail.org",
  "dispostable.com",
  "fakeinbox.com",
  "sharklasers.com",
  "getairmail.com",
  "maildrop.cc",
  "spambog.com",
  "mytemp.email",
  "throwawaymail.com",
  "tempmailaddress.com",
  "mailcatch.com",
  "moakt.com",
  "temp-mail.io",
  "tutanota.com",
];

function InquiryUs() {

  const location = useLocation();

  const [phoneValue, setphoneValue] = useState("");
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [recaptchaValue, setRecapchaValue] = useState(null);
  const recaptcha = useRef();
  const [successmsg, setMessage] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryFrom, setSelectedCountryFrom] = useState(null);
  const [selectedCountryTo, setSelectedCountryTo] = useState(null);

  const [selectedFreightType, setSelectedFreightType] = useState(null);

  const [selectedOceanType, setSelectedOceanType] = useState(null);

  const [selectedTerm, setSelectedTerm] = useState(null);
  
  
  // const [formData, setFormData] = useState({
  //   company_name: "",
  //   contact_person: "",
  //   email: "",
  //   phone: "",
  //   industry: "",
  //   country: "",
  //   product: "",
  //   quantity: "",
  //   unit: "",
  //   message: "",
  //   country_from: "",
  //   country_to: "",
  //   freight_type:"",
  //   port_of_destination:"",
  //   country_to:"",
  //   port_of_origin:"",
  //   country_from:"",
  //   ocean_type:"",
  //   lwh:"",
  //   cbpp:"",
  //   total_weight:"",
  //   term:"",
  //   no_of_containers:"",
  // });
  const initialState = {
    company_name: "",
    contact_person: "",
    email: "",
    phone: "",
    industry: "",
    country: "",
    product: "",
    quantity: "",
    unit: "",
    message: "",
    country_from: "",
    country_to: "",
    freight_type: "",
    port_of_destination: "",
    port_of_origin: "",
    ocean_type: "",
    lwh: "",
    cbpp: "",
    total_weight: "",
    term: "",
    no_of_containers: "",
    type:"Supplier",
    commodity:"",
  };

  const [formData, setFormData] = useState(initialState);

  const [isRadioCheckedSupplier, setIsRadioCheckedBuyer] = useState(true);
  const [isRadioCheckedLogisticPartner, setisRadioCheckedLogisticPartner] = useState(false);
  const [types, setTypes] = useState("Supplier");

  const handleButtonClickBuyer = () => {
    setIsRadioCheckedBuyer(true);
    setisRadioCheckedLogisticPartner(false);
    setTypes("Supplier");
    setFormData(initialState);
    setErrors({});
    setphoneValue("");
    setSelectedIndustry(null);
    setSelectedCountry(null);
    setSelectedCountryFrom(null);
    setSelectedCountryTo(null);
    setSelectedFreightType(null);
    setSelectedOceanType(null);
    setSelectedTerm(null);
    setRecapchaValue(null);
    recaptcha.current.reset();
    setMessage("");
    setFormData((prevalue) => {
      return {
        ...prevalue,
        type: "Supplier",
      };
    });

  };


  const handleButtonClickSeller = () => {
    setisRadioCheckedLogisticPartner(true);
    setIsRadioCheckedBuyer(false);
    setTypes("Logistic_Partner");
    setFormData(initialState);
    setErrors({});
    setphoneValue("");
    setSelectedIndustry(null);
    setSelectedCountry(null);
    setSelectedCountryFrom(null);
    setSelectedCountryTo(null);
    setSelectedFreightType(null);
    setSelectedOceanType(null);
    setSelectedTerm(null);
    setRecapchaValue(null);
    recaptcha.current.reset();
    setMessage("");
    setFormData((prevalue) => {
      return {
        ...prevalue,
        type: "Logistic_Partner",
      };
    });
  };


  const handleChange = (e) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleRecaptchaChange = (value) => {
    setRecapchaValue(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      recaptcha: value ? "" : "Please complete the CAPTCHA",
    }));
  };

  const handlePhoneChange = (val) => {
    setphoneValue(val);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        phone: val,
      };
    });
  };

  
  const validation = () => {
    let er = { ...errors };
    let flag = 0;
    let isValid = true;

     if (!formData.company_name || formData.company_name === "") {
        flag = 1;
        isValid = false;
        er.company_name = errorMessages.FIELD_REQUIRED;
      }else if (formData.company_name.length > 40) {
        flag = 1;
        isValid = false;
        er.company_name = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9 .&@]+$/;
        const check = regExp.test(formData.company_name);
        if (check === false) {
          isValid = false;
          flag = 1;
          er.company_name = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.company_name = "";
        }
      }


        if (formData.contact_person === "") {
          isValid = false;
          flag = 1;
          er.contact_person = errorMessages.FIELD_REQUIRED;
        } else if (formData.contact_person.length > 40) {
          isValid = false;
          flag = 1;
          er.contact_person = errorMessages.MAX_LENGTH_EXCEEDED;
        } else {
          const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
  
          const check = regExp.test(formData.contact_person);
          if (check === false) {
            isValid = false;
            flag = 1;
            er.contact_person = errorMessages.IN_VALID_FIELD_FORMAT;
          } else {
            flag = 0;
            er.contact_person = "";
          }
        }
     
        if (formData.email === "") {
          isValid = false;
          flag = 1;
          er.email = errorMessages.FIELD_REQUIRED;
        } else if (formData.email.length > 40) {
          isValid = false;
          flag = 1;
          er.email = errorMessages.MAX_LENGTH_EXCEEDED;
        } else {
          const currentEmail = formData.email.split("@")[1];
          const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          const check = regExp.test(formData.email);
          if (check === false) {
            isValid = false;
            flag = 1;
            er.email = errorMessages.IN_VALID_FIELD_FORMAT;
          } else if (invalidEmails.includes(currentEmail)) {
            isValid = false;
            flag = 1;
            er.email = errorMessages.IN_VALID_FIELD_FORMAT;
          } else {
            flag = 0;
            er.email = "";
          }
        }


      if (phoneValue) {
        let numberWithoutCountryCode = phoneValue.replace(/^\+(\d{1,4})\s?/, '');
        numberWithoutCountryCode = numberWithoutCountryCode.replace(/\s/g, '');
        if (!phoneValue || !isValidPhoneNumber(phoneValue) && numberWithoutCountryCode.length > 0) {
          flag = 1;
          isValid = false;
          er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
        }
      }

        if(types === 'Supplier'){

            if (formData.industry === '') {
              isValid = false;
              flag = 1;
              er.industry = errorMessages.FIELD_REQUIRED;
            } else {
              flag = 0;
              er.industry = "";
            }

            if (formData.country === '') {
              isValid = false;
              flag = 1;
              er.country = errorMessages.FIELD_REQUIRED;
            } else {
              flag = 0;
              er.country = "";
            }


            if (formData.product === "") {
              isValid = false;
              flag = 1;
              er.product = errorMessages.FIELD_REQUIRED;
            } else if (formData.product.length > 40) {
              isValid = false;
              flag = 1;
              er.product = errorMessages.MAX_LENGTH_EXCEEDED;
            } else {
              const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
      
              const check = regExp.test(formData.product);
              if (check === false) {
                isValid = false;
                flag = 1;
                er.product = errorMessages.IN_VALID_FIELD_FORMAT;
              } else {
                flag = 0;
                er.product = "";
              }
            }
        
            
        
              let quantity = formData.quantity;
              let quantityReg = /^(?!0(\.0+)?$)(\d+(\.\d+)?|\.\d+)$/;
              if (quantity !== '' && quantity.length > 0) {
                const check = quantityReg.test(quantity);
                if (check === false) {
                  isValid = false;
                  flag = 1;
                  er.quantity = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                  flag = 0;
                  er.quantity = '';
                }
              }

              let unit = formData.unit;
              let untiEpr = /^[a-zA-Z]+(\s*[-&]?\s*[a-zA-Z]+)*(\s*\(\s*[a-zA-Z]+\s*\))?$/;
              if (unit !== '' && unit.length > 0) {
                const check = untiEpr.test(unit);
                if (check === false) {
                  isValid = false;
                  flag = 1;
                  er.unit = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                  flag = 0;
                  er.unit = '';
                }
        
              }
            
        }

        if(types === 'Logistic_Partner'){
          if (formData.country_from === '') {
            isValid = false;
            flag = 1;
            er.country_from = errorMessages.FIELD_REQUIRED;
          } else {
            flag = 0;
            er.country_from = "";
          }

          if (formData.country_to === '') {
            isValid = false;
            flag = 1;
            er.country_to = errorMessages.FIELD_REQUIRED;
          } else {
            flag = 0;
            er.country_to = "";
          }


            if (formData.port_of_origin === "") {
              isValid = false;
              flag = 1;
              er.port_of_origin = errorMessages.FIELD_REQUIRED;
            } else if (formData.port_of_origin.length > 40) {
              isValid = false;
              flag = 1;
              er.port_of_origin = errorMessages.MAX_LENGTH_EXCEEDED;
            } else {
             
              const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
      
              const check = regExp.test(formData.port_of_origin);
              if (check === false) {
                isValid = false;
                flag = 1;
                er.port_of_origin = errorMessages.IN_VALID_FIELD_FORMAT;
              } else {
                flag = 0;
                er.port_of_origin = "";
              }
            }
      
          
      
            if (formData.port_of_destination === "") {
              isValid = false;
              flag = 1;
              er.port_of_destination = errorMessages.FIELD_REQUIRED;
            } else if (formData.port_of_destination.length > 40) {
              isValid = false;
              flag = 1;
              er.port_of_destination = errorMessages.MAX_LENGTH_EXCEEDED;
            } else {
              const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
      
              const check = regExp.test(formData.port_of_destination);
              if (check === false) {
                isValid = false;
                flag = 1;
                er.port_of_destination = errorMessages.IN_VALID_FIELD_FORMAT;
              } else {
                flag = 0;
                er.port_of_destination = "";
              }
            }
      
        
            if (formData.freight_type === '') {
              isValid = false;
              flag = 1;
              er.freight_type = errorMessages.FIELD_REQUIRED;
            } else {
              flag = 0;
              er.freight_type = "";
            }

            if (formData.freight_type === '' || formData.freight_type === 'Ocean') {
            if (formData.ocean_type === '') {
              isValid = false;
              flag = 1;
              er.ocean_type = errorMessages.FIELD_REQUIRED;
            } else {
              flag = 0;
              er.ocean_type = "";
            }
          
      
      
            let no1 = formData.no_of_containers;
            let noReg1 = /^(?!0(\0+)?$)(\d+(\d+)?|\d+)$/;
            if (no1 === '') {
              isValid = false;
              flag = 1;
              er.no_of_containers = errorMessages.FIELD_REQUIRED;
            } else if (no1 !== '' && no1.length > 0) {
              const check = noReg1.test(no1);
              if (check === false) {
                isValid = false;
                flag = 1;
                er.no_of_containers = errorMessages.IN_VALID_FIELD_FORMAT;
              } else {
                flag = 0;
                er.no_of_containers = '';
              }
            }
          }
          
            if (formData.freight_type === '' || formData.freight_type === 'Air') {

              let no = formData.lwh;
              let noReg =  /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
              if (no === '') {
                isValid = false;
                flag = 1;
                er.lwh = errorMessages.FIELD_REQUIRED;
              } else if (no !== '' && no.length > 0) {
                const check = noReg.test(no);
                if (check === false) {
                  isValid = false;
                  flag = 1;
                  er.lwh = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                  flag = 0;
                  er.lwh = '';
                }
              }
            }
          
            if (!formData.commodity || formData.commodity === "") {
              isValid = false;
              flag = 1;
              er.commodity = errorMessages.FIELD_REQUIRED;
            } else if (formData.commodity.length > 40) {
              isValid = false;
              flag = 1;
              er.commodity = errorMessages.MAX_LENGTH_EXCEEDED;
            } else {
              const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
            
              const check = regExp.test(formData.commodity);
              if (check === false) {
                isValid = false;
                flag = 1;
                er.commodity = errorMessages.IN_VALID_FIELD_FORMAT;
              } else {
                flag = 0;
                er.commodity = "";
              }
            }
            
            let no2 = formData.cbpp;
            let noReg2 =  /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
            if (no2 === '') {
              isValid = false;
              flag = 1;
              er.cbpp = errorMessages.FIELD_REQUIRED;
            } else if (no2 !== '' && no2.length > 0) {
              const check = noReg2.test(no2);
              if (check === false) {
                isValid = false;
                flag = 1;
                er.cbpp = errorMessages.IN_VALID_FIELD_FORMAT;
              } else {
                flag = 0;
                er.cbpp = '';
              }
            }
          
      
            let no3 = formData.total_weight;
            let noReg3 =  /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
             if (no3 !== '' && no3.length > 0) {
              const check = noReg3.test(no3);
              if (check === false) {
                isValid = false;
                flag = 1;
                er.total_weight = errorMessages.IN_VALID_FIELD_FORMAT;
              } else {
                flag = 0;
                er.total_weight = '';
              }
            }
          
      
      
            if (formData.term === '') {
              isValid = false;
              flag = 1;
              er.term = errorMessages.FIELD_REQUIRED;
            } else {
              flag = 0;
              er.term = "";
            }
        
        }
        if (recaptchaValue === null) {
          isValid = false;
          flag = 1;
          er.recaptcha = "Please verify the reCAPTCHA!";
        } else {
          flag = 0;
          er.recaptcha = "";
        }
        setErrors(er);
        // if (flag === 1) {
        //   return false;
        // } else {
        //   return true;
        // }
        if (!isValid) {
          return false; 
        }
      
        return true;

      

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validation()) {
        try {
          setisButtonDisabled(true);
          axios
            .post(API_URL + "/inquiry-us",formData, {
              headers: {
                Accept: "application/json",
              },
              timeout: 10000,
            })
            .then((response) => {
              if (response.data.status === true) {
                setMessage(
                  "The inquiry has been submitted successfully."
                );
               
                const initialState0 = {
                  company_name: "",
                  contact_person: "",
                  email: "",
                  phone: "",
                  industry: "",
                  country: "",
                  product: "",
                  quantity: "",
                  unit: "",
                  message: "",
                  country_from: "",
                  country_to: "",
                  freight_type: "",
                  port_of_destination: "",
                  port_of_origin: "",
                  ocean_type: "",
                  lwh: "",
                  cbpp: "",
                  total_weight: "",
                  term: "",
                  no_of_containers: "",
                  type:types,
                  commodity:"",
                };

                setTimeout(() => {
                  recaptcha.current.reset();
                  setMessage("");
                  setFormData(initialState0);
                  setErrors({});
                  setphoneValue("");
                  setSelectedIndustry(null);
                  setSelectedCountry(null);
                  setSelectedCountryFrom(null);
                  setSelectedCountryTo(null);
                  setSelectedFreightType(null);
                  setSelectedOceanType(null);
                  setSelectedTerm(null);
                  setisButtonDisabled(false);
                }, 3000);
                setRecapchaValue(null);
              } else {
                setisButtonDisabled(false);
                setErrors([]);
                const errors = {};
                if (response.data.errors.company_name) {
                  errors.company_name = response.data.errors.company_name;
                }
                if (response.data.errors.contact_person) {
                  errors.contact_person = response.data.errors.contact_person;
                }
                if (response.data.errors.email) {
                  errors.email = response.data.errors.email;
                }
                if (response.data.errors.country) {
                  errors.country = response.data.errors.country;
                }
                if (response.data.errors.industry) {
                  errors.industry = response.data.errors.industry;
                }
                if (response.data.errors.product) {
                  errors.product = response.data.errors.product;
                }

                if (response.data.errors.quantity) {
                  errors.quantity = response.data.errors.quantity;
                }

                if (response.data.errors.unit) {
                  errors.unit = response.data.errors.unit;
                }

                if (response.data.errors.cbpp) {
                  errors.cbpp = response.data.errors.cbpp;
                }

                if (response.data.errors.country_from) {
                  errors.country_from = response.data.errors.country_from;
                }
                if (response.data.errors.country_to) {
                  errors.country_to = response.data.errors.country_to;
                }

                if (response.data.errors.freight_type) {
                  errors.freight_type = response.data.errors.freight_type;
                }
                if (response.data.errors.port_of_destination) {
                  errors.port_of_destination = response.data.errors.port_of_destination;
                }
                if (response.data.errors.port_of_origin) {
                  errors.port_of_origin = response.data.errors.port_of_origin;
                }
                if (response.data.errors.commodity) {
                  errors.commodity = response.data.errors.commodity;
                }
                if (response.data.errors.term) {
                  errors.term = response.data.errors.term;
                }

                if (response.data.errors.lwh) {
                  errors.lwh = response.data.errors.lwh;
                }

                if (response.data.errors.ocean_type) {
                  errors.ocean_type = response.data.errors.ocean_type;
                }
                if (response.data.errors.no_of_containers) {
                  errors.no_of_containers = response.data.errors.no_of_containers;
                }

            
                
                 setErrors(errors);
                 return Object.keys(errors).length > 0;
              }
            })
            .catch((error) => {
              setisButtonDisabled(false);
              setRecapchaValue(null);
              console.error("Error fetching data:", error);
            });
        } catch (err) {
          console.error(err);
        }


    }



  };

  useEffect(() => {
    if (recaptchaValue !== null) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        recaptcha: "",
      }));
      setisButtonDisabled(false);
    }
  }, [recaptchaValue]);

  const styleError = {
    display: "block",
  };

  
  
    useEffect(() => {
      // Scroll to the section indicated by the hash in the URL
      if (location.hash) {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, [location]);




  const termList = [
    { value: "EXW (Ex-Works or Ex-Warehouse)", label: "EXW (Ex-Works or Ex-Warehouse)" },
    { value: "FCA (Free to Carrier)", label: "FCA (Free to Carrier)" },
    { value: "FAS (Free Alongside Ship)", label: "FAS (Free Alongside Ship)" },
    { value: "FOB (Free On Board)", label: "FOB (Free On Board)" },
    { value: "CFR (Cost and Freight)", label: "CFR (Cost and Freight)" },
    { value: "CIF (Cost, Insurance and Freight)", label: "CIF (Cost, Insurance and Freight)" },
    { value: "CPT (Carriage Paid To)", label: "CPT (Carriage Paid To)" },
    { value: "CIP (Carriage And Insurance Paid To)", label: "CIP (Carriage And Insurance Paid To)" },
    { value: "DAP (Delivered At Place)", label: "DAP (Delivered At Place)" },
    { value: "DPU (Delivered At Place Unloaded (replaces Incoterm® 2010 DAT))", label: "DPU (Delivered At Place Unloaded (replaces Incoterm® 2010 DAT))" },
    { value: "DDP (Delivered Duty Paid)", label: "DDP (Delivered Duty Paid)" },
  ];

  const freightTypeList = [
    { value: "Ocean", label: "Ocean Freight" },
    { value: "Air", label: "Air Freight" },
  ];

  const industryList = [
    { value: "Agriculture", label: "Agriculture" },
    { value: "FMCG", label: "FMCG" },
    { value: "Food Products", label: "Food Products" },
    { value: "Pharmaceuticals", label: "Pharmaceuticals" },
    { value: "Textiles", label: "Textiles" },
    { value: "Automobile & Auto Parts", label: "Automobile & Auto Parts" },
    { value: "Building Materials", label: "Building Materials" },
    { value: "Marble & Stones", label: "Marble & Stones" },
    { value: "Electronics & Gadgets", label: "Electronics & Gadgets" },
    { value: "Cosmetics & Personal Care", label: "Cosmetics & Personal Care" },
  ];

  const oceanTypeList = [
    {value:"LCL" , label:"LCL (Less than Container Load)"},
    {value:"FCL" , label:"FCL (Full Container Load)"},
  ];


  useEffect(() => {
    fetchCountries();
  }, []);


  const fetchCountries = async () => {
    await axios
      .get(API_URL + "/countries")
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === true) {
            const options = response.data.data.map((country) => ({
              value: country.name,
              label: country.name,
              countryCode: country.iso2,
            }));
            setCountryList(options);
          }
        }
      })
      .catch((err) => {
        console.log("Error ========== ", err);
      });
  };


  const handleIndustryChange = (cval) => {
    setSelectedIndustry(cval);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        industry: cval.value,
      };
    });

  };

  const handleCountryChange = (cval) => {
    const countryId = cval.value;
    setSelectedCountry(cval);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        country: countryId,
      };
    });

  };

  const handleCountryFromChange = (cval) => {
    const countryId = cval.value;
    setSelectedCountryFrom(cval);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        country_from: countryId,
      };
    });
  };

  const handleCountryToChange = (cval) => {
    const countryId = cval.value;
    setSelectedCountryTo(cval);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        country_to: countryId,
      };
    });
  };


  const handleFreightTypeChange = (cval) => {

    const freightTypeId = cval.value;
    setSelectedFreightType(cval);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        freight_type: freightTypeId,
      };
    });

    let errObj = { ...errors };
    errObj[`ocean_type`] = "";
    errObj[`no_of_containers`] = "";
    errObj[`lwh`] = "";
    setErrors(errObj);

  };

  const handleOceanTypeChange = (cval) => {
      const oceanTypeId = cval.value;
      setSelectedOceanType(cval);
      setFormData((prevalue) => {
        return {
          ...prevalue,
          ocean_type: oceanTypeId,
        };
      });
    
  };

  const handleTermChange = (cval) => {
    const termId = cval.value;
    setSelectedTerm(cval);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        term: termId,
      };
    });
  }

  const handleFocusOut = async (e) => {
    let flag = 0;
    let er = { ...errors };

    if (e.target.name === 'company_name') {
      if (e.target.value === "") {
        flag = 1;
        er.company_name = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.company_name = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9 .&@]+$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.company_name = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.company_name = "";
        }
      }
    }

    if (e.target.name === 'contact_person') {
      if (e.target.value === "") {
        flag = 1;
        er.contact_person = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.contact_person = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.contact_person = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.contact_person = "";
        }
      }
    }

    if (e.target.name === 'email') {
      if (e.target.value === "") {
        flag = 1;
        er.email = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.email = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const currentEmail = e.target.value.split("@")[1];
        const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else if (invalidEmails.includes(currentEmail)) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.email = "";
        }
      }
    }


    if (e.target.name === "phone") {
      let phoneValue = e.target.value.trim();
      let numberWithoutCountryCode = phoneValue.replace(/^\+(\d{1,4})\s?/, "").replace(/\s/g, "");
      if (numberWithoutCountryCode === '+') {
        flag = 0;
        er.phone = "";
      }
      if (phoneValue !== '' && phoneValue.length > 4 && numberWithoutCountryCode > 0) {
        if (!isValidPhoneNumber(phoneValue)) {
          flag = 1;
          er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
        } else {
          flag = 0;
          er.phone = "";
        }
      }
    }

    if (e.target.name === 'industry') {
      if (e.target.value === '') {
        flag = 1;
        er.industry = errorMessages.FIELD_REQUIRED;
      } else {
        flag = 0;
        er.industry = "";
      }
    }

    if (e.target.name === 'country') {
      if (e.target.value === '') {
        flag = 1;
        er.country = errorMessages.FIELD_REQUIRED;
      } else {
        flag = 0;
        er.country = "";
      }
    }


    if (e.target.name === 'product') {
      if (e.target.value === "") {
        flag = 1;
        er.product = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.product = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.product = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.product = "";
        }
      }

    }

    if (e.target.name === 'quantity') {
      let quantity = e.target.value;
      let quantityReg = /^(?!0(\.0+)?$)(\d+(\.\d+)?|\.\d+)$/
      if (e.target.value !== '' && quantity.length > 0) {
        const check = quantityReg.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.quantity = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.quantity = '';
        }
      }
    }

    if (e.target.name === 'unit') {
      let unit = e.target.value;
      let untiEpr = /^[a-zA-Z]+(\s*[-&]?\s*[a-zA-Z]+)*(\s*\(\s*[a-zA-Z]+\s*\))?$/
      if (e.target.value !== '' && unit.length > 0) {
        const check = untiEpr.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.unit = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.unit = '';
        }

      }
    }


    if (e.target.name === 'country_from') {
      if (e.target.value === '') {
        flag = 1;
        er.country_from = errorMessages.FIELD_REQUIRED;
      } else {
        flag = 0;
        er.country_from = "";
      }
    }

    if (e.target.name === 'country_to') {
      if (e.target.value === '') {
        flag = 1;
        er.country_to = errorMessages.FIELD_REQUIRED;
      } else {
        flag = 0;
        er.country_to = "";
      }
    }

    

    if (e.target.name === 'port_of_origin') {
      if (e.target.value === "") {
        flag = 1;
        er.port_of_origin = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.port_of_origin = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.port_of_origin = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.port_of_origin = "";
        }
      }

    }

    if (e.target.name === 'port_of_destination') {
      if (e.target.value === "") {
        flag = 1;
        er.port_of_destination = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.port_of_destination = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.port_of_destination = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.port_of_destination = "";
        }
      }

    }


    if (e.target.name === 'ocean_type') {
      if (e.target.value === '') {
        flag = 1;
        er.ocean_type = errorMessages.FIELD_REQUIRED;
      } else {
        flag = 0;
        er.ocean_type = "";
      }
    }


    if (e.target.name === 'no_of_containers') {
      let no = e.target.value;
      let noReg = /^(?!0(\0+)?$)(\d+(\d+)?|\d+)$/;
      if (e.target.value === '') {
        flag = 1;
        er.no_of_containers = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value !== '' && no.length > 0) {
        const check = noReg.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.no_of_containers = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.no_of_containers = '';
        }
      }
    }

    if (e.target.name === 'lwh') {
      let no = e.target.value;
      let noReg =  /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
      if (e.target.value === '') {
        flag = 1;
        er.lwh = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value !== '' && no.length > 0) {
        const check = noReg.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.lwh = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.lwh = '';
        }
      }
    }

    if (e.target.name === 'commodity') {
      if (e.target.value === "") {
        flag = 1;
        er.commodity = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.commodity = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.commodity = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.commodity = "";
        }
      }

    }


    if (e.target.name === 'cbpp') {
      let no = e.target.value;
      let noReg =  /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
      if (e.target.value === '') {
        flag = 1;
        er.cbpp = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value !== '' && no.length > 0) {
        const check = noReg.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.cbpp = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.cbpp = '';
        }
      }
    }

    if (e.target.name === 'total_weight') {
      let no = e.target.value;
      let noReg =  /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
       if (e.target.value !== '' && no.length > 0) {
        const check = noReg.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.total_weight = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.total_weight = '';
        }
      }
    }


    if (e.target.name === 'term') {
      if (e.target.value === '') {
        flag = 1;
        er.term = errorMessages.FIELD_REQUIRED;
      } else {
        flag = 0;
        er.term = "";
      }
    }
    
    setErrors(er);
    if (flag === 0) {
      setisButtonDisabled(false);
      return false;
    } else {
     
      return true;
    }

  };






  return (
    <>
      <section className="inner-pad page contact-us-wrape">
        <section className="inner-banner-wrape global-advertising inquiry banner-wrape">
          <div className="inner">Inquiry</div>
          <span class="sprite decoration"></span>
        </section>

        <div className="container map-bg">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="section-head center">
                <div className="head">
                  <p>Orex Trade</p>
                  <h2>
                    <span>Inquiry</span>
                  </h2>
                </div>

                <p className="text">
                  Want to get in touch? We'd love to hear from you. Here's how
                  you can reach us.
                </p>
              </div>
            </div>
          </div>
            <div className="white-wrape">
              <div className="row">

              <div className="col-lg-12 col-sm-12 col-md-12">

                <div className="form-wrape">
                  <div className="container-fluid">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="text-center"><strong>Select Inquiry Type</strong></p>
                        </div>
                        <div className="col-sm-12">

                          <div className="buyer-supplier-btn pt-2">

                            <div className="d-flex justify-content-center align-items-center">


                              <RadioButtonField
                                value="Supplier"
                                name="findWhom"
                                checked={isRadioCheckedSupplier}
                                className={`${isRadioCheckedSupplier ? "d-block" : "d-none"
                                  } mx-2 form-check-input d-none`}
                              />
                              <Link
                                className={`btn primary-btn buyer iquiry-supplier ${isRadioCheckedSupplier ? "" : "deactive-buyer"
                                  } `}
                                  to="#" 
                                  onClick={(e) => { e.preventDefault(); handleButtonClickBuyer(); }}
                                // onClick={handleButtonClickBuyer}
                              >
                                Supplier
                              </Link>
                              <b className="mx-1"> </b>
                            </div>

                            <div className="d-flex justify-content-center align-items-center">
                              <RadioButtonField
                                value="Logistic_Partner"
                                name="findWhom"
                                checked={isRadioCheckedLogisticPartner}
                                className={`${isRadioCheckedLogisticPartner ? "d-block" : "d-none"
                                  } mx-2 form-check-input d-none`}
                              />
                              <Link
                               
                                className={`btn primary-btn ${isRadioCheckedLogisticPartner ? "active-supplier" : ""
                                  }supplier`}
                                // onClick={handleButtonClickSeller}
                                to="#" 
                                onClick={(e) => { e.preventDefault(); handleButtonClickSeller(); }}
                              >
                                Logistic Partner
                              </Link>
                            </div>
                          </div>

                          
                        </div>

                        <div className="col-sm-6 ">
                          <div className="input-group">
                            <label className="form-label required">Company Name</label>
                            <div className="input-pad">
                              <input
                                type="text"
                                name="company_name"
                                value={formData.company_name}
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter company name"
                                onChange={handleChange}
                                onBlur={handleFocusOut}

                              />
                              {errors.company_name && (
                                <span
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.company_name}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 ">
                          <div className="input-group">
                            <label className="form-label required">Contact Person</label>
                            <div className="input-pad">
                              <input
                                type="text"
                                name="contact_person"
                                value={formData.contact_person}
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter contact person"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                              />
                              {errors.contact_person && (
                                <span
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.contact_person}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 ">
                          <div className="input-group">
                            <label className="form-label required">Email</label>
                            <div className="input-pad">
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                value={formData.email}
                                autoComplete="off"
                                placeholder="Enter email"
                                onChange={handleChange}
                                onBlur={handleFocusOut}

                              />
                              {errors.email && (
                                <span
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.email}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 ">
                          <div className="input-wrape">
                            <label className="form-label">
                              Phone Number
                            </label>
                            <div className="input-pad">
                              <PhoneInput
                                id="contact-form-phone"
                                className="form-control inquiry-phone inp-font-size border-dropdown company-representive-border outer_pages_phone_input"
                                name="phone"
                                international
                                limitMaxLength
                                placeholder="Enter phone number"
                                autoComplete="off"
                                value={phoneValue}
                                onChange={handlePhoneChange}
                                onBlur={handleFocusOut}

                              />
                              {errors.phone && (
                                <span
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.phone}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>


                        {isRadioCheckedSupplier && (

                          <div id="suppliers" className="row">
                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Industry</label>
                                <div className="input-pad">
                                  <Select
                                    name="industry"
                                    options={industryList}
                                    value={selectedIndustry}
                                    onChange={handleIndustryChange}
                                    placeholder="Select industry"
                                    className="dd-pad"
                                    noOptionsMessage={() => "No Industry Found"}
                                    onBlur={handleFocusOut}
                                  />
                                  {errors.industry && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.industry}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Country</label>
                                <div className="input-pad">
                                  <Select
                                    name="country"
                                    options={countryList}
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    placeholder="Select country"
                                    className="dd-pad"
                                    noOptionsMessage={() => "No Country Found"}
                                    onBlur={handleFocusOut}
                                  />
                                  {errors.country && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.country}
                                    </span>
                                  )}

                                </div>
                              </div>
                            </div>



                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Product</label>
                                <div className="input-pad">
                                  <input
                                    type="text"
                                    name="product"
                                    value={formData.product}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder="Enter product"
                                    onChange={handleChange}
                                    onBlur={handleFocusOut}

                                  />
                                  {errors.product && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.product}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-3 ">
                              <div className="input-group">
                                <label className="form-label">Quantity</label>
                                <div className="input-pad">
                                  <input
                                    type="text"
                                    name="quantity"
                                    value={formData.quantity}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder="Enter quantity"
                                    onChange={handleChange}
                                    onBlur={handleFocusOut}
                                  />
                                  {errors.quantity && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.quantity}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>



                            <div className="col-sm-3 ">
                              <div className="input-group">
                                <label className="form-label">Unit</label>
                                <div className="input-pad">
                                  <input
                                    type="text"
                                    name="unit"
                                    value={formData.unit}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder="Enter unit"
                                    onChange={handleChange}
                                    onBlur={handleFocusOut}
                                  />
                                  {errors.unit && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.unit}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>
                        )}


                        {isRadioCheckedLogisticPartner && (

                          <div id="logistic_partner" className="row">

                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Country From</label>
                                <div className="input-pad">
                                  <Select
                                    name="country_from"
                                    options={countryList}
                                    value={selectedCountryFrom}
                                    onChange={handleCountryFromChange}
                                    placeholder="Select country"
                                    className="dd-pad"
                                    onBlur={handleFocusOut}
                                    noOptionsMessage={() => "No Country Found"}
                                  />
                                  {errors.country_from && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.country_from}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Port of origin</label>
                                <div className="input-pad">
                                  <input
                                    type="text"
                                    name="port_of_origin"
                                    value={formData.port_of_origin}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder="Enter port of origin"
                                    onChange={handleChange}
                                    onBlur={handleFocusOut}
                                  />
                                  {errors.port_of_origin && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.port_of_origin}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Country To</label>
                                <div className="input-pad">
                                  <Select
                                    name="country_to"
                                    options={countryList}
                                    value={selectedCountryTo}
                                    onChange={handleCountryToChange}
                                    placeholder="Select country"
                                    className="dd-pad"
                                    noOptionsMessage={() => "No Country Found"}
                                    onBlur={handleFocusOut}
                                  />
                                  {errors.country_to && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.country_to}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Port of destination</label>
                                <div className="input-pad">
                                  <input
                                    type="text"
                                    name="port_of_destination"
                                    value={formData.port_of_destination}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder="Enter port of destination"
                                    onChange={handleChange}
                                    onBlur={handleFocusOut}
                                  />
                                  {errors.port_of_destination && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.port_of_destination}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Freight type</label>
                                <div className="input-pad">
                                  <Select
                                    name="freight_type"
                                    options={freightTypeList}
                                    value={selectedFreightType}
                                    onChange={handleFreightTypeChange}
                                    placeholder="Select freight type"
                                    className="dd-pad"
                                    noOptionsMessage={() => "No Freight Type Found"}
                                    onBlur={handleFocusOut}
                                  />
                                  {errors.freight_type && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.freight_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            {selectedFreightType && selectedFreightType.value === 'Ocean' ? (
                              <>
                            <div className="col-sm-3 ">
                              <div className="input-group">
                                <label className="form-label required">Ocean Type</label>
                                <div className="input-pad">
                                  <Select
                                    name="ocean_type"
                                    options={oceanTypeList}
                                    value={selectedOceanType}
                                    onChange={handleOceanTypeChange}
                                    placeholder="Select ocean type"
                                    className="dd-pad"
                                    noOptionsMessage={() => "No Ocean Type Found"}
                                    onBlur={handleFocusOut}
                                  />
                                 {errors.ocean_type && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.ocean_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-3">
                            <div className="input-group">
                              <label className="form-label required">No. of Containers</label>
                              <div className="input-pad">
                                <input
                                  type="text"
                                  name="no_of_containers"
                                  value={formData.no_of_containers}
                                  className="form-control"
                                  autoComplete="off"
                                  placeholder="No. of Containers"
                                  onChange={handleChange}
                                  onBlur={handleFocusOut}
                                />
                                {errors.no_of_containers && (
                                  <span
                                    className="invalid-feedback"
                                    style={styleError}
                                  >
                                    {errors.no_of_containers}
                                  </span>
                                )}
                              </div>
                            </div>
                            </div>
                            </>
                            ) : (

                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Length/Width/Height (CM)</label>
                                <div className="input-pad">
                                  <input
                                    type="text"
                                    name="lwh"
                                    value={formData.lwh}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder="Enter Length/Width/Height (CM)"
                                    onChange={handleChange}
                                    onBlur={handleFocusOut}
                                  />
                                  {errors.lwh && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.lwh}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                        )}

                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Commodity</label>
                                <div className="input-pad">
                                  <input
                                    type="text"
                                    name="commodity"
                                    value={formData.commodity}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder="Enter commodity"
                                    onChange={handleChange}
                                    onBlur={handleFocusOut}

                                  />
                                  {errors.commodity && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.commodity}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Containers Type/Boxes/Pallat/Packages</label>
                                <div className="input-pad">
                                  <input
                                    type="text"
                                    name="cbpp"
                                    value={formData.cbpp}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder="Enter Containers Type/Boxes/Pallat/Packages"
                                    onChange={handleChange}
                                    onBlur={handleFocusOut}
                                  />
                                  {errors.cbpp && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.cbpp}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>


                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label">Total Weight (KG)</label>
                                <div className="input-pad">
                                  <input
                                    type="text"
                                    name="total_weight"
                                    value={formData.total_weight}
                                    className="form-control"
                                    autoComplete="off"
                                    placeholder="Enter Total Weight (KG)"
                                    onChange={handleChange}
                                    onBlur={handleFocusOut}
                                  />
                                  {errors.total_weight && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.total_weight}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6 ">
                              <div className="input-group">
                                <label className="form-label required">Term</label>
                                <div className="input-pad">
                                  
                                <Select
                                    name="term"
                                    options={termList}
                                    value={selectedTerm}
                                    onChange={handleTermChange}
                                    placeholder="Select ocean type"
                                    className="dd-pad"
                                    noOptionsMessage={() => "No Term Found"}
                                    onBlur={handleFocusOut}
                                  />

                                  {errors.term && (
                                    <span
                                      className="invalid-feedback"
                                      style={styleError}
                                    >
                                      {errors.term}
                                    </span>
                                  )}

                                </div>
                              </div>
                            </div>
                          </div>
                        )}


                        <div className="col-sm-12 ">
                          <hr />
                        </div>
                        <div className="col-12">
                          <div className="input-wrape">
                            <label
                              className="form-label inquiry-text"
                              for="contact-form-message"
                            >
                              Remarks
                            </label>
                            <div className="input-pad">
                              <textarea
                                id="contact-form-message"
                                className="form-control"
                                rows="3"
                                placeholder="Remarks...."
                                name="message"
                                value={formData.message}
                                onChange={handleChange}

                              ></textarea>
                              {errors.message && (
                                <span
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>


                        <div className="col-sm-6">
                          <div className="input-wrape captcha-wrape">
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_SITE_KEY}
                              onChange={handleRecaptchaChange}
                              ref={recaptcha}
                              className="Inquiry-capcha"
                              onExpired={() => {
                                setRecapchaValue(null);
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  recaptcha: "Please complete the CAPTCHA",
                                }));
                              }}

                            />
                            {errors.recaptcha && (
                              <div
                                className="invalid-feedback"
                                style={styleError}
                              >
                                {errors.recaptcha}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 inquiry-form-btn">
                          <Button
                            className="btn secondry-btn"
                            type="submit"
                            disabled={isButtonDisabled}
                          >
                            Send Inquiry
                          </Button>
                        </div>
                      </div>
                    </form>
                    {successmsg && (
                      <div className="alert alert-success mt-3">
                        {successmsg}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-sm-12 col-md-12">

              </div>
              </div>

            </div>

        </div>

        {/* <div className="container-fluid contact-wrape">
          <div className="box-inner">
            <div className="row">
              <div className="col-lg-12">
                <div className="map-wrape">
                   <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6680.063392228654!2d-83.29305314417903!3d33.160794723328266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f6b8d7dc3786a1%3A0xd53a4d92044edd96!2s304%20Riley%20Cir%20NW%2C%20Milledgeville%2C%20GA%2031061%2C%20USA!5e0!3m2!1sen!2sin!4v1737182591293!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe> *
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      <Footer />
    </>
  );
}

export default InquiryUs;
