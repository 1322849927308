import React from "react";
import { Link } from "react-router-dom";

function TermsOfConditionsBuyer() {
  return (
    <div>
     
      <p>
        <b>Effective Date:</b> 25 Jan 2025
      </p>
      <p>
        <b>Last Updated:</b> 2 Dec 2024
      </p>
      <p>
        <b>Website:</b>{" "}
        <Link to="/">
                www.orextrade.com </Link>{" "}
      </p>

      <h2>1. Introduction</h2>

      <p>
        These <b>Buyer Terms and Conditions</b> govern the rights, obligations and
        responsibilities of buyers who register and operate on <b>Orex Trade World</b>
        ("Company", "we", "our" or "us"). By accessing and using our platform,
        you agree to comply with these terms. If you do not agree, you must
        discontinue use immediately.
      </p>

      <h2>2. Buyer Registration & Eligibility</h2>
      <p>To register as a buyer on <b>Orex Trade</b>, you must:</p>
      <ul>
        <li>
          Provide accurate business details, including a valid business license,
          tax identification number and contact information.
        </li>
        <li>
          Ensure that your business complies with international trade laws and
          the regulations of the jurisdictions in which you operate.
        </li>
        <li>
          Agree to undergo verification to confirm your business legitimacy.
        </li>
      </ul>

      <h2>3. Buyer Membership Plan</h2>
      <p>Orex Trade offers a <b>Zero Cost Buyer Plan</b>, which includes:</p>
      <ul>
        <li>Free registration and access to the platform.</li>
        <li>Ability to search and connect with suppliers.</li>
        <li>Verification options for improved credibility.</li>
        <li>Access to limited trade insights and analytics.</li>
        <li>Customer support assistance.</li>
      </ul>

      <h2>4. Buyer Responsibilities</h2>
      <p>As a buyer on Orex Trade, you agree to:</p>
      <ul>
        <li>Conduct due diligence on suppliers before making purchases.</li>
        <li>
          Ensure compliance with trade regulations, customs duties and taxation
          policies in your respective country.
        </li>
        <li>
          Maintain ethical business practices and avoid fraudulent transactions.
        </li>
        <li>Respond promptly to supplier inquiries and negotiations.</li>
        <li>
          Honor agreements and trade commitments made through the platform.
        </li>
      </ul>

      <h2>5. Detailed Plan Terms & Conditions</h2>
      <p>
        Orex Trade offers a comprehensive facility enabling buyers to
        efficiently discover suppliers tailored specifically to their needs,
        searchable by HS codes and commodity classifications. Through our
        user-friendly platform, buyers can initiate direct engagement with
        suppliers by submitting detailed inquiries via the Orex system. To
        facilitate effective communication and seamless transactions, buyers
        must thoroughly complete the inquiry form, providing explicit details
        such as product specifications, quantity, delivery timelines, payment
        terms and any other special requirements or conditions.
      </p>

      <p>
        Once submitted, the targeted supplier will receive the inquiry and is
        required to respond within a stipulated timeframe of 48 hours. If a
        response is not provided within this period, our sophisticated matching
        algorithm automatically redirects the inquiry to the next most suitable
        and verified supplier within our extensive network. This automated
        reallocation ensures minimal disruption and maintains continuity in your
        procurement process.
      </p>

      <p>
        Additionally, Orex Trade periodically monitors inquiry responsiveness,
        encouraging supplier accountability and fostering a responsive trade
        environment. Suppliers who consistently demonstrate timely
        responsiveness and high levels of buyer satisfaction may gain enhanced
        visibility and priority within the platform, ultimately improving
        buyer-supplier relationships and overall trading efficiency.
      </p>

      <p>
        Furthermore, Orex Trade reserves the right to periodically review and
        modify the inquiry submission and response processes to continually
        enhance the effectiveness of buyer-supplier interactions, improve lead
        quality and ensure compliance with international trade best practices.
      </p>

      <h2>6. Fees, Payments & Refunds</h2>
      <ul>
        <li>
          The <b>Zero Cost Buyer Plan</b> is free; however, optional services may incur
          charges.
        </li>
        <li>
          Any add-on services, such as background checks or premium access, must
          be paid through approved payment gateways.
        </li>
        <li>
          Fees for premium services are non-refundable unless explicitly stated.
        </li>
      </ul>

      <h2>7. Dispute Resolution & Liability</h2>
      <ul>
        <li>
          Buyers are responsible for negotiating and resolving disputes with
          suppliers regarding product quality, delivery and pricing.
        </li>
        <li>
          Orex Trade acts as a <b>B2B platform</b> and does <b>not</b> guarantee transactions
          between buyers and suppliers.
        </li>
        <li>
          Any disputes requiring Orex intervention must follow the <b>binding arbitration</b> process outlined in our policies.
        </li>
      </ul>

      <h2>8. Termination & Compliance</h2>
      <ul>
        <li>
          Orex Trade reserves the right to <b>suspend or terminate buyer accounts</b>
          found engaging in fraudulent activities or violating platform
          policies.
        </li>
        <li>
          Buyers must adhere to international business regulations and ethical
          trade practices.
        </li>
        <li>
          Failure to comply with Orex’s terms may result in permanent suspension
          from the platform.
        </li>
      </ul>

      <h2>9. Contact Information</h2>
      <p>
        For any questions or concerns regarding these <b>Buyer Terms and
        Conditions</b>, contact us:
      </p>
      <ul>
        <li>
          <b>Email</b>:{" "}
          <a href="mailto:support@orextrade.com">support@orextrade.com</a>
        </li>
        <li>
          <b>WhatsApp</b>: <b>+1 (786) 772-5447</b>
        </li>
        <li>
          <b>Website</b>: <Link to="/">
                  www.orextrade.com </Link>{" "}
        </li>
      </ul>

      <p>
        These <b>Buyer Terms and Conditions</b> ensure a fair, secure and professional
        trade environment for all businesses using <b>Orex Trade World.</b>
      </p>
    </div>
  );
}

export default TermsOfConditionsBuyer;
