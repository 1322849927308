import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from './Button'
import ButtonCancell from './ButtonCancell'
import ButtonAdminpanel from './ButtonAdminpanel'


function MediumPopup(props) {
    return (

        <div>

            <Modal

                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.mediummodalShow}
                onHide={() => props.setMediumModalShow(false)}

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='modal-title mx-3'>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}

                </Modal.Body>

                {(props.closeButtonLabel || props.okButtonLabel) && (
                    <Modal.Footer>

                        {props.closeButtonLabel &&
                            <ButtonCancell

                                onClick={() => props.setMediumModalShow(false)}
                            >{props.closeButtonLabel}</ButtonCancell>
                        }
                        {props.okButtonLabel &&
                            <ButtonAdminpanel
                                type={props.type}
                                onClick={props.onSubmit}
                                className='mx-3'

                            >{props.okButtonLabel}</ButtonAdminpanel>
                        }
                    </Modal.Footer>
                )}
            </Modal>
        </div>
    )
}

export default MediumPopup