import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import defaultValues from '../../constant/defaultValues';

import { getLogisticsMemberList } from '../../actions/memberAction';

import AcSimpleTable from "../../components/AcSimpleTable";
import InputField from '../../components/InputField';
import SubHeader from '../../components/SubHeader';

import { useAuth } from '../../AuthProvider';
import { hideLoading, showLoading } from "../../actions/loaderAction";
import { ToastContainer } from "react-toastify";
import { showErrorMessage } from '../../utils/CustomNotification';
import { capitalize, debounce, isEmpty } from 'lodash';

function LogisticsMembers(props) {

    const { admin, COMPANY_DEFAULTS } = defaultValues;
    const statusList = COMPANY_DEFAULTS.STATUS;
    const { user } = useAuth();

    const [tableKey, setTableKey] = useState(0);
    const [memberList, setMemberList] = useState([]);
    const [offset, setOffset] = useState(admin.OFFSET);
    const [limit, setLimit] = useState(admin.LIMIT);
    const [searchTxt, setSearchTxt] = useState("");
    const [loading, setLoading] = useState(false);
    const [totalRows, settotalRows] = useState(0);
    const initialRef = useRef(true);
    const [columnName, setColumnName] = useState(admin.COLUMN_NAME);
    const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);
    const [current_page, setCurrentPage] = useState(1);

    const columns = [
        {
            name: 'Sr No',
            selector: (row, idx) => {
                return (
                    <>{offset + (idx + 1)}</>
                )
            },
            width: "5%"
        },
        {
            name: 'Unique Number',
            selector: row => <div className='text-wrap'>{row.member_unique_number}</div>,
            //width: "10%",
            width: "13%",
            columnName: 'member_unique_number',
            sortable: true
        },
        {
            name: 'Company Name',
            selector: row => <div className='tbl-text-wrap-row'>{row.company_name}</div>,
            width: "35%",
            columnName: 'company_name',
            sortable: true
        },
        // {
        //     name: 'Name',
        //     selector: row => {
        //         let first_name = !isEmpty(row.first_name) ? row.first_name : '';
        //         let last_name = !isEmpty(row.last_name) ? row.last_name : '';
        //         return <div className='text-wrap text-capitalize'>{first_name + ' ' + last_name}</div>
        //     },
        //     width: "10%",
        //     columnName: 'first_name',
        //     sortable: true
        // },
        // {
        //     name: 'Email',
        //     selector: row => <div className='tbl-text-wrap-row'>{row.email}</div>,
        //     width: "21%",
        //     columnName: 'email',
        //     sortable: true
        // },
        {
            name: 'Trade Mode',
            selector: row => <div className='text-wrap'>{row.shipment_mode}</div>,
            //width: "10%",
            width: "13%",
            columnName: 'shipment_mode',
            sortable: true
        },
        {
            name: 'Country',
            selector: row => <div className='text-wrap'>{row.country.name}</div>,
            width: "19%",
            columnName: "country_name",
            sortable: true
        },

        // {
        //     name: 'Status',
        //     selector: (row) => {
        //         const rowStatus = statusList.find(option => option.value === row.status);
        //         return rowStatus.label;
        //     },
        //     width: "10%"

        // },
        {
            name: 'Actions',
            cell: (row) => (
                <>
                    {user.role_slug.slug === "SUP_ADM" && <Link to={"/updateprofile/" + row.id}>
                        <button className="btn action-icon-btn m-0 p-0 " data-toggle="tooltip" data-placement="top" title="Edit">
                            <i className="fa-regular fa-pen-to-square icon-pen"></i>
                        </button>
                    </Link>
                    }

                    <Link to={"/company/" + row.id}>
                        <button className="btn action-icon-btn m-0 p-0 mx-2 " data-toggle="tooltip" data-placement="top" title="View">
                            <i className="fa-regular fa-eye icon-eyes"></i>
                        </button>
                    </Link>
                    {/* 
                    <button className="btn" >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                    </button> */}
                </>
            ),
            Width: '10%'
        }
    ];

    const handleSort = async (column, sortDirection) => {
        setColumnName(column.columnName);
        setSortDirection(sortDirection);
        // getRegisteredLogisticsMembers(
        //     admin.OFFSET,
        //     admin.LIMIT,
        //     current_page,
        //     column.columnName,
        //     sortDirection,
        //     searchTxt
        // );
        getRegisteredLogisticsMembers(
            offset,
            limit,
            current_page,
            column.columnName,
            sortDirection,
            searchTxt
        );
    };

    const onSearchTextBoxChanged = debounce((e) => {
        setSearchTxt(e.target.value);
        let searchVal = e.target.value;
        if (searchVal.length >= 3 || searchVal.length === 0) {
            setOffset(admin.OFFSET);
            setLimit(admin.LIMIT);
            setColumnName(admin.COLUMN_NAME);
            setSortDirection(admin.SORT_DIRECTION);
            setCurrentPage(1);
            //console.log("Search ============ ", e.target.value);
            setTableKey((prev) => prev + 1);
            getRegisteredLogisticsMembers(admin.OFFSET, admin.LIMIT, 1, admin.COLUMN_NAME, admin.SORT_DIRECTION, e.target.value);
        }

    });


    const getRegisteredLogisticsMembers = async (offset, limit, current_page = 1, columnName, sortDirection, searchTxt) => {
        await props.showLoading();
        await props.getLogisticsMembers(offset, limit, current_page, columnName, sortDirection, searchTxt).then((response) => {
            setMemberList(response.data);
            settotalRows(response.metadata.total);
        }).catch((err) => {
            console.log("Error ==== ", err);
            showErrorMessage("Something went wrong");
        })
        await props.hideLoading();
    }

    useEffect(() => {
        if (initialRef.current === true) {
            initialRef.current = false;
            getRegisteredLogisticsMembers(offset, limit, current_page, columnName, sortDirection, searchTxt);
        }
    }, []);


    const expandedRowComponent = ({ data }) => {
        const rowStatus = statusList.find(option => option.value === data.status);
        const statusLabel = rowStatus ? rowStatus.label : '';
        return (

            <div style={{ padding: '20px', borderTop: '1px solid #ddd' }}>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: '22px',
                    padding: '12px',
                    border: '1px solid #ddd',
                    borderRadius: '8px'
                }}>
                    <div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Name:</strong> {(data.salutation ? data.salutation : '') + `  ` + (data.first_name ? data.first_name : '') + ` ` + (data.last_name ? data.last_name : '')}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Email:</strong> {data.email || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Plan Name:</strong> {(data.plan_name == null) ? "Free" : data.plan_name}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Established Year:</strong> {data.establish_date || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Status:</strong> {statusLabel}
                        </div>

                    </div>
                    <div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Phone:</strong> {data.phone || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Country:</strong> {data.country.name || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Company Size:</strong> {data.company_size || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Trade Mode: </strong>
                            {(data.shipment_mode) && data.shipment_mode} {(data.shipment_mode) && (data.shipment_mode === 'Manufacturer' || data.shipment_mode === 'Trader') ? '(' + capitalize(data.trade_mode_type) + ')' : ''}
                        </div>
                        {data.status === 4 && <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Reason:</strong> {data.reason || '-'}
                        </div>
                        }


                    </div>
                    <div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Mobile:</strong> {data.mobile || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Industry: </strong> {data.member_industry ? data.member_industry.industy_name : "-"}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>What You Want With Us:</strong> {data.with_us || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Website:</strong> {data.website || '-'}
                        </div>

                    </div>
                </div>
            </div>
        );
    };


    return (<>

        <div className='data-table-main'>
            <div className="AcSimpleTable">

                <SubHeader layer1="Logistics Management" layer2="List" layer3=""
                    pageName="Logistics Members"
                    clickable={{
                        layer1: "",
                        layer2: "",
                        layer3: ""
                    }}
                />
                <div className='card1'>

                    <div className='table-header-name'>
                        <h5 className='table-text py-3'>List</h5>
                        <div className='d-flex justify-content-between align-items-center py-3'>
                            <label className='mx-3'>Search</label>
                            <InputField
                                type="text"
                                id="filter-text-box"
                                placeholder="Search..."
                                className='w-25'
                                onKeyUp={onSearchTextBoxChanged}
                                autoComplete="off"
                            ></InputField>

                            {/* <button className='export-btn'>

                            <img src={exportIcon} alt="" />
        
                            Export</button> */}

                            {/* <button className='table-add-user-button'>
                            <img src={tableAdduserIcon} alt="" /> Add New User</button> */}

                        </div>

                    </div>

                    <div className="table-wrapper">
                        <div className="table-container">
                            {memberList && (
                                <AcSimpleTable
                                    key={tableKey}
                                    data={memberList}
                                    title=""
                                    pagination={true}
                                    paginationPageSize={limit}
                                    columns={columns}
                                    progressPending={loading}
                                    paginationTotalRows={totalRows}
                                    fixedColumnsStart={admin.FIXED_COLUMNS_START}
                                    getData={getRegisteredLogisticsMembers}
                                    offset={offset}
                                    limit={limit}
                                    columnName={columnName}
                                    sortDirection={sortDirection}
                                    setLimit={setLimit}
                                    setOffset={setOffset}
                                    setColumnName={setColumnName}
                                    setSortDirection={setSortDirection}
                                    setLoading={setLoading}
                                    // noDataComponent={<NoData />}
                                    paginationPerPage={limit}
                                    paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                                    // className="company-table"
                                    striped
                                    sortServer
                                    onSort={handleSort}
                                    defaultSortAsc={false}
                                    current_page={current_page}
                                    setCurrentPage={setCurrentPage}
                                    expandableRows
                                    expandableRowsComponent={expandedRowComponent}
                                />
                            )}
                        </div>
                    </div>
                </div>


            </div>
            <ToastContainer />
        </div>

    </>)
}

const mapStateToProps = state => {

    return {
        member_list: state.member.pendingMemberlist,
        // authError: state.auth.errorMessages
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getLogisticsMembers: (offset, limit, cuurent_page, columnName, sortDirection, searchTxt) => dispatch(getLogisticsMemberList(offset, limit, cuurent_page, columnName, sortDirection, searchTxt)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogisticsMembers);
