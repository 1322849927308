import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import DropDown from "../components/DropDown";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-number-input/style.css";
import Select from "react-select";
import Footer from "../Footer";
import axios from "axios";
import errorMessages from "../constant/errorMessages";
import API_URL from "../config/apiConfig";
import ROUTES from "../config/routePath";
const invalidEmails = [
  "yopmail.com",
  "mailinator.com",
  "10minutemail.com",
  "guerrillamail.com",
  "trashmail.com",
  "temp-mail.org",
  "dispostable.com",
  "fakeinbox.com",
  "sharklasers.com",
  "getairmail.com",
  "maildrop.cc",
  "spambog.com",
  "mytemp.email",
  "throwawaymail.com",
  "tempmailaddress.com",
  "mailcatch.com",
  "moakt.com",
  "temp-mail.io",
  "tutanota.com",
];

function ContactUs() {
  const [phoneValue, setphoneValue] = useState();
  const [mobileValue, setmobileValue] = useState();
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [recaptchaValue, setRecapchaValue] = useState(null);
  const recaptcha = useRef();
  const [successmsg, setMessage] = useState("");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    mobile: "",
    message: "",
    company_name: "",
  });

  const handleChange = (e) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleRecaptchaChange = (value) => {
    setRecapchaValue(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      recaptcha: value ? "" : "Please complete the CAPTCHA",
    }));
  };

  const handlePhoneChange = (val) => {
    setphoneValue(val);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        phone: val,
      };
    });
  };

  const handleMobileChange = (val) => {
    setmobileValue(val);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        mobile: val,
      };
    });
  };

  const handleFocusOut = async (e) => {
    let flag = 0;
    let er = { ...errors };

    if (e.target.name === "first_name") {
      if (e.target.value === "") {
        flag = 1;
        er.first_name = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.first_name = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp =
          /^(?!\d+$)[a-zA-Z\s\-\'.\(\)&]*\s*(\([a-zA-Z\s\-\'.\&]*\))?[\s\-\.\(\)&a-zA-Z]*\d?[\s\-\.\(\)&a-zA-Z0-9]*$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.first_name = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.first_name = "";
        }
      }
    }

    if (e.target.name === "last_name") {
      flag = 0;
      if (e.target.value !== "") {
        if (e.target.value.length > 40) {
          flag = 1;
          er.last_name = errorMessages.MAX_LENGTH_EXCEEDED;
        } else {
          const regExp =
            /^(?!\d+$)[a-zA-Z\s\-\'.\(\)&]*\s*(\([a-zA-Z\s\-\'.\&]*\))?[\s\-\.\(\)&a-zA-Z]*\d?[\s\-\.\(\)&a-zA-Z0-9]*$/;
          const check = regExp.test(e.target.value);
          if (check === false) {
            flag = 1;
            er.last_name = errorMessages.IN_VALID_FIELD_FORMAT;
          } else {
            flag = 0;
            er.last_name = "";
          }
        }
      } else {
        flag = 0;
        er.last_name = "";
      }
    }

    if (e.target.name === "email") {
      if (e.target.value === "") {
        flag = 1;
        er.email = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.email = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const currentEmail = e.target.value.split("@")[1];
        const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else if (invalidEmails.includes(currentEmail)) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.email = "";
        }
      }
    }
    if (e.target.name === "phone") {
      let phoneValue = e.target.value.trim();
      if (!phoneValue) {
        flag = 1;
        er.phone = errorMessages.FIELD_REQUIRED;
      } else {
        let numberWithoutCountryCode = phoneValue
          .replace(/^\+(\d{1,4})\s?/, "")
          .replace(/\s/g, "");

        if (numberWithoutCountryCode.length === 0) {
          flag = 1;
          er.phone = errorMessages.FIELD_REQUIRED;
        } else if (!isValidPhoneNumber(phoneValue)) {
          flag = 1;
          er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
        } else {
          flag = 0;
          er.phone = "";
        }
      }
    }

    if (e.target.name === "mobile") {
      let mobileValue = e.target.value;
      let numberWithoutCountryCode = mobileValue.replace(/^\+(\d{1,4})\s?/, "");
      numberWithoutCountryCode = numberWithoutCountryCode.replace(/\s/g, "");

      if (numberWithoutCountryCode.length === 0) {
        flag = 1;
        er.mobile = "";
      } else if (
        !isValidPhoneNumber(mobileValue) &&
        numberWithoutCountryCode.length > 0
      ) {
        flag = 1;
        er.mobile = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
      } else {
        flag = 0;
        er.mobile = "";
      }
    }
    if (e.target.name === "message") {
      if (e.target.value === "") {
        flag = 1;
        er.message = errorMessages.FIELD_REQUIRED;
      } else {
        flag = 0;
        er.message = "";
      }
    }

    setErrors(er);
    if (flag === 0) {
      setisButtonDisabled(false);
      return false;
    } else {
      // setisButtonDisabled(true);
      return true;
    }
  };

  const validation = () => {
    let er = { ...errors };
    let flag = 0;

    if (formData.first_name === "") {
      flag = 1;
      er.first_name = errorMessages.FIELD_REQUIRED;
    } else if (formData.first_name.length > 40) {
      flag = 1;
      er.first_name = errorMessages.MAX_LENGTH_EXCEEDED;
    } else {
      const regExp =
        /^(?!\d+$)[a-zA-Z\s\-\'.\(\)&]*\s*(\([a-zA-Z\s\-\'.\&]*\))?[\s\-\.\(\)&a-zA-Z]*\d?[\s\-\.\(\)&a-zA-Z0-9]*$/;
      const check = regExp.test(formData.first_name);
      if (check === false) {
        flag = 1;
        er.first_name = errorMessages.IN_VALID_FIELD_FORMAT;
      } else {
        flag = 0;
        er.first_name = "";
      }
    }

    if (formData.last_name !== "") {
      if (formData.last_name.length > 40) {
        flag = 1;
        er.last_name = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp =
          /^(?!\d+$)[a-zA-Z\s\-\'.\(\)&]*\s*(\([a-zA-Z\s\-\'.\&]*\))?[\s\-\.\(\)&a-zA-Z]*\d?[\s\-\.\(\)&a-zA-Z0-9]*$/;
        const check = regExp.test(formData.last_name);
        if (check === false) {
          flag = 1;
          er.last_name = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.last_name = "";
        }
      }
    } else {
      flag = 0;
      er.last_name = "";
    }

    if (formData.email === "") {
      flag = 1;
      er.email = errorMessages.FIELD_REQUIRED;
    } else if (formData.email.length > 40) {
      flag = 1;
      er.email = errorMessages.MAX_LENGTH_EXCEEDED;
    } else {
      const currentEmail = formData.email.split("@")[1];
      const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const check = regExp.test(formData.email);
      if (check === false) {
        flag = 1;
        er.email = errorMessages.IN_VALID_FIELD_FORMAT;
      } else if (invalidEmails.includes(currentEmail)) {
        flag = 1;
        er.email = errorMessages.IN_VALID_FIELD_FORMAT;
      } else {
        flag = 0;
        er.email = "";
      }
    }

    if (
      phoneValue === undefined ||
      !phoneValue ||
      !isValidPhoneNumber(phoneValue)
    ) {
      if (phoneValue == null) {
        flag = 1;
        er.phone = errorMessages.FIELD_REQUIRED;
      } else {
        let numberWithoutCountryCode = phoneValue.replace(
          /^\+(\d{1,4})\s?/,
          ""
        );
        numberWithoutCountryCode = numberWithoutCountryCode.replace(/\s/g, "");
        if (
          phoneValue === undefined ||
          (phoneValue === "" && numberWithoutCountryCode.length == 0)
        ) {
          flag = 1;
          er.phone = errorMessages.FIELD_REQUIRED;
        } else {
          if (numberWithoutCountryCode.length > 0) {
            flag = 1;
            er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
          }
        }
      }
    } else {
      flag = 0;
      er.phone = "";
    }

    if (mobileValue) {
      let numberWithoutCountryCode = mobileValue.replace(/^\+(\d{1,4})\s?/, "");
      numberWithoutCountryCode = numberWithoutCountryCode.replace(/\s/g, "");
      if (
        !mobileValue ||
        (!isValidPhoneNumber(mobileValue) &&
          numberWithoutCountryCode.length > 0)
      ) {
        flag = 1;
        er.mobile = errorMessages.MOBILE_NUMBER_VALIDATION_MSG;
      }
    }

    if (mobileValue && phoneValue) {
      if (mobileValue === phoneValue) {
        flag = 1;
        er.mobile = errorMessages.UNIQUE_MSG;
      }
      if (mobileValue !== phoneValue && isValidPhoneNumber(mobileValue)) {
        flag = 0;
        er.mobile = "";
      }
    }

    if (formData.message === "") {
      flag = 1;
      er.message = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.message = "";
    }

    if (recaptchaValue === null) {
      flag = 1;
      er.recaptcha = "Please verify the reCAPTCHA!";
    } else {
      flag = 0;
      er.recaptcha = "";
    }

    setErrors(er);
    if (flag === 1) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validation()) {
      try {
        setisButtonDisabled(true);
        axios
          .post(API_URL + "/contact-inquiry", formData, {
            headers: {
              Accept: "application/json",
            },
            timeout: 10000,
          })
          .then((response) => {
            if (response.data.status === true) {
              setMessage(
                "The contact inquiry has been submitted successfully."
              );
              setTimeout(() => {
                recaptcha.current.reset();
                setMessage("");
                const frmdata = {
                  first_name: "",
                  last_name: "",
                  email: "",
                  phone: "",
                  mobile: "",
                  message: "",
                  company_name: "",
                };
                setFormData(frmdata);
                setmobileValue(null);
                setphoneValue(null);
                setErrors([]);
                setisButtonDisabled(false);
              }, 3000);
              setRecapchaValue(null);
            } else {
              setisButtonDisabled(false);
              setErrors([]);
              const errors = {};
              if (response.data.error.first_name) {
                errors.first_name = response.data.error.first_name;
              }
              if (response.data.error.last_name) {
                errors.last_name = response.data.error.last_name;
              }
              if (response.data.error.email) {
                errors.email = response.data.error.email;
              }
              if (response.data.error.phone) {
                errors.phone = response.data.error.phone;
              }
              if (response.data.error.message) {
                errors.message = response.data.error.message;
              }
              setErrors(errors);
              return Object.keys(errors).length > 0;
            }
          })
          .catch((error) => {
            setisButtonDisabled(false);
            setRecapchaValue(null);
            console.error("Error fetching data:", error);
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (recaptchaValue !== null) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        recaptcha: "",
      }));
      setisButtonDisabled(false);
    }
  }, [recaptchaValue]);

  const styleError = {
    display: "block",
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <section className="inner-pad page contact-us-wrape">
        <section className="inner-banner-wrape global-advertising banner-wrape">
          <div className="inner"> Contact Us</div>
          <span class="sprite decoration"></span>
        </section>

        <div className="container map-bg">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="section-head center">
                <div className="head">
                  <p>Orex Trade</p>
                  <h2>
                    <span>Contact</span> Us
                  </h2>
                </div>

                <p className="text">
                  Want to get in touch? We'd love to hear from you. Here's how
                  you can reach us.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="white-wrape">
              <div className="col-lg-7 col-sm-12 col-md-12">
                <div className="form-wrape">
                  <div className="container-fluid">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-sm-6 ">
                          <div className="input-group">
                            <label className="form-label required">
                              First Name
                            </label>
                            <div className="input-pad">
                              <input
                                type="text"
                                name="first_name"
                                className="form-control"
                                value={formData.first_name}
                                autoComplete="off"
                                placeholder="Enter first name"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                              />
                              {errors.first_name && (
                                <span
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.first_name}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 ">
                          <div className="input-group">
                            <label className="form-label">Last Name</label>
                            <div className="input-pad">
                              <input
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter last name"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                              />
                              {errors.last_name && (
                                <span
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.last_name}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 ">
                          <div className="input-group">
                            <label className="form-label required">Email</label>
                            <div className="input-pad">
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                value={formData.email}
                                autoComplete="off"
                                placeholder="Enter email"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                              />
                              {errors.email && (
                                <span
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.email}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 ">
                          <div className="input-wrape">
                            <label className="form-label required">
                              Phone Number
                            </label>
                            <div className="input-pad">
                              <PhoneInput
                                id="contact-form-phone"
                                className="form-control inquiry-phone inp-font-size border-dropdown company-representive-border outer_pages_phone_input"
                                name="phone"
                                international
                                limitMaxLength
                                placeholder="Enter phone number"
                                autoComplete="off"
                                value={phoneValue}
                                onChange={handlePhoneChange}
                                onBlur={handleFocusOut}
                              />
                              {errors.phone && (
                                <span
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.phone}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 ">
                          <div className="input-wrape">
                            <label className="form-label">
                              Whatsapp Number
                            </label>
                            <div className="input-pad">
                              <PhoneInput
                                id="contact-form-phone"
                                className="form-control inquiry-phone inp-font-size border-dropdown company-representive-border outer_pages_phone_input"
                                name="mobile"
                                international
                                limitMaxLength
                                placeholder="Enter whatsapp number"
                                autoComplete="off"
                                value={mobileValue}
                                onChange={handleMobileChange}
                                onBlur={handleFocusOut}
                              />
                              {errors.mobile && (
                                <span
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.mobile}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 ">
                          <div className="input-group">
                            <label className="form-label">Company Name</label>
                            <div className="input-pad">
                              <input
                                type="text"
                                name="company_name"
                                value={formData.company_name}
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter company name"
                                onChange={handleChange}
                              //onBlur={handleFocusOut}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 ">
                          <hr />
                        </div>
                        <div className="col-12">
                          <div className="input-wrape">
                            <label
                              className="form-label inquiry-text required"
                              for="contact-form-message"
                            >
                              Message
                            </label>
                            <div className="input-pad">
                              <textarea
                                id="contact-form-message"
                                className="form-control"
                                rows="3"
                                placeholder="Write a message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                              ></textarea>
                              {errors.message && (
                                <span
                                  className="invalid-feedback"
                                  style={styleError}
                                >
                                  {errors.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="input-wrape captcha-wrape">
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_SITE_KEY}
                              onChange={handleRecaptchaChange}
                              ref={recaptcha}
                              onExpired={() => {
                                setRecapchaValue(null);
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  recaptcha: "Please complete the CAPTCHA",
                                }));
                              }}
                              onBlur={handleFocusOut}
                            />
                            {errors.recaptcha && (
                              <div
                                className="invalid-feedback"
                                style={styleError}
                              >
                                {errors.recaptcha}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 inquiry-form-btn">
                          <Button
                            className="btn secondry-btn"
                            type="submit"
                            disabled={isButtonDisabled}
                          >
                            Send Inquiry
                          </Button>
                        </div>
                      </div>
                    </form>
                    {successmsg && (
                      <div className="alert alert-success mt-3">
                        {successmsg}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-sm-12 col-md-12">
                <div className="detail-part">
                  <div className="contact-pad">
                    <div className="address">
                      <h6>Head Office</h6>
                      <p>
                        <i className="sprite"></i>
                        304, Riley CIR NW, Milledgeville, GA, 31061-7076, USA
                      </p>
                      <Link className="number" to="tel:+1 (786) 772-5447">
                        <i className="sprite"></i>
                        +1 (786) 772-5447
                      </Link>
                      <Link className="email" to="mailto:info@orextrade.com">
                        <i className="sprite"></i>
                        info@orextrade.com
                      </Link>
                    </div>
                  </div>
                  <div className="other-details">
                    <div className="other">
                      <h6 className="m-b-5">Branch Offices</h6>
                      {/* <Link className="flag usa m-b-15" title="USA"></Link> */}
                      <span className="flag india m-b-15" title="India"></span>
                      <span className="flag uae m-b-15" title="UAE"></span>
                    </div>
                  </div>
                  <div className="other-details">
                    <div className="address">
                      <h6 className="m-b-5">Lets Call & Email</h6>
                      <Link className="number" to="tel:+1 (786) 772-5447">
                        <i className="sprite"></i>
                        +1 (786) 772-5447
                      </Link>
                      <Link className="email" to="mailto:support@orextrade.com">
                        <i className="sprite"></i>
                        support@orextrade.com
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid contact-wrape">
          <div className="box-inner">
            <div className="row">
              <div className="col-lg-12">
                <div className="map-wrape">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6680.063392228654!2d-83.29305314417903!3d33.160794723328266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f6b8d7dc3786a1%3A0xd53a4d92044edd96!2s304%20Riley%20Cir%20NW%2C%20Milledgeville%2C%20GA%2031061%2C%20USA!5e0!3m2!1sen!2sin!4v1737182591293!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Footer />
    </>
  );
}

export default ContactUs;
