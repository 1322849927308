const TradingCompanies = ({ title, data }) => {

    return (
        <div>
            <h5 className='m-3 border-bottom pb-2'>{title}</h5>
            <div style={{ width: "100%", height: "400px" }}>

                <div className="m-3 pb-2">
                    <ul className="trading-company-list">
                        {data && data.map((v, idx) => {
                            return (<li key={`tc_${idx}`}>{v.name}</li>)
                        })}
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default TradingCompanies;