import React from "react";
import FindSupplierIMG from "../imgs/supplier-img.jpg";

function FindSupplier() {
  return (
    <section className="find-supplier-wrape">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="section-head dark">
                    <div className="head">
                      <h2>
                        <span>Finding</span> Suppliers
                      </h2>
                      <p>with Orex Trade</p>
                    </div>

                    <p className="text">
                      Orex Trade can also help you locate reliable suppliers.
                      Here's how you can use Orex Trade to find the right
                      suppliers for your business
                    </p>
                  </div>

                  <div className="container-fluid usp-wrape dark">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="usp one">
                          <i className="sprite search"></i>
                          <div className="detail">
                            <h3>Supplier Search</h3>
                            <p>
                              Orex Trade allows you to search for suppliers
                              based on specific criteria such as{" "}
                              <b>
                                product type, HSN Code, country or company name
                              </b>
                              . This helps you narrow down your options and find
                              the best suppliers who fulfill your specific
                              requirements. We provide a comprehensive supplier
                              search service that takes the hassle out of the
                              process to help you find trustworthy suppliers.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="usp two">
                          <i className="sprite analytic"></i>
                          <div className="detail">
                            <h3>Verified Supplier </h3>
                            <p>
                              Orex Trade provides verified information on
                              suppliers, including their{" "}
                              <b>product portfolios and certification status</b>
                              . This ensures that you're dealing with secure
                              suppliers and minimizes the threat of fraudulent
                              deals. With Orex Trade, you can be confident that
                              the supplier information you receive is accurate,
                              up-to-date and thoroughly verified.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="usp three">
                          <i className="sprite certified"></i>
                          <div className="detail">
                            <h3>Competitive Analysis</h3>
                            <p>
                              Orex Trade offers a comprehensive analysis of{" "}
                              <b>market trends and supplier performance</b>.This
                              information enables you to compare different
                              suppliers and make informed decisions. Choose the
                              right supplier to ensure quality and timely
                              shipment of your products. Stay ahead in your
                              business by availing yourself of the benefits of
                              competitive analysis on our platform.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="usp four">
                          <i className="sprite identify"></i>
                          <div className="detail">
                            <h3>Suppliers Final Selection</h3>
                            <p>
                              We conduct the final screening details once we
                              have compiled a list of the world's top prospects
                              for you. To ensure that you can continue trading
                              without experiencing any lag, we further filter
                              down the list under this{" "}
                              <b>
                                screening process and get just the finest one
                                for you
                              </b>
                              . Our goal is to give our clients the greatest
                              supplier list possible.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="img-wrape">
                    <div className="buble-lable">
                      <div className="inner">
                        <h5>21.7K</h5>
                        <p>Verified Clients Worldwide</p>
                      </div>
                    </div>
                    <img src={FindSupplierIMG} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-7">
            <div className="section-head dark">
              <div className="head">
                <h2>
                  <span>Finding</span> Suppliers
                </h2>
                <p>with Orex Trade</p>
              </div>

              <p className="text">
                Finding buyers for your products is essential for a successful trading business. Here's how Orex Trade can be used effectively to identify potential buyers
              </p>
            </div>

            <div className="container-fluid usp-wrape dark">
              <div className="row">
                <div className="col-lg-6">
                  <div className="usp one">
                    <i className="sprite search"></i>
                    <div className="detail">
                      <h3>Keywords Search</h3>
                      <p>Orex Trade allows you to search for buyers by using specific keywords related to your product.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp two">
                    <i className="sprite analytic"></i>
                    <div className="detail">
                      <h3>Analytics and Insights</h3>
                      <p>Orex Trade provides valuable analytics and insights into global trade trends.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp three">
                    <i className="sprite certified"></i>
                    <div className="detail">
                      <h3>Certified Buyer Profiles</h3>
                      <p>Orex Trade offers detailed Certified profiles of buyers, including information on their product preferences.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp four">
                    <i className="sprite identify"></i>
                    <div className="detail">
                      <h3>Identify The Right Market</h3>
                      <p>Orex Trade provides valuable analytics and insights into global trade trends.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-5">
            <div className="img-wrape">
              <img src={FindSupplierIMG} alt="" />
            </div>
          </div>           */}
        </div>
      </div>
    </section>
  );
}

export default FindSupplier;
