import React, { useEffect, useState } from 'react';

import { useAsyncError, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import moment from 'moment';

import { MENU_ROUTES } from '../../config/menuRoutes';
import defaultValues from '../../constant/defaultValues';

import { deleteShipment, ListShipments } from '../../actions/shipmentAction';

import { ToastContainer } from 'react-toastify';
import SubHeader from '../../components/SubHeader';
import InputField from '../../components/InputField';
import AcSimpleTable from '../../components/AcSimpleTable';
import { showSuccessMessage, showErrorMessage } from '../../utils/CustomNotification';
import SmallPopup from '../../components/SmallPopup';
import Choosefile from '../../components/Choosefile';
import MediumPopup from '../../components/MediumPopup';
import { hideLoading, showLoading } from '../../actions/loaderAction';
import { useAuth } from '../../AuthProvider';
import { debounce } from 'lodash';



function ListShipment(props) {

    const { admin } = defaultValues;
    const { isSuperAdmin } = useAuth();

    const nav = useNavigate();

    const [tableKey, setTableKey] = useState(0);
    const [shipmentlist, setShipmentList] = useState([]);
    const [offset, setOffset] = useState(admin.OFFSET);
    const [limit, setLimit] = useState(admin.LIMIT);
    const [totalRows, settotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchTxt, setSearchTxt] = useState("");
    const [deletedShipmentId, setDeletedShipmentId] = useState(null);
    const [smallmodalShow, setSmallModalShow] = useState(false);
    const [mediummodalShow, setMediumModalShow] = useState(false);
    const [columnName, setColumnName] = useState(admin.COLUMN_NAME);
    const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);
    const [current_page, setCurrentPage] = useState(1);


    const columns = [
        {
            name: 'Sr No',
            selector: (row, idx) => {
                return (
                    <>{offset + (idx + 1)}</>
                )
            },
            // resizable: true,
            width: '100px'
        },
        /* {
            name: "Bill Date",
            selector: (row) => moment(row.bill_date).format("YYYY-MM-DD"),
            // resizable: true,
            width: '150px',
            sortable: true,
            columnName: "bill_date"
        }, */
        {
            name: "HS Code",
            selector: (row) => <div className='tbl-text-wrap-row'>{row.hs_code}</div>,
            // resizable: true,
            width: '120px',
            sortable: true,
            columnName: "hs_code"
        },
        {
            name: "Supplier Name",
            selector: (row) => ((row.supplier_id > 0) ? <div className='tbl-text-wrap-row'> {row.supplier && row.supplier.company_name} </div> : <div className='tbl-text-wrap-row'> {row.supplier_name} </div>),
            // resizable: true,
            width: '250px',
            sortable: true,
            columnName: "supplier_name"
        },
        // {
        //     name: "Supplier Type",
        //     selector: (row) => <div className='tbl-text-wrap-row'>{row.supplier_type}</div>,
        //     width: '150px',
        //     sortable: true,
        //     columnName: "supplier_type"
        // },
        {
            name: "Export Country",
            selector: (row) => (row.export_country) ? <div className='tbl-text-wrap-row'>{row.export_country}</div> : "-",
            // resizable: true,
            width: '200px',
            sortable: true,
            columnName: "export_country"
        },
        {
            name: "Port of Unlanding",
            selector: (row) => (row.port_of_unlanding) ? <div className='tbl-text-wrap-row'>{row.port_of_unlanding}</div> : "-",
            // resizable: true,
            width: '175px',
            sortable: true,
            columnName: "port_of_unlanding"
        },
        {
            name: "Buyer Name",
            selector: (row) => ((row.buyer_id > 0) ? <div className='tbl-text-wrap-row'>{row.buyer.company_name}</div> : <div className='tbl-text-wrap-row'>{row.buyer_name}</div>),
            // resizable: true,
            width: '250px',
            sortable: true,
            columnName: "buyer_name"
        },
        {
            name: "Import Country",
            selector: (row) => (row.import_country) ? <div className='tbl-text-wrap-row'>{row.import_country}</div> : "-",
            // resizable: true,
            width: '200px',
            sortable: true,
            columnName: "import_country"
        },
        {
            name: "Port of landing",
            selector: (row) => (row.port_of_landing) ? <div className='tbl-text-wrap-row'>{row.port_of_landing}</div> : "-",
            width: '150px',
            sortable: true,
            columnName: "port_of_landing"
        },
        /*   {
              name: "Buyer Type",
              selector: (row) => <div className="tbl-text-wrap-row">{row.buyer_type}</div>,
              // resizable: true,
              width: '150px',
              sortable: true,
              columnName: "buyer_type"
          },
          {
              name: "Import Country",
              selector: (row) => (row.import_country) ? <div className='tbl-text-wrap-row'>{row.import_country}</div> : "-",
              // resizable: true,
              width: '170px',
              sortable: true,
              columnName: "import_country"
          }, */

        /*{
            name: "HS Code",
            selector: (row) => (row.hs_code) ? <div className='tbl-text-wrap-row'>{row.hs_code}</div> : '-',
            // resizable: true,
            width: '150px',
            sortable: true,
            columnName: "hs_code"
        },
        {
            name: "Product Name",
            selector: (row) => (row.product_name) ? <div className="tbl-text-wrap-row">{row.product_name}</div> : '-',
            // resizable: true,
            width: '150px',
            sortable: true,
            columnName: "product_name"
        },
        {
            name: "Quantity",
            selector: (row) => (row.product_name) ? <div className="tbl-text-wrap-row">{row.quantity}</div> : '-',
            width: '150px',
            sortable: true,
            columnName: "qty"
        },
        {
            name: "Per Unit Value",
            selector: (row) => (row.product_name) ? <div className="tbl-text-wrap-row">{row.per_unit_value}</div> : '-',
            width: '150px',
            sortable: true,
            columnName: "per_unit_value"
        },
        {
            name: "Item Rate",
            selector: (row) => (row.item_rate) ? <div className="tbl-text-wrap-row">{row.item_rate}</div> : '-',
            width: '150px',
            sortable: true,
            columnName: "item_rate"
        },
        {
            name: "Duty",
            selector: (row) => (row.duty) ? <div className="tbl-text-wrap-row">{row.duty}</div> : '-',
            width: '150px',
            sortable: true,
            columnName: "duty"
        },
        {
            name: "Mode of Transport",
            selector: (row) => (row.transport_mode) ? <div className="tbl-text-wrap-row">{row.transport_mode}</div> : '-',
            width: '200px',
            sortable: true,
            columnName: "transport_mode"
        },
        {
            name: "Actions",
            selector: (row) => {
                return (<>
                    <div>
                        {/* <button className='btn'>
                            <i className='fa-regular fa-pen-to-square icon-pen' ></i>
                        </button>
                        <button className='btn'>
                            <i className='fa fa-plus icon-pen' ></i>
                        </button> */
        /* <button className='btn' onClick={() => handleDeleteClick(row.id)}>
                            <i className='fa-solid fa-trash-can icon-trash' ></i>
                        </button>

                    </div>
                </>)
            },
            resizable: true
        }, */


    ];

    if (isSuperAdmin) {
        const actionColumn = {
            name: "Actions",
            selector: (row) => {
                return (<>
                    <div>
                        {/* <button className='btn'>
                            <i className='fa-regular fa-pen-to-square icon-pen' ></i>
                        </button>
                        <button className='btn'>
                            <i className='fa fa-plus icon-pen' ></i>
                        </button> */}
                        <button className='btn' onClick={() => handleDeleteClick(row.id)}>
                            <i className='fa-solid fa-trash-can icon-trash' ></i>
                        </button>

                    </div>
                </>)
            },
            resizable: true
        };
        // columns.push(actionColumn);
    }

    const handleDeleteClick = (id) => {
        setSmallModalShow(true);
        setDeletedShipmentId(id);
    }

    const handleConfirmDeleteClick = () => {
        props
            .deleteShipment(deletedShipmentId)
            .then((response) => {
                if (response.status === true) {
                    showSuccessMessage("Shipment Deleted Successfully");
                    setOffset(admin.OFFSET);
                    setLimit(admin.LIMIT);
                    setColumnName(admin.COLUMN_NAME);
                    setSortDirection(admin.SORT_DIRECTION);
                    setSearchTxt("");
                    setDeletedShipmentId(null);
                    getShipments(admin.OFFSET, admin.LIMIT, current_page, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");

                }
                setSmallModalShow(false);
            })
            .catch((err) => {
                console.log("Error == ", err);
                showErrorMessage("Something went wrong");
            });
    };

    const handleAddShipmentClick = () => {
        nav(MENU_ROUTES.ADD_SHIPMENT);
    }

    const onSearchTextBoxChanged = debounce(async (e) => {

        let searchVal = e.target.value;
        if (searchVal.length >= 3 || searchVal.length == 0) {
            setOffset(admin.OFFSET);
            setLimit(admin.LIMIT);
            setColumnName(admin.COLUMN_NAME);
            setSortDirection(admin.SORT_DIRECTION);
            setSearchTxt(e.target.value);
            setTableKey((prev) => prev + 1);
            await getShipments(admin.OFFSET, admin.LIMIT, current_page, admin.COLUMN_NAME, admin.SORT_DIRECTION, e.target.value);
        }
    });

    const handleSort = async (column, sortDirection) => {
        setColumnName(column.columnName);
        setSortDirection(sortDirection);
        /*getShipments(
            admin.OFFSET,
            admin.LIMIT,
            current_page,
            column.columnName,
            sortDirection,
            searchTxt
        ); */
        getShipments(
            offset,
            limit,
            current_page,
            column.columnName,
            sortDirection,
            searchTxt
        );
    };

    const getShipments = async (offset, limit, current_page = 1, columnName, sortDirection, searchTxt) => {
        props.showLoading();
        await props
            .ListShipments(offset, limit, columnName, sortDirection, searchTxt)
            .then((response) => {
                setShipmentList([]);
                setShipmentList(response.data);
                settotalRows(response.metadata.total);
                props.hideLoading();
            })
            .catch((err) => {
                console.log("Error ==== ", err);
                showErrorMessage("Something went wrong");
            });
    }

    const openImportShipment = () => {
        setMediumModalShow(true);
    }

    useEffect(() => {
        getShipments(offset, limit, current_page, columnName, sortDirection, searchTxt);

    }, []);



    const expandedRowComponent = ({ data }) => {
        console.log(data);
        return (

            <div style={{ padding: '20px', borderTop: '1px solid #ddd' }}>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    gap: '22px',
                    padding: '12px',
                    border: '1px solid #ddd',
                    borderRadius: '8px'
                }}>
                    <div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Buyer Type:</strong> {data.buyer_type || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Currency:</strong> {data.currency || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Port of Unlanding:</strong> {data.port_of_unlanding || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>HS Code:</strong> {data.hs_code || '-'}
                        </div>

                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Product Name:</strong> {data.product_name || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>IEC No.:</strong> {data.iec_number || '-'}
                        </div>

                    </div>
                    <div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Quantity:</strong> {data.qty || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Per Unit Value:</strong> {data.per_unit_value || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Item Rate:</strong> {data.item_rate || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Duty:</strong> {data.duty || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Mode of Transport:</strong> {data.transport_mode || '-'}
                        </div>

                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>UQC:</strong> {data.uqc || '-'}
                        </div>


                    </div>
                    <div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Port of landing:</strong> {data.port_of_landing || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Trade Mode:</strong> {data.trade_mode || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>IGST:</strong> {data.igst_amt || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Invoice No.:</strong> {data.invoice_no || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>FOB:</strong> {data.fob || '-'}
                        </div>

                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Supplier Type:</strong> {data.supplier_type || '-'}
                        </div>


                    </div>

                    <div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>CIF:</strong> {data.cif || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>CVD Amt:</strong> {data.cvd_amt || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>CVD Rate:</strong> {data.cvd_rate || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>BCD Amt:</strong> {data.bcd_amt || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>BCD Rate:</strong> {data.bcd_rate || '-'}
                        </div>
                        <div style={{ marginBottom: '15px' }}>
                            <strong style={{ fontWeight: 'bold' }}>Drawback:</strong> {data.drawback || '-'}
                        </div>

                    </div>

                </div>
            </div>
        );
    };

    return (


        <div className='data-table-main'>

            <div className="AcSimpleTable">

                {/** Delete User popup */}
                <SmallPopup
                    title="Delete Shipment"
                    smallmodalShow={smallmodalShow}
                    setSmallModalShow={setSmallModalShow}
                    onSubmit={handleConfirmDeleteClick}
                    closeButtonLabel="Cancel"
                    okButtonLabel="Delete"
                >
                    <div className='row my-3'>
                        <div className="col-md-12 p-0">Are you sure you want to delete trade record?</div>
                    </div>

                </SmallPopup>

                <MediumPopup
                    title={"Import File"}
                    mediummodalShow={mediummodalShow}
                    setMediumModalShow={setMediumModalShow}
                    // onSubmit={handleSubmit}
                    okButtonLabel={"Import"}
                    closeButtonLabel="Cancel"
                >
                    <Choosefile
                        className={`form-control`}
                        type="file"
                        accept="text/csv"
                        // onChange={handleChange}
                        name={`shipmentcsv`}


                    />
                </MediumPopup>


                <SubHeader layer1="Shipment Management" layer2="Shipments" layer3="List" pageName="Shipments" subTitle="" clickable={{
                    layer1: "",
                    layer2: "",
                    layer3: ""
                }} />

                <div className='card1'>

                    <div className="table-header-name">
                        <h5 className="table-text">List</h5>
                        <div className="d-flex flex-wrap justify-content-between align-items-center p-3">
                            <label className="mx-3">Search</label>
                            <InputField
                                type="text"
                                id="filter-text-box"
                                placeholder="Search..."
                                className="w-25"
                                onChange={onSearchTextBoxChanged}
                                onKeyUp={onSearchTextBoxChanged}
                            ></InputField>

                            {/* <ButtonAdd className='export-btn' type="button" onClick={openImportShipment}>
                                <i className="fa-solid fa-upload"></i> Import
                            </ButtonAdd> */}

                            { /* <ButtonAdd
                                type="button"
                                className="btn-add"
                                onClick={handleAddShipmentClick}
                            >
                                {" "}
                                <i className="fa-solid fa-circle-plus"></i> Add Shipment
                            </ButtonAdd> */}

                        </div>
                    </div>

                    <div className="table-wrapper shipment-wrapper">
                        {/* <div className="table-container" style={{ overflowX: 'auto', width: '100%', maxWidth: '100%' }}> */}
                        {/* {shipmentlist && ( */}
                        <AcSimpleTable
                            key={tableKey}
                            data={shipmentlist}
                            title=""
                            pagination={true}
                            paginationPageSize={limit}
                            columns={columns}
                            progressPending={loading}
                            paginationTotalRows={totalRows}
                            fixedColumnsStart={2}
                            getData={getShipments}
                            offset={offset}
                            limit={limit}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            setLoading={setLoading}
                            paginationPerPage={limit}
                            paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                            striped
                            columnName={columnName}
                            sortDirection={sortDirection}
                            setColumnName={setColumnName}
                            setSortDirection={setSortDirection}
                            searchTxt={searchTxt}
                            sortServer
                            onSort={handleSort}
                            defaultSortAsc={false}
                            setCurrentPage={setCurrentPage}
                            expandableRows
                            expandableRowsComponent={expandedRowComponent}
                        // style={{overfl:'wrap'}}
                        // noDataComponent={<NoData />}
                        />
                        {/* )} */}
                        {/* </div> */}
                    </div>
                </div>
                <ToastContainer />


            </div>

        </div>




    )

}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        ListShipments: (offset, limit, columnName, sortDirection, searchTxt) =>
            dispatch(ListShipments(offset, limit, columnName, sortDirection, searchTxt)),
        deleteShipment: (id) => dispatch(deleteShipment(id)),
        hideLoading: () => dispatch(hideLoading()),
        showLoading: () => dispatch(showLoading())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListShipment);
