import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import { useEffect, useState } from "react";
import InputField from "../InputField";
import defaultValues from "../../constant/defaultValues";
import { connect } from "react-redux";
import { getCountryList } from "../../actions/defaultDataAction";
import errorMessages from "../../constant/errorMessages";
import API_URL from "../../config/apiConfig";
import { setAuthHeaders, showErrorMessage, showSuccessMessage } from "../../utils/CustomNotification";
import { useAuth } from "../../AuthProvider";
import axiosHttp from "../../axiosHttp";
import ButtonCancell from "../ButtonCancell";
import ButtonAdminpanel from "../ButtonAdminpanel";
import { trim } from "lodash";
import { hideLoading, showLoading } from "../../actions/loaderAction";

const LogisticInquiry = (props) => {

    const { user } = useAuth();

    const initialState = {
        company_name: user.company_name,
        contact_person: user.name,
        email: user.email,
        phone: "",
        industry: "",
        country: "",
        product: "",
        quantity: "",
        unit: "",
        message: "",
        country_from: "",
        country_to: "",
        freight_type: "",
        port_of_destination: "",
        port_of_origin: "",
        ocean_type: "",
        lwh: "",
        cbpp: "",
        total_weight: "",
        term: "",
        no_of_containers: "",
        type: "Logistic_Partner",
        commodity: "",
        emailIds: "",
        member_id: user && user.email === "demo@orextrade.com" ? 0 : parseInt(props.member_id)
    };

    const invalidEmails = defaultValues.IGNORE_EMAILS;



    const [formData, setFormData] = useState(initialState);

    const [countryList, setCountryList] = useState([]);
    const [selectedCountryFrom, setSelectedCountryFrom] = useState(null);
    const [selectedCountryTo, setSelectedCountryTo] = useState(null);
    const [selectedFreightType, setSelectedFreightType] = useState(null);
    const [selectedOceanType, setSelectedOceanType] = useState(null);
    const [selectedTerm, setSelectedTerm] = useState(null);
    const [errors, setErrors] = useState({});
    const [isButtonDisabled, setisButtonDisabled] = useState(false);

    const [phoneValue, setphoneValue] = useState("");

    const freightTypeList = defaultValues.FREIGHT_TYPE;
    const oceanTypeList = defaultValues.OCEAN_TYPE;
    const termList = defaultValues.TERM_LIST;




    const handleChange = (e) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleCountryFromChange = (cval) => {
        const countryId = cval.value;
        setSelectedCountryFrom(cval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                country_from: countryId,
            };
        });
    };

    const handleCountryToChange = (cval) => {
        const countryId = cval.value;
        setSelectedCountryTo(cval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                country_to: countryId,
            };
        });
    };

    const handleFreightTypeChange = (cval) => {

        const freightTypeId = cval.value;
        setSelectedFreightType(cval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                freight_type: freightTypeId,
            };
        });
    };

    const handleOceanTypeChange = (cval) => {
        const oceanTypeId = cval.value;
        setSelectedOceanType(cval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                ocean_type: oceanTypeId,
            };
        });

    };

    const handleTermChange = (cval) => {
        const termId = cval.value;
        setSelectedTerm(cval);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                term: termId,
            };
        });
    };

    const handlePhoneChange = (val) => {
        setphoneValue(val);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                phone: val,
            };
        });
    };

    const handleFocusOut = async (e) => {
        let flag = 0;
        let er = { ...errors };

        if (e.target.name === 'company_name') {
            if (e.target.value === "") {
                flag = 1;
                er.company_name = errorMessages.FIELD_REQUIRED;
            } else if (e.target.value.length > 40) {
                flag = 1;
                er.company_name = errorMessages.MAX_LENGTH_EXCEEDED;
            } else {
                const regExp = /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9 .&@]+$/;
                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.company_name = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.company_name = "";
                }
            }
        }

        if (e.target.name === 'contact_person') {
            if (e.target.value === "") {
                flag = 1;
                er.contact_person = errorMessages.FIELD_REQUIRED;
            } else if (e.target.value.length > 40) {
                flag = 1;
                er.contact_person = errorMessages.MAX_LENGTH_EXCEEDED;
            } else {
                const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.contact_person = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.contact_person = "";
                }
            }
        }

        if (e.target.name === 'email') {
            if (e.target.value === "") {
                flag = 1;
                er.email = errorMessages.FIELD_REQUIRED;
            } else if (e.target.value.length > 40) {
                flag = 1;
                er.email = errorMessages.MAX_LENGTH_EXCEEDED;
            } else {
                const currentEmail = e.target.value.split("@")[1];
                const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.email = errorMessages.IN_VALID_FIELD_FORMAT;
                } else if (invalidEmails.includes(currentEmail)) {
                    flag = 1;
                    er.email = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.email = "";
                }
            }
        }

        if (e.target.name === "phone") {
            let phoneValue = e.target.value.trim();
            let numberWithoutCountryCode = phoneValue.replace(/^\+(\d{1,4})\s?/, "").replace(/\s/g, "");
            if (numberWithoutCountryCode === '+') {
                flag = 0;
                er.phone = "";
            }
            if (phoneValue !== '' && phoneValue.length > 4 && numberWithoutCountryCode > 0) {
                if (!isValidPhoneNumber(phoneValue)) {
                    flag = 1;
                    er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
                } else {
                    flag = 0;
                    er.phone = "";
                }
            }
        }

        if (e.target.name === 'country_from') {
            if (e.target.value === '') {
                flag = 1;
                er.country_from = errorMessages.FIELD_REQUIRED;
            } else {
                flag = 0;
                er.country_from = "";
            }
        }

        if (e.target.name === 'country_to') {
            if (e.target.value === '') {
                flag = 1;
                er.country_to = errorMessages.FIELD_REQUIRED;
            } else {
                flag = 0;
                er.country_to = "";
            }
        }



        if (e.target.name === 'port_of_origin') {
            if (e.target.value === "") {
                flag = 1;
                er.port_of_origin = errorMessages.FIELD_REQUIRED;
            } else if (e.target.value.length > 40) {
                flag = 1;
                er.port_of_origin = errorMessages.MAX_LENGTH_EXCEEDED;
            } else {
                const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.port_of_origin = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.port_of_origin = "";
                }
            }

        }

        if (e.target.name === 'port_of_destination') {
            if (e.target.value === "") {
                flag = 1;
                er.port_of_destination = errorMessages.FIELD_REQUIRED;
            } else if (e.target.value.length > 40) {
                flag = 1;
                er.port_of_destination = errorMessages.MAX_LENGTH_EXCEEDED;
            } else {
                const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.port_of_destination = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.port_of_destination = "";
                }
            }

        }


        if (e.target.name === 'ocean_type') {
            if (e.target.value === '') {
                flag = 1;
                er.ocean_type = errorMessages.FIELD_REQUIRED;
            } else {
                flag = 0;
                er.ocean_type = "";
            }
        }


        if (e.target.name === 'no_of_containers') {
            let no = e.target.value;
            let noReg = /^(?!0(\0+)?$)(\d+(\d+)?|\d+)$/;
            if (e.target.value === '') {
                flag = 1;
                er.no_of_containers = errorMessages.FIELD_REQUIRED;
            } else if (e.target.value !== '' && no.length > 0) {
                const check = noReg.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.no_of_containers = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.no_of_containers = '';
                }
            }
        }

        if (e.target.name === 'lwh') {
            let no = e.target.value;
            let noReg = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
            if (e.target.value === '') {
                flag = 1;
                er.lwh = errorMessages.FIELD_REQUIRED;
            } else if (e.target.value !== '' && no.length > 0) {
                const check = noReg.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.lwh = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.lwh = '';
                }
            }
        }

        if (e.target.name === 'commodity') {
            if (e.target.value === "") {
                flag = 1;
                er.commodity = errorMessages.FIELD_REQUIRED;
            } else if (e.target.value.length > 40) {
                flag = 1;
                er.commodity = errorMessages.MAX_LENGTH_EXCEEDED;
            } else {
                const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

                const check = regExp.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.commodity = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.commodity = "";
                }
            }

        }


        if (e.target.name === 'cbpp') {
            let no = e.target.value;
            let noReg = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
            if (e.target.value === '') {
                flag = 1;
                er.cbpp = errorMessages.FIELD_REQUIRED;
            } else if (e.target.value !== '' && no.length > 0) {
                const check = noReg.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.cbpp = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.cbpp = '';
                }
            }
        }

        if (e.target.name === 'total_weight') {
            let no = e.target.value;
            let noReg = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
            if (e.target.value !== '' && no.length > 0) {
                const check = noReg.test(e.target.value);
                if (check === false) {
                    flag = 1;
                    er.total_weight = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.total_weight = '';
                }
            }
        }


        if (e.target.name === 'term') {
            if (e.target.value === '') {
                flag = 1;
                er.term = errorMessages.FIELD_REQUIRED;
            } else {
                flag = 0;
                er.term = "";
            }
        }

        if (e.target.name === "emailIds") {
            if (e.target.value === '') {
                flag = 1;
                er.emailIds = errorMessages.FIELD_REQUIRED;
            } else {
                let fEmails = e.target.value;
                fEmails = fEmails.split(",");
                if (fEmails.length > 0) {
                    for (let fe of fEmails) {
                        const currentEmail = fe.split("@")[1];
                        const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        const check = regExp.test(fe);
                        if (check === false) {
                            flag = 1;
                            er.emailIds = errorMessages.IN_VALID_FIELD_FORMAT;
                        } else if (invalidEmails.includes(currentEmail)) {
                            flag = 1;
                            er.emailIds = errorMessages.IN_VALID_FIELD_FORMAT;
                        } else {
                            flag = 0;
                            er.emailIds = "";
                        }
                    }
                }

            }
        }

        setErrors(er);
        if (flag === 0) {
            setisButtonDisabled(false);
            return false;
        } else {

            return true;
        }

    };

    const validation = () => {
        let er = { ...errors };
        let flag = 0;
        let isValid = true;
        if (!formData.company_name || formData.company_name === "") {
            flag = 1;
            isValid = false;
            er.company_name = errorMessages.FIELD_REQUIRED;
        } else if (formData.company_name.length > 40) {
            flag = 1;
            isValid = false;
            er.company_name = errorMessages.MAX_LENGTH_EXCEEDED;
        } else {
            const regExp = /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9 .&@]+$/;
            const check = regExp.test(formData.company_name);
            if (check === false) {
                isValid = false;
                flag = 1;
                er.company_name = errorMessages.IN_VALID_FIELD_FORMAT;
            } else {
                flag = 0;
                er.company_name = "";
            }
        }


        if (formData.contact_person === "") {
            isValid = false;
            flag = 1;
            er.contact_person = errorMessages.FIELD_REQUIRED;
        } else if (formData.contact_person.length > 40) {
            isValid = false;
            flag = 1;
            er.contact_person = errorMessages.MAX_LENGTH_EXCEEDED;
        } else {
            const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

            const check = regExp.test(formData.contact_person);
            if (check === false) {
                isValid = false;
                flag = 1;
                er.contact_person = errorMessages.IN_VALID_FIELD_FORMAT;
            } else {
                flag = 0;
                er.contact_person = "";
            }
        }

        if (formData.email === "") {
            isValid = false;
            flag = 1;
            er.email = errorMessages.FIELD_REQUIRED;
        } else if (formData.email.length > 40) {
            isValid = false;
            flag = 1;
            er.email = errorMessages.MAX_LENGTH_EXCEEDED;
        } else {
            const currentEmail = formData.email.split("@")[1];
            const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const check = regExp.test(formData.email);
            if (check === false) {
                isValid = false;
                flag = 1;
                er.email = errorMessages.IN_VALID_FIELD_FORMAT;
            } else if (invalidEmails.includes(currentEmail)) {
                isValid = false;
                flag = 1;
                er.email = errorMessages.IN_VALID_FIELD_FORMAT;
            } else {
                flag = 0;
                er.email = "";
            }
        }


        if (phoneValue) {
            let numberWithoutCountryCode = phoneValue.replace(/^\+(\d{1,4})\s?/, '');
            numberWithoutCountryCode = numberWithoutCountryCode.replace(/\s/g, '');
            if (!phoneValue || !isValidPhoneNumber(phoneValue) && numberWithoutCountryCode.length > 0) {
                flag = 1;
                isValid = false;
                er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
            }
        }



        if (formData.country_from === '') {
            isValid = false;
            flag = 1;
            er.country_from = errorMessages.FIELD_REQUIRED;
        } else {
            flag = 0;
            er.country_from = "";
        }

        if (formData.country_to === '') {
            isValid = false;
            flag = 1;
            er.country_to = errorMessages.FIELD_REQUIRED;
        } else {
            flag = 0;
            er.country_to = "";
        }


        if (formData.port_of_origin === "") {
            isValid = false;
            flag = 1;
            er.port_of_origin = errorMessages.FIELD_REQUIRED;
        } else if (formData.port_of_origin.length > 40) {
            isValid = false;
            flag = 1;
            er.port_of_origin = errorMessages.MAX_LENGTH_EXCEEDED;
        } else {

            const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

            const check = regExp.test(formData.port_of_origin);
            if (check === false) {
                isValid = false;
                flag = 1;
                er.port_of_origin = errorMessages.IN_VALID_FIELD_FORMAT;
            } else {
                flag = 0;
                er.port_of_origin = "";
            }
        }



        if (formData.port_of_destination === "") {
            isValid = false;
            flag = 1;
            er.port_of_destination = errorMessages.FIELD_REQUIRED;
        } else if (formData.port_of_destination.length > 40) {
            isValid = false;
            flag = 1;
            er.port_of_destination = errorMessages.MAX_LENGTH_EXCEEDED;
        } else {
            const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

            const check = regExp.test(formData.port_of_destination);
            if (check === false) {
                isValid = false;
                flag = 1;
                er.port_of_destination = errorMessages.IN_VALID_FIELD_FORMAT;
            } else {
                flag = 0;
                er.port_of_destination = "";
            }
        }


        if (formData.freight_type === '') {
            isValid = false;
            flag = 1;
            er.freight_type = errorMessages.FIELD_REQUIRED;
        } else {
            flag = 0;
            er.freight_type = "";
        }

        if (formData.freight_type === '' || formData.freight_type === 'Ocean') {
            if (formData.ocean_type === '') {
                isValid = false;
                flag = 1;
                er.ocean_type = errorMessages.FIELD_REQUIRED;
            } else {
                flag = 0;
                er.ocean_type = "";
            }



            let no1 = formData.no_of_containers;
            let noReg1 = /^(?!0(\0+)?$)(\d+(\d+)?|\d+)$/;
            if (no1 === '') {
                isValid = false;
                flag = 1;
                er.no_of_containers = errorMessages.FIELD_REQUIRED;
            } else if (no1 !== '' && no1.length > 0) {
                const check = noReg1.test(no1);
                if (check === false) {
                    isValid = false;
                    flag = 1;
                    er.no_of_containers = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.no_of_containers = '';
                }
            }
        }

        if (formData.freight_type === '' || formData.freight_type === 'Air') {

            let no = formData.lwh;
            let noReg = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
            if (no === '') {
                isValid = false;
                flag = 1;
                er.lwh = errorMessages.FIELD_REQUIRED;
            } else if (no !== '' && no.length > 0) {
                const check = noReg.test(no);
                if (check === false) {
                    isValid = false;
                    flag = 1;
                    er.lwh = errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    flag = 0;
                    er.lwh = '';
                }
            }
        }

        if (!formData.commodity || formData.commodity === "") {
            isValid = false;
            flag = 1;
            er.commodity = errorMessages.FIELD_REQUIRED;
        } else if (formData.commodity.length > 40) {
            isValid = false;
            flag = 1;
            er.commodity = errorMessages.MAX_LENGTH_EXCEEDED;
        } else {
            const regExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;

            const check = regExp.test(formData.commodity);
            if (check === false) {
                isValid = false;
                flag = 1;
                er.commodity = errorMessages.IN_VALID_FIELD_FORMAT;
            } else {
                flag = 0;
                er.commodity = "";
            }
        }

        let no2 = formData.cbpp;
        let noReg2 = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
        if (no2 === '') {
            isValid = false;
            flag = 1;
            er.cbpp = errorMessages.FIELD_REQUIRED;
        } else if (no2 !== '' && no2.length > 0) {
            const check = noReg2.test(no2);
            if (check === false) {
                isValid = false;
                flag = 1;
                er.cbpp = errorMessages.IN_VALID_FIELD_FORMAT;
            } else {
                flag = 0;
                er.cbpp = '';
            }
        }


        let no3 = formData.total_weight;
        let noReg3 = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s\-\'.\(\)&]+(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
        if (no3 !== '' && no3.length > 0) {
            const check = noReg3.test(no3);
            if (check === false) {
                isValid = false;
                flag = 1;
                er.total_weight = errorMessages.IN_VALID_FIELD_FORMAT;
            } else {
                flag = 0;
                er.total_weight = '';
            }
        }



        if (formData.term === '') {
            isValid = false;
            flag = 1;
            er.term = errorMessages.FIELD_REQUIRED;
        } else {
            flag = 0;
            er.term = "";
        }

        if (user && user.email === "demo@orextrade.com") {
            if (formData.emailIds === '') {
                flag = 1;
                isValid = false;
                er.emailIds = errorMessages.FIELD_REQUIRED;
            } else {
                let fEmails = formData.emailIds.split(",");
                if (fEmails.length > 0) {
                    for (let fe of fEmails) {
                        let fe1 = trim(fe);
                        const currentEmail = fe1.split("@")[1];
                        const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        const check = regExp.test(fe1);
                        if (check === false) {
                            flag = 1;
                            isValid = false;
                            er.emailIds = errorMessages.IN_VALID_FIELD_FORMAT;
                        } else if (invalidEmails.includes(currentEmail)) {
                            flag = 1;

                            isValid = false;
                            er.emailIds = errorMessages.IN_VALID_FIELD_FORMAT;
                        } else {
                            flag = 0;
                            er.emailIds = "";
                        }
                    }
                }
            }
        }


        setErrors(er);
        if (!isValid) {
            return false;
        }

        return true;


    };

    const handleLogisticInquirySubmit = async (e) => {
        e.preventDefault();
        const headers = setAuthHeaders();

        if (validation()) {
            await props.showLoading();
            try {
                setisButtonDisabled(true);
                await axiosHttp
                    .post(API_URL + "/post-inquiry", formData, {
                        headers,
                        timeout: 10000,
                    })
                    .then((response) => {
                        props.hideLoading();
                        if (response.status === 200) {
                            if (response.data.status == true) {

                                showSuccessMessage("Inquiry Posted Successfully!");
                                setTimeout(() => {
                                    setFormData(initialState);
                                    setErrors({});
                                    setphoneValue("");
                                    setSelectedCountryFrom(null);
                                    setSelectedCountryTo(null);
                                    setSelectedFreightType(null);
                                    setSelectedOceanType(null);
                                    setSelectedTerm(null);
                                    setisButtonDisabled(false);
                                    props.setMediumModalShow(false);
                                }, 1000);

                            } else {
                                setisButtonDisabled(false);
                                setErrors([]);
                                const errors = {};
                                if (response.data.errors.company_name) {
                                    errors.company_name = response.data.errors.company_name;
                                }
                                if (response.data.errors.contact_person) {
                                    errors.contact_person = response.data.errors.contact_person;
                                }
                                if (response.data.errors.email) {
                                    errors.email = response.data.errors.email;
                                }
                                if (response.data.errors.cbpp) {
                                    errors.cbpp = response.data.errors.cbpp;
                                }
                                if (response.data.errors.country_from) {
                                    errors.country_from = response.data.errors.country_from;
                                }
                                if (response.data.errors.country_to) {
                                    errors.country_to = response.data.errors.country_to;
                                }

                                if (response.data.errors.freight_type) {
                                    errors.freight_type = response.data.errors.freight_type;
                                }
                                if (response.data.errors.port_of_destination) {
                                    errors.port_of_destination = response.data.errors.port_of_destination;
                                }
                                if (response.data.errors.port_of_origin) {
                                    errors.port_of_origin = response.data.errors.port_of_origin;
                                }
                                if (response.data.errors.commodity) {
                                    errors.commodity = response.data.errors.commodity;
                                }
                                if (response.data.errors.term) {
                                    errors.term = response.data.errors.term;
                                }

                                if (response.data.errors.lwh) {
                                    errors.lwh = response.data.errors.lwh;
                                }

                                if (response.data.errors.ocean_type) {
                                    errors.ocean_type = response.data.errors.ocean_type;
                                }
                                if (response.data.errors.no_of_containers) {
                                    errors.no_of_containers = response.data.errors.no_of_containers;
                                }

                                setErrors(errors);
                                return Object.keys(errors).length > 0;
                            }
                        }

                    }).catch((err) => {
                        props.hideLoading();
                        setisButtonDisabled(false);
                        console.log("Eror =============", err);
                        showErrorMessage("Something Went Wrong!");
                    })
            } catch (err) {
                props.hideLoading();
                console.log("Eror =============", err);
                showErrorMessage("Something Went Wrong!");
            }
        }
    }

    const handleCancelClick = (e) => {
        e.preventDefault();
        props.setMediumModalShow(false);
    }

    const fetchCountries = async () => {
        await props.getCountryList().then((data) => {
            const options = data.map((country) => ({
                value: country.name,
                label: country.name,
                countryCode: country.iso2,
            }));
            setCountryList(options);
        }).catch((err) => {
            console.log("Error >>>> ", err);
        })
    }

    useEffect(() => {
        fetchCountries();
    }, []);

    const styleError = {
        display: "block",
    };


    return (<>
        <form onSubmit={handleLogisticInquirySubmit}>
            <div className="row">
                <div className="col-sm-6 ">
                    <div className="input-group">
                        <label className="form-label required">Company Name</label>
                        <div className="input-pad">
                            <InputField name="company_name"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Enter company name"
                                onChange={handleChange}
                                value={formData.company_name}
                                onBlur={handleFocusOut}
                                readOnly={true}
                            />

                            {errors.company_name && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.company_name}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 ">
                    <div className="input-group">
                        <label className="form-label required">Contact Person</label>
                        <div className="input-pad">
                            <InputField
                                type="text"
                                name="contact_person"
                                value={formData.contact_person}
                                // className="form-control"
                                autoComplete="off"
                                placeholder="Enter contact person"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                                readOnly={true}
                            />
                            {errors.contact_person && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.contact_person}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 ">
                    <div className="input-group">
                        <label className="form-label required">Email</label>
                        <div className="input-pad">
                            <InputField
                                type="text"
                                name="email"
                                //className="form-control"
                                value={formData.email}
                                autoComplete="off"
                                placeholder="Enter email"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                                readOnly={true}
                            />
                            {errors.email && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.email}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 ">
                    <div className="input-wrape">
                        <label className="form-label">
                            Phone Number
                        </label>
                        <div className="input-pad">
                            <PhoneInput
                                id="contact-form-phone"
                                className="form-control inquiry-phone inp-font-size border-dropdown company-representive-border outer_pages_phone_input"
                                name="phone"
                                international
                                limitMaxLength
                                placeholder="Enter phone number"
                                autoComplete="off"
                                value={phoneValue}
                                onChange={handlePhoneChange}
                                onBlur={handleFocusOut}

                            />
                            {errors.phone && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.phone}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 ">
                    <div className="input-group">
                        <label className="form-label required">Country From</label>
                        <div className="input-pad">
                            <Select
                                name="country_from"
                                options={countryList}
                                value={selectedCountryFrom}
                                onChange={handleCountryFromChange}
                                placeholder="Select country"
                                className="dd-pad"
                                onBlur={handleFocusOut}
                                noOptionsMessage={() => "No Country Found"}
                            />
                            {errors.country_from && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.country_from}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 ">
                    <div className="input-group">
                        <label className="form-label required">Port of origin</label>
                        <div className="input-pad">
                            <InputField
                                type="text"
                                name="port_of_origin"
                                value={formData.port_of_origin}
                                // className="form-control"
                                autoComplete="off"
                                placeholder="Enter port of origin"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                            />
                            {errors.port_of_origin && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.port_of_origin}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 ">
                    <div className="input-group">
                        <label className="form-label required">Country To</label>
                        <div className="input-pad">
                            <Select
                                name="country_to"
                                options={countryList}
                                value={selectedCountryTo}
                                onChange={handleCountryToChange}
                                placeholder="Select country"
                                className="dd-pad"
                                noOptionsMessage={() => "No Country Found"}
                                onBlur={handleFocusOut}
                            />
                            {errors.country_to && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.country_to}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 ">
                    <div className="input-group">
                        <label className="form-label required">Port of destination</label>
                        <div className="input-pad">
                            <InputField
                                type="text"
                                name="port_of_destination"
                                value={formData.port_of_destination}
                                // className="form-control"
                                autoComplete="off"
                                placeholder="Enter port of destination"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                            />
                            {errors.port_of_destination && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.port_of_destination}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className={selectedFreightType && selectedFreightType.value == "Ocean" ? `col-sm-4` : `col-sm-6 `}>
                    <div className="input-group">
                        <label className="form-label required">Freight type</label>
                        <div className="input-pad">
                            <Select
                                name="freight_type"
                                options={freightTypeList}
                                value={selectedFreightType}
                                onChange={handleFreightTypeChange}
                                placeholder="Select freight type"
                                className="dd-pad"
                                noOptionsMessage={() => "No Freight Type Found"}
                                onBlur={handleFocusOut}
                            />
                            {errors.freight_type && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.freight_type}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                {selectedFreightType && selectedFreightType.value === 'Ocean' ? (
                    <>
                        <div className="col-sm-4 ">
                            <div className="input-group">
                                <label className="form-label required">Ocean Type</label>
                                <div className="input-pad">
                                    <Select
                                        name="ocean_type"
                                        options={oceanTypeList}
                                        value={selectedOceanType}
                                        onChange={handleOceanTypeChange}
                                        placeholder="Select ocean type"
                                        className="dd-pad"
                                        noOptionsMessage={() => "No Ocean Type Found"}
                                        onBlur={handleFocusOut}
                                    />
                                    {errors.ocean_type && (
                                        <span
                                            className="company-required"
                                            style={styleError}
                                        >
                                            {errors.ocean_type}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="input-group">
                                <label className="form-label required">No. of Containers</label>
                                <div className="input-pad">
                                    <InputField
                                        type="text"
                                        name="no_of_containers"
                                        value={formData.no_of_containers}
                                        // className="form-control"
                                        autoComplete="off"
                                        placeholder="No. of Containers"
                                        onChange={handleChange}
                                        onBlur={handleFocusOut}
                                    />
                                    {errors.no_of_containers && (
                                        <span
                                            className="company-required"
                                            style={styleError}
                                        >
                                            {errors.no_of_containers}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="col-sm-6 ">
                        <div className="input-group">
                            <label className="form-label required">Length/Width/Height (CM)</label>
                            <div className="input-pad">
                                <InputField
                                    type="text"
                                    name="lwh"
                                    value={formData.lwh}
                                    // className="form-control"
                                    autoComplete="off"
                                    placeholder="Enter Length/Width/Height (CM)"
                                    onChange={handleChange}
                                    onBlur={handleFocusOut}
                                />
                                {errors.lwh && (
                                    <span
                                        className="company-required"
                                        style={styleError}
                                    >
                                        {errors.lwh}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <div className="col-sm-6 ">
                    <div className="input-group">
                        <label className="form-label required">Commodity</label>
                        <div className="input-pad">
                            <InputField
                                type="text"
                                name="commodity"
                                value={formData.commodity}
                                // className="form-control"
                                autoComplete="off"
                                placeholder="Enter commodity"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                            />
                            {errors.commodity && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.commodity}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 ">
                    <div className="input-group">
                        <label className="form-label required">Containers Type/Boxes/Pallat/Packages</label>
                        <div className="input-pad">
                            <InputField
                                type="text"
                                name="cbpp"
                                value={formData.cbpp}
                                // className="form-control"
                                autoComplete="off"
                                placeholder="Enter Containers Type/Boxes/Pallat/Packages"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                            />
                            {errors.cbpp && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.cbpp}
                                </span>
                            )}
                        </div>
                    </div>
                </div>


                <div className="col-sm-6 ">
                    <div className="input-group">
                        <label className="form-label">Total Weight (KG)</label>
                        <div className="input-pad">
                            <InputField
                                type="text"
                                name="total_weight"
                                value={formData.total_weight}
                                // className="form-control"
                                autoComplete="off"
                                placeholder="Enter Total Weight (KG)"
                                onChange={handleChange}
                                onBlur={handleFocusOut}
                            />

                            {errors.total_weight && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.total_weight}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 ">
                    <div className="input-group">
                        <label className="form-label required">Term</label>
                        <div className="input-pad">

                            <Select
                                name="term"
                                options={termList}
                                value={selectedTerm}
                                onChange={handleTermChange}
                                placeholder="Select ocean type"
                                className="dd-pad"
                                noOptionsMessage={() => "No Term Found"}
                                onBlur={handleFocusOut}
                            />

                            {errors.term && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.term}
                                </span>
                            )}

                        </div>
                    </div>
                </div>

                <div className="col-sm-12 ">
                    <hr />
                </div>
                <div className="col-12">
                    <div className="input-wrape">
                        <label
                            className="form-label inquiry-text"
                            for="contact-form-message"
                        >
                            Remarks
                        </label>
                        <div className="input-pad">
                            <textarea
                                id="contact-form-message"
                                className="form-control"
                                rows="3"
                                placeholder="Remarks...."
                                name="message"
                                value={formData.message}
                                onChange={handleChange}

                            ></textarea>
                            {errors.message && (
                                <span
                                    className="company-required"
                                    style={styleError}
                                >
                                    {errors.message}
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                {
                    user && user.email === "demo@orextrade.com" && (<><div className="col-md-12 ">
                        <label className="form-label required">Recipient Email</label> <InputField name="emailIds" type="text" value={formData.emailIds} onChange={handleChange} onBlur={handleFocusOut} />
                        <em>(Multiple emails can be added using comma separated)</em>
                    </div>{errors.emailIds && (
                        <span
                            className="company-required"
                            style={styleError}
                        >
                            {errors.emailIds}
                        </span>
                    )}</>)
                }


                <div className="col-md-12 inquiry-form-btn ">
                    {
                        props.pageView === "pop_up" &&
                        <ButtonCancell
                            className="inq-form-btn"
                            type="button"
                            // disabled={isButtonDisabled}
                            onClick={handleCancelClick}
                        > Cancel
                        </ButtonCancell>
                    }
                    <ButtonAdminpanel type="submit"
                        className="inq-form-btn"
                        disabled={isButtonDisabled}>
                        Send Inquiry
                    </ButtonAdminpanel>


                </div>

            </div>
        </form>
    </>)
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCountryList: () => dispatch(getCountryList()),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogisticInquiry); 