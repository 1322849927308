import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import { object } from "@amcharts/amcharts5";
import TermsOfCondition from "./TermsOfCondition";
import { Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import TermsOfConditionsLogistic from "./TermsOfConditionsLogistic";
import TermsOfConditionsBuyer from "./TermsOfConditionsBuyer";
import TermsOfConditionsSupplier from "./TermsOfConditionsSupplier";

function TermsOfService() {
  const navigate = useNavigate();
  const location = useLocation();

  const [pageTitle, setpageTitle] = useState("Terms And Conditions");

  // Mapping URL paths to tab keys
  const tabMap = {
    "/terms-and-conditions": "1",
    "/terms-and-conditions-supplier": "2",
    "/terms-and-conditions-buyer": "3",
    "/terms-and-conditions-logistic": "4",
  };

  // Reverse map for setting URL from tab selection

  const reverseTabMap = {
    1: "/terms-and-conditions",
    2: "/terms-and-conditions-supplier",
    3: "/terms-and-conditions-buyer",
    4: "/terms-and-conditions-logistic",
  };

  const activeKey = tabMap[location.pathname] || "1";

  const handleSelect = (key) => {
    navigate(reverseTabMap[key]); // Update the browser URL
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {

    if (location.pathname === "/terms-and-conditions") {
      setpageTitle("Terms And Conditions");
    }

    else if (location.pathname === "/terms-and-conditions-supplier") {
      setpageTitle("Supplier Terms and Conditions");
    }

    else if (location.pathname === "/terms-and-conditions-buyer") {
      setpageTitle("Buyer Terms and Conditions");
    }

    else if (location.pathname === "/terms-and-conditions-logistic") {
      setpageTitle("Logistic Partner Terms and Conditions");
    }

  }, [location.pathname]);

  return (
    <div className="terms-and-condition-wraper">
      <section className="inner-pad page contact-us-wrape">
        <section className="inner-banner-wrape global-advertising terms-condition banner-wrape">
          <div className="inner"> {pageTitle}</div>
          <span class="sprite decoration"></span>
        </section>
      </section>

      <div className=" trem-of-service-wraper container">
        <Tabs
          defaultActiveKey={1}
          activeKey={activeKey}
          onSelect={handleSelect}
          id="uncontrolled-tab-example"
          className="term-of-tab-wraper"
        >
          <Tab eventKey={1} title="Terms and Conditions">
            <TermsOfCondition />
          </Tab>

          <Tab eventKey={2} title="Terms and Conditions Supplier">
            <TermsOfConditionsSupplier />
          </Tab>

          <Tab eventKey={3} title="Terms and Conditions Buyer">
            <TermsOfConditionsBuyer />
          </Tab>

          <Tab eventKey={4} title="Terms and Conditions logistic">
            <TermsOfConditionsLogistic />
          </Tab>
        </Tabs>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfService;
