import { isEmpty, trim } from "lodash";
import API_URL from "../config/apiConfig";
import axios from "axios";
import axiosInstance from '../axiosHttp';
import { setAuthHeaders } from '../utils/CustomNotification';

export const getQuantityUnitList = () => async (dispatch) => {

  return await axios.get(API_URL + "/quantity-units")
    .then((response) => {
      if (response.status === 200 && response.data.status) {
        dispatch({
          type: "LIST_QUANTITY",
          payload: response.data.data
        });
        return response.data.data;
      }


    })
    .catch((error) => {
      dispatch({
        type: "LIST_QUANTITY",
        payload: error
      });
    });
}

export const getHSCodeList = () => async (dispatch) => {

  return await axios.get(API_URL + "/hs-codes")
    .then((response) => {
      if (response.status === 200 && response.data.status) {
        dispatch({
          type: "LIST_HSCODES",
          payload: response.data.data
        });
        return response.data.data;
      }


    })
    .catch((error) => {
      dispatch({
        type: "LIST_HSCODES",
        payload: error
      });
    });
}

export const getHSCodeSearchList = (search) => async (dispatch) => {
  // console.log("dsvfffffffff", search);
  return await axios.post(API_URL + '/hs-codes/hsCodesearch', {
    search_txt: search,
  }).then((response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        return response.data.data;
      }
    }
    return [];
  }).catch((error) => {
    console.log("ERROR =====", error);
  });

}

export const getSelectedHSCodeListByIdList = (ids) => async (dispatch) => {

  return await axios.post(API_URL + '/hs-codes/hsCodeById', {
    search_txt: ids,
  }).then((response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        return response.data.data;
      }
    }
    return [];
  }).catch((error) => {
    console.log("ERROR =====", error);
  });

}

export const getCountryList = () => async (dispatch) => {
  return await axios.get(API_URL + "/countries")
    .then((response) => {
      if (response.status === 200 && response.data.status) {
        dispatch({
          type: "LIST_COUNTRIES",
          payload: response.data.data
        });
        return response.data.data;
      }
      return [];


    })
    .catch((error) => {
      dispatch({
        type: "LIST_COUNTRIES",
        payload: error
      });
    });
}

export const getCityList = (countryId) => async (dispatch) => {
  return await axios.get(API_URL + '/cities/' + countryId).then((response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        // dispatch({
        //   type: "LIST_COUNTRIES",
        //   payload: response.data.data
        // });
        return response.data.data;
      }
    }
    return [];
  }).catch((err) => {
    console.log("Error ============= ", err);
  });
}

export const getCityListBySerachVal = (searchTxt, countryId) => async (dispatch) => {

  return await axios.post(API_URL + '/cities/search', {
    search_txt: searchTxt,
    country_id: countryId
  }).then((response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        return response.data.data;
      }
    }
    return [];
  });
}

export const getPortsByCountry = (countryId) => async (dispatch) => {

  return await axios.get(API_URL + '/ports/' + countryId).then((response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        // dispatch({
        //   type: "LIST_COUNTRIES",
        //   payload: response.data.data
        // });
        return response.data.data;
      }
    }
    return [];
  }).catch((err) => {
    console.log("Error ============= ", err);
  });
}

export const getServices = () => async () => {
  return await axios.get(API_URL + '/services').then((response) => {

    if (response.status === 200) {
      // if (response.data.status === true) {
      // console.log("Response ========== ", response.data.data);
      return response.data.data;
      // }
    }
    return [];
  }).catch((err) => {
    console.log("Error ============= ", err);
  });
}

export const getHScodeSuggetions = (offset, limit, searchTxt) => async () => {

  searchTxt = trim(searchTxt);
  const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
  return await axios.get(API_URL + `/hs-codes-suggetions/${offset}/${limit}/code/ASC${searchQueryString}`).then((response) => {
    if (response.status === 200) {
      // if (response.data.status === true) {
      // console.log("Response ========== ", response.data.data);
      return response.data.data;
      // }
    }
    return [];
  }).catch((err) => {
    console.log("Error ============= ", err);
  });
}

export const getServicesList = (offset, limit, searchTxt) => async () => {

  searchTxt = trim(searchTxt);
  const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
  return await axios.get(API_URL + `/servcies-list/${offset}/${limit}${searchQueryString}`).then((response) => {
    if (response.status === 200) {
      // if (response.data.status === true) {
      // console.log("Response ========== ", response.data.data);
      return response.data.data;
      // }
    }
    return [];
  }).catch((err) => {
    console.log("Error ============= ", err);
  });
}

export const getServiceByIndustry = (industry_id) => async () => {

  return await axios.get(API_URL + `/industry-services/${industry_id}`).then((response) => {

    if (response.status === 200) {
      // if (response.data.status === true) {
      // console.log("Response ========== ", response.data.data);
      return response.data.data;
      // }
    }
    return [];
  }).catch((err) => {
    console.log("Error ============= ", err);
  });
}

export const getIndustryByTradeMode = (trade_mode) => async () => {
  return await axios.get(API_URL + `/trading-industries/${trade_mode}`).then((response) => {
    if (response.status === 200) {
      // if (response.data.status === true) {
      // console.log("Response ========== ", response.data);
      return response.data;
      // }
    }
    return [];
  }).catch((err) => {
    console.log("Error ============= ", err);
  });
}


export const getHSCodeFilterMemberPieChart = () => async (dispatch) => {
  const headers = setAuthHeaders();
  return await axiosInstance.get(API_URL + "/pieChartData", { headers })
    .then((response) => {
      if (response.status === 200 && response.data.status) {
        dispatch({
          type: "PIE_CHART_HSCODES",
          payload: response.data.data
        });
        return response.data.data;
      }
    })
    .catch((error) => {
      dispatch({
        type: "PIE_CHART_HSCODES",
        payload: error
      });
    });
}

export const getTargetCountryMapChartData = () => async (dispatch) => {
  const headers = setAuthHeaders();
  return await axiosInstance.get(API_URL + "/countryChart", { headers })
    .then((response) => {
      if (response.status === 200 && response.data.status) {
        dispatch({
          type: "MAP_CHART_TARGET_COUNTRY",
          payload: response.data.data
        });
        return response.data.data;
      }
    })
    .catch((error) => {
      dispatch({
        type: "MAP_CHART_TARGET_COUNTRY",
        payload: error
      });
    });
}

export const getTradeHScodeSuggetions = (offset, limit, searchTxt) => async () => {

  searchTxt = trim(searchTxt);
  const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
  return await axios.get(API_URL + `/trade-hs-codes-suggetions/${offset}/${limit}/hs_code/ASC${searchQueryString}`).then((response) => {
    if (response.status === 200) {
      return response.data.data;
      // }
    }
    return [];
  }).catch((err) => {
    console.log("Error ============= ", err);
  });
}