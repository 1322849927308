import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../../Footer";
import GuaranteeBusinessIMG from "../../imgs/inner-guarantee-business.jpg";

function GuaranteeProspectiveBusiness() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="benefits guarantee-prospective-business-wrape page  para-head-text-align">
      {/* <Header /> */}
      <section className="inner-banner-wrape guarantee-prospective-business banner-wrape">
        <div className="inner"> Guarantee to Get Prospective Business</div>
        <span class="sprite decoration"></span>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="img-wrape">
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Guarantee to Get <span>Prospective Business</span>
                  </h2>
                </div>

                <p className="text">Connecting You with the Right Partners</p>
              </div>
              <img src={GuaranteeBusinessIMG} alt="" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="content-wrape">
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Guarantee to Get <span>Prospective Business</span>
                  </h2>
                </div>

                <p className="text">Connecting You with the Right Partners</p>
              </div>

              <p>
                At Orex Trade World INC, we use a targeted matchmaking process
                combined with data-driven insights to guarantee that your
                business will connect with potential partners actively seeking
                products or services like yours. This ensures that you are
                always engaging with high-quality, prospective business leads
                that are aligned with your offerings and goals.
              </p>
              <p>
                Our matchmaking system is designed to identify the right
                partners based on their needs, business activity and market
                trends. By leveraging our extensive network and advanced data
                analytics, we filter out irrelevant contacts and focus on those
                who are most likely to form successful partnerships. This means
                you don’t waste time or resources on unqualified leads and
                instead you can focus on building relationships with genuine
                prospects.
              </p>
              <p>
                With our service, you gain access to a pipeline of businesses
                that are ready to collaborate, negotiate and create valuable
                deals. Whether you are looking for reliable buyers, suppliers
                or strategic partners, we ensure you are always working with
                businesses that have a clear interest in your offerings.
              </p>
              <p>
                Our <b>“Guarantee to Get Prospective Business”</b> feature
                provides you with a consistent stream of qualified leads,
                allowing you to focus on developing meaningful and profitable
                partnerships that will help grow your business.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default GuaranteeProspectiveBusiness;
