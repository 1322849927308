import React, { useEffect, useRef, useState } from 'react'
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { isEmpty, size } from 'lodash';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import errorMessages from "../../constant/errorMessages";
import defaultValues from "../../constant/defaultValues";
import regExp from '../../constant/regExpressions';
import { showErrorMessage, showSuccessMessage } from '../../utils/CustomNotification';

import {
    getContactAction,
    updateContactAction,
} from "../../actions/updateProfileAction";

import InputField from '../InputField'
import ButtonAdd from '../ButtonAdd';
import Button from '../../components/Button';
import RadioButtonField from '../RadioButtonField';
import { hideLoading, showLoading } from "../../actions/loaderAction";
import ButtonAdminpanel from '../ButtonAdminpanel';

// import "react-toastify/dist/ReactToastify.css";

function ContectInfo(props) {
    const { id } = useParams();

    const { IGNORE_EMAILS } = defaultValues;
    const [array, setArray] = useState([0]);
    const [errors, setErrors] = useState({});
    const [allErrors, setAllErrors] = useState({});
    const [isButtonDisabled, setisButtonDisabled] = useState(false);
    const [inputFields, setInputFields] = useState([
        {
            salutation: "Mr",
            first_name: "",
            last_name: "",
            email: "",
            designation: "",
            phone: "",
            contact_type: "Referral"
        },
    ]);

    const initialRef = useRef(true);


    const handleAddContact = (event) => {
        if (array.length >= 2) {
            event.target.style.display = "none";
        }
        if (array.length < 3) {
            setArray((prev) => [...prev, array.length]);
            setInputFields([
                ...inputFields,
                {
                    salutation: "Mr",
                    first_name: "",
                    last_name: "",
                    email: "",
                    designation: "",
                    phone: "",
                    contact_type: "Referral"
                },
            ]);
        }
        if (array.length > 0) {
            let err = { ...allErrors };

            const updatedErrors = Object.keys(err).reduce((acc, key) => {
                if (!key.includes(array.length)) {
                    acc[key] = err[key];
                }
                return acc;
            }, {});
            setAllErrors(updatedErrors);

            let error = { ...errors };

            const updatedError = Object.keys(error).reduce((acc, key) => {
                if (!key.includes(array.length)) {
                    acc[key] = err[key];
                }
                return acc;
            }, {});
            setErrors(updatedError);

        }
    };

    const handleRemoveDiv = (idx) => {
        if (inputFields.length <= 3) {
            document.getElementById("addButton").style.display = "block";
        }
        if (inputFields.length > 1) {
            setArray((prev) => prev.filter((_, index) => index !== idx));
            setInputFields((prev) => prev.filter((_, index) => index !== idx));
        }
    };


    const fetchContactInfo = async () => {
        await props
            .getContact(id, "referal_reference")
            .then((response) => {
                setTimeout(() => {
                    if (!isEmpty(response)) {
                        setInputFields(response)
                    }
                }, 3000);
            })
            .catch((error) => {
                console.log(error);
                showErrorMessage("Something went wrong");
            });
    };

    const handleContactInfoChange = (e, idx) => {
        const { name, value } = e.target;
        const newInputFields = [...inputFields];
        newInputFields[idx][name] = value;
        setInputFields(newInputFields);
    };

    const handleContactPhoneChange = (val, idx) => {
        const newInputFields = [...inputFields];
        newInputFields[idx].phone = val;
        setInputFields(newInputFields);
    };

    const handleContactTypeChange = (e, idx, contact_type) => {
        const newInputFields = [...inputFields];

        if (contact_type === "Referral") {
            newInputFields[idx]["contact_type"] = "Referral";
        } else if (contact_type === "Reference") {
            newInputFields[idx]["contact_type"] = "Reference";
        }
        setInputFields(newInputFields);
    }

    const checkAllInputsEmpty = () => {
        setErrors({});
        const newErrors = {};
        let objValues = [];
        let objectValues2 = [];
        let tmpInputs = [...inputFields];
        tmpInputs.forEach((field, idx) => {
            let { salutation, contact_type, ...tmpfield } = field;
            objValues[idx] = Object.values(tmpfield);
            if (!isEmpty(objValues[idx].filter(n => n))) {
                objectValues2.push(idx + 1);
            }
        });

        if (isEmpty(objectValues2) === true) {
            tmpInputs.forEach((field, idx) => {
                newErrors[`first_name${idx}`] = errorMessages.FIELD_REQUIRED;
                newErrors[`phone_and_email${idx}`] = errorMessages.BOTH_EMAIL_OR_PHONE;
            });

            setAllErrors(newErrors);
        }

        return isEmpty(objectValues2);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validation()) {
            // console.log("here==");
            props.showLoading();
            if (checkAllInputsEmpty()) {
                // console.log("STATUS ===", checkAllInputsEmpty());
                // showErrorMessage("Please Fill up required fields");
            } else {
                let formData = null;
                await props
                    .updateContact(
                        id,
                        inputFields,
                        formData,
                        "Referral"
                    )
                    .then((response) => {
                        let newErrors = {};
                        setErrors({});
                        setAllErrors({});
                        if (response === true) {
                            showSuccessMessage("Update profile successfully...");
                            // setTimeout(() =>
                            //     window.location.reload()
                            //     , 3000);
                        }
                        // if (response.status === false) {
                        //     const result = Object.entries(response.error);
                        //     result.forEach(([key, value]) => {
                        //         if(key == 'email1' && key == 'phone1'){
                        //             if(value === 'The field is required'){
                        //                 newErrors['email1'] = 'Please fill out either email or phone number.'
                        //             }
                        //         } else{
                        //             newErrors[key] = value;
                        //         }

                        //     });
                        //     setErrors(newErrors);
                        // }
                        if (response.status === false) {
                            const result = Object.entries(response.error);
                            result.forEach(([key, value]) => {
                                if (key.startsWith('email') && value === 'The field is required') {
                                    newErrors[key] = errorMessages.BOTH_EMAIL_OR_PHONE;
                                }
                                else if (key.startsWith('phone') && value === 'The field is required') {
                                    newErrors[key] = '';
                                } else {
                                    newErrors[key] = value;
                                }
                            });
                            setErrors(newErrors);

                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        showErrorMessage("Something went wrong");
                    });
            }
            props.hideLoading();
        }
    }


    const validation = () => {
        setAllErrors({});
        const newErrors = {};
        let hasError = false;
        // const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const regExpEmail = regExp.email;

        inputFields.forEach((field, idx) => {

            if (field.first_name !== '') {
                const regExpression = regExp.name;
                const isValid = regExpression.test(field.first_name);

                if (!isValid) {
                    newErrors[`first_name${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
                    hasError = true;
                } else if (size(field.first_name) > 20) {
                    newErrors[`first_name${idx}`] = errorMessages.MAX_CHARACTERS_REACHED;
                    hasError = true;
                }
                if (field.email === '' && field.phone === '') {
                    //  newErrors[`email${idx}`] = errorMessages.FIELD_REQUIRED;
                    //  newErrors[`phone${idx}`] = errorMessages.FIELD_REQUIRED;
                    newErrors[`email${idx}`] = errorMessages.BOTH_EMAIL_OR_PHONE;
                    hasError = true;
                }
            }
            if (field.email !== '') {
                newErrors[`email${idx}`] = '';
                if ((field.first_name === '') || (field.phone === null)) {
                    newErrors[`first_name${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
                const currentEmail = field.email.split('@')[1];
                const isValid = regExpEmail.test(field.email);

                if (!isValid || IGNORE_EMAILS.includes(currentEmail)) {
                    newErrors[`email${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
                    hasError = true;
                }

            }
            if (field.phone !== '') {
                newErrors[`phone${idx}`] = '';
                if ((field.first_name === '') || (field.phone === null)) {
                    newErrors[`first_name${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
            }
            if (field.phone) {
                let mobileValue = field.phone;
                if (mobileValue.length > 0) {
                    if (!field.phone || !isValidPhoneNumber(field.phone)) {
                        newErrors[`phone${idx}`] = errorMessages.MOBILE_NUMBER_VALIDATION_MSG;
                        hasError = true;
                    }
                }

            }
            // if (field.phone) {
            //     let mobileValue = field.phone
            //     let numberWithoutCountryCode = mobileValue.replace(/^\+(\d{1,4})\s?/, '');
            //     numberWithoutCountryCode = numberWithoutCountryCode.replace(/\s/g, '');
            //     let lengthOfRemainingNumber = numberWithoutCountryCode.length;
            //     if(lengthOfRemainingNumber > 0) { 
            //         if (!field.phone || !isValidPhoneNumber(field.phone)) {
            //             newErrors[`phone${idx}`] = errorMessages.MOBILE_NUMBER_VALIDATION_MSG;
            //             hasError = true;
            //         }
            //     }
            //     if(lengthOfRemainingNumber == 0 && field.email == '') { 

            //             newErrors[`phone${idx}`] = errorMessages.FIELD_REQUIRED;
            //             hasError = true;

            //     }else{
            //         newErrors[`phone${idx}`] = '';
            //         hasError = false;
            //     }   
            // }


            if (field.designation) {
                const regExpression = regExp.designation;
                const isValid = regExpression.test(field.designation);

                if (!isValid) {
                    newErrors[`designation${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
                    hasError = true;
                } else if (size(field.designation) > 25) {
                    newErrors[`designation${idx}`] = errorMessages.MAX_CHARACTERS_REACHED;
                    hasError = true;
                }
            }

            if (field.last_name) {
                const regExpression = regExp.name;
                const isValid = regExpression.test(field.last_name);

                if (!isValid) {
                    newErrors[`last_name${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
                    hasError = true;
                } else if (size(field.last_name) > 20) {
                    newErrors[`last_name${idx}`] = errorMessages.MAX_CHARACTERS_REACHED;
                    hasError = true;
                }
            }

            if (field.last_name !== '' || field.designation !== '') {

                if (field.email === '' && field.phone === '') {
                    newErrors[`email${idx}`] = errorMessages.FIELD_REQUIRED;
                    newErrors[`phone${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
                if (field.first_name === '') {
                    newErrors[`first_name${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
            }

        });

        setErrors(newErrors);
        return !hasError;
    };


    const handleFocusOut = (e, idx) => {
        setAllErrors({});
        const { name, value } = e.target;
        let hasError = false;

        if (name === "first_name") {
            if (value !== '') {
                const regExpName = regExp.name;
                const isValid = regExpName.test(value);

                if (!isValid) {
                    hasError = true;
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`first_name${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                    }));
                } else if (size(value) > 20) {
                    hasError = true;
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`first_name${idx}`]: errorMessages.MAX_CHARACTERS_REACHED,
                    }));
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`first_name${idx}`]: '',
                    }));
                }
            } else {
                if (value.length > 0) {
                    hasError = true;
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`first_name${idx}`]: errorMessages.FIELD_REQUIRED,
                    }));
                }
            }
        }

        if (name === "last_name") {
            const regExpName = regExp.name;
            const isValid = regExpName.test(value);

            if (value.length > 0 && !isValid) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`last_name${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                }));
            } else if (value.length > 0 && size(value) > 20) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`last_name${idx}`]: errorMessages.MAX_CHARACTERS_REACHED,
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`last_name${idx}`]: '',
                }));
            }
        }

        if (name === "email") {
            const currentEmail = value.split('@')[1];
            // const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const regExpEmail = regExp.email;
            const isValid = regExpEmail.test(value);
            const isDuplicateEmail = inputFields.some((item, index) => item.email === value && index !== idx);

            if ((value.length > 0) && (!isValid || IGNORE_EMAILS.includes(currentEmail))) {

                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                }));
            } else if (value.length > 0 && isDuplicateEmail) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: errorMessages.UNIQUE_MSG,
                }));
            } else if (size(value) > 40) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: errorMessages.MAX_CHARACTERS_REACHED,
                }));
            } else {
                // if (value.length > 0 && !isDuplicateEmail) {
                //     setErrors((prevErrors) => {
                //         let updatedErrors = { ...prevErrors };
                //         Object.keys(updatedErrors).forEach((key) => {
                //             if (key.startsWith('email')) {
                //                 updatedErrors[key] = '';
                //             }
                //         });
                //         return updatedErrors;
                //     });
                // } else {
                //     setErrors((prevErrors) => ({
                //         ...prevErrors,
                //         [`email${idx}`]: '',
                //     }));
                // }
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: '',
                }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`phone${idx}`]: '',
                }));
            }
        }

        if (name === "designation") {
            const regExpDesignation = regExp.designation;
            const isValid = regExpDesignation.test(value);

            if (value.length > 0 && !isValid) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`designation${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                }));
            } else if (value.length > 0 && size(value) > 25) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`designation${idx}`]: errorMessages.MAX_CHARACTERS_REACHED,
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`designation${idx}`]: '',
                }));
            }
        }


        // if (name === 'phone') {
        //     // let mobileValue = value;
        //     // if (mobileValue) {
        //     //     if (!mobileValue || !isValidPhoneNumber(mobileValue)) {
        //     //         hasError = true;
        //     //         setErrors((prevErrors) => ({
        //     //             ...prevErrors,
        //     //             [`phone${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
        //     //         }));
        //     //     } else {
        //     //         setErrors((prevErrors) => ({
        //     //             ...prevErrors,
        //     //             [`phone${idx}`]: '',
        //     //         }));
        //     //         setErrors((prevErrors) => ({
        //     //             ...prevErrors,
        //     //             [`email${idx}`]: '',
        //     //         }));
        //     //     }
        //     // }

        //     const normalizePhone = (phone) => phone.replace(/\D/g, '');
        //     const isDuplicatePhone = inputFields.some((item, index) => {
        //         return normalizePhone(item.phone) === normalizePhone(value) && index !== idx;
        //     });

        //     if (value.length > 0 && isDuplicatePhone) {
        //         hasError = true;
        //         setErrors((prevErrors) => ({
        //             ...prevErrors,
        //             [`phone${idx}`]: errorMessages.UNIQUE_MSG,
        //         }));
        //     } else {
        //         if (value.length > 0 && !isDuplicatePhone) {
        //             setErrors((prevErrors) => {
        //                 let updatedErrors = { ...prevErrors };
        //                 Object.keys(updatedErrors).forEach((key) => {
        //                     if (key.startsWith('phone')) {
        //                         updatedErrors[key] = '';
        //                     }
        //                 });
        //                 return updatedErrors;
        //             });
        //         }

        //     }
        // }
        if (name === 'phone') {
            const normalizePhone = (phone) => {
                if (phone && typeof phone === 'string') {
                    return phone.replace(/\D/g, '');
                }
                return '';
            };

            const isDuplicatePhone = inputFields.some((item, index) => {
                const itemPhone = item.phone ? normalizePhone(item.phone) : '';
                return itemPhone === normalizePhone(value) && index !== idx;
            });

            if (value && value.length > 0 && isDuplicatePhone) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`phone${idx}`]: errorMessages.UNIQUE_MSG,
                }));
            } else {
                if (value && value.length > 0 && !isDuplicatePhone) {
                    setErrors((prevErrors) => {
                        let updatedErrors = { ...prevErrors };
                        Object.keys(updatedErrors).forEach((key) => {
                            if (key.startsWith('phone')) {
                                updatedErrors[key] = '';
                            }
                        });
                        return updatedErrors;
                    });
                }
            }
        }
        setisButtonDisabled(hasError);
        return hasError;
    };

    useEffect(() => {
        if (initialRef.current === true) {
            initialRef.current = false;
            fetchContactInfo();
        }


    }, []);

    const styleError = {
        display: 'block',
    };

    return (

        <form onSubmit={handleSubmit}>
            <h3 className='company-info-text'>Referral/Reference Info</h3>

            <div className='p-4 card-shadow bg-white rounded'>

                {/* ****contect-person 1**** */}

                <div>

                    <div className='d-flex justify-content-between align-items-center border-bottom pb-2'>

                        {inputFields.map((item, idx) => (
                            <div key={idx} id={idx}>
                                {idx === 0 && (
                                    <h4 className='head-office-text'>{item.contact_type} Info</h4>
                                )}
                            </div>
                        ))}

                        {inputFields.length < 3 && !props.disableProfile && (
                            <ButtonAdd className='add-btn' type='button' onClick={handleAddContact} id='addButton' disabled={props.disableProfile}>
                                <i className="fa-solid fa-circle-plus mx-1"></i>
                                <b>Add</b></ButtonAdd>

                        )}
                    </div>

                    {inputFields.map((item, idx) => (
                        <div key={idx} id={idx}>

                            {idx !== 0 && (

                                <div className='text-end d-flex justify-content-between align-item-center border-bottom mt-4 pb-2'>
                                    <h4 className='head-office-text'>{item.contact_type} Info</h4>
                                    {!props.disableProfile && (
                                        <ButtonAdd className='btn-remove ' type='button' onClick={() => handleRemoveDiv(idx)} disabled={props.disableProfile}>
                                            <i className="fa-solid fa-circle-minus mx-1"></i>
                                            <b>Remove</b>
                                        </ButtonAdd>
                                    )}
                                    {/* <ButtonAdd className='btn-remove ' type='button' onClick={() => handleRemoveDiv(idx)} disabled={props.disableProfile}>
                                        <i className="fa-solid fa-circle-minus mx-1"></i>
                                        <b>Remove</b>
                                    </ButtonAdd> */}

                                </div>
                            )}
                            <div className='row pt-3 reference-wrapper'>

                                <div className='col-xl-4 py-2'>

                                    <label className='profile-text'>First Name
                                        <span className='text-danger'> *</span>
                                    </label>

                                    <div className='d-flex'>

                                        <span className={`input-group-addon ${(props.disableProfile) ? `input-group-disable` : ``}`}>
                                            <select name="salutation" onChange={(e) => handleContactInfoChange(e, idx)} value={item.salutation} disabled={props.disableProfile}
                                                className={`${(props.disableProfile) ? `input-group-disable` : ``}`}
                                            >
                                                <option value="Mr">Mr</option>
                                                <option value="Mrs">Mrs</option>
                                                <option value="Miss">Miss</option>
                                            </select>
                                        </span>

                                        <div className='w-100'>
                                            <InputField
                                                name="first_name"
                                                placeholder={"Enter your first name"}
                                                className={"input-error-border mr-input"}
                                                value={item.first_name}
                                                onChange={(e) => handleContactInfoChange(e, idx)}
                                                onBlur={(e) => handleFocusOut(e, idx)}
                                                disabled={props.disableProfile}
                                                maxLength={20 + 1}
                                                autoComplete="off"
                                            />

                                        </div>

                                    </div>

                                    {errors[`first_name${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {errors[`first_name${idx}`]}
                                    </span>
                                    )}

                                    {allErrors[`first_name${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {allErrors[`first_name${idx}`]}
                                    </span>
                                    )}

                                </div>

                                <div className='col-xl-4 py-2'>
                                    <label className='profile-text'>Last Name
                                        {/* <span className='text-danger'>*</span> */}
                                    </label>
                                    <InputField
                                        name="last_name"
                                        placeholder={"Enter your last name"}
                                        className={"input-error-border"}
                                        value={item.last_name}
                                        onChange={(e) => handleContactInfoChange(e, idx)}
                                        onBlur={(e) => handleFocusOut(e, idx)}
                                        disabled={props.disableProfile}
                                        maxLength={20 + 1}
                                        autoComplete="off"
                                    />
                                    {errors[`last_name${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {errors[`last_name${idx}`]}
                                    </span>
                                    )}

                                </div>

                                <div className='col-xl-4 py-2'>
                                    <label className='profile-text'>Designation
                                        {/* <span className='text-danger'>*</span> */}
                                    </label>
                                    <InputField
                                        name="designation"
                                        placeholder={"Enter your designation"}
                                        className={"input-error-border"}
                                        value={item.designation}
                                        onChange={(e) => handleContactInfoChange(e, idx)}
                                        onBlur={(e) => handleFocusOut(e, idx)}
                                        disabled={props.disableProfile}
                                        maxLength={25 + 1}
                                        autoComplete="off"
                                    />
                                    {errors[`designation${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {errors[`designation${idx}`]}
                                    </span>
                                    )}

                                </div>

                                <div className='col-xl-4 py-2'>

                                    <label className='profile-text'>Email
                                        <span className='text-danger'> *</span>
                                    </label>
                                    <InputField
                                        name="email"
                                        placeholder={"Enter your email"}
                                        className={"input-error-border"}
                                        value={item.email}
                                        onChange={(e) => handleContactInfoChange(e, idx)}
                                        onBlur={(e) => handleFocusOut(e, idx)}
                                        disabled={props.disableProfile}
                                        maxLength={40 + 1}
                                        autoComplete="off"
                                    />

                                    {errors[`email${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {errors[`email${idx}`]}
                                    </span>
                                    )}

                                </div>

                                <div className='col-xl-4 py-2'>
                                    <label className='profile-text'>Mobile Number
                                        <span className='text-danger'> *</span>
                                    </label>

                                    <PhoneInput
                                        id="contact-form-mobile"
                                        name="phone"
                                        international limitMaxLength
                                        placeholder="Enter your number"
                                        onChange={(e) => handleContactPhoneChange(e, idx)}
                                        value={inputFields[idx]?.phone}
                                        onBlur={(e) => handleFocusOut(e, idx)}
                                        // disabled={props.disableProfile}
                                        className={`${props.disableProfile ? `contact-border-remove phone_disable` : ``}`}
                                        autoComplete="off"
                                    />
                                    {errors[`phone${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {errors[`phone${idx}`]}
                                    </span>
                                    )}

                                </div>

                                <div className='col-xl-4 py-2'>
                                    <label className='profile-text'>Contact Type
                                        <span className='text-danger'> *</span>
                                    </label>
                                    <div className='d-flex'>
                                        <div className='mt-2'>
                                            <RadioButtonField onChange={(e) => handleContactTypeChange(e, idx, "Referral")} checked={(item.contact_type === "Referral")} disabled={props.disableProfile} />
                                            <label className=''>Referral</label>
                                        </div>
                                        <div className='mt-2'>
                                            <RadioButtonField onChange={(e) => handleContactTypeChange(e, idx, "Reference")} checked={(item.contact_type === "Reference")} disabled={props.disableProfile} />
                                            <label className=''>Reference</label>
                                        </div>

                                    </div>
                                    <span className='company-required-content'>The field is required</span>

                                </div>

                                {allErrors[`phone_and_email${idx}`] && (<span className="company-required-content" style={styleError}>
                                    {allErrors[`phone_and_email${idx}`]}
                                </span>
                                )}

                            </div>






                        </div>
                    ))}

                </div>

                {!props.disableProfile && (
                    <div className='profile-btn mt-5 border-top'>

                        {/* <button className='back-home mx-3 mt-3'>Cancel</button> */}
                        <ButtonAdminpanel className="mt-4 btn-regi" type="submit" disabled={isButtonDisabled || props.disableProfile}>
                            Save Changes
                        </ButtonAdminpanel>
                    </div>
                )}

            </div>

        </form>


    )
}


const mapStateToProps = (state) => {
    return {
        getContact: state.update.getContact,
        updateContact: state.update.updateContact,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getContact: (member_id, contact_type) => dispatch(getContactAction(member_id, contact_type)),
        updateContact: (member_id, inputFields, formData, contact_type) =>
            dispatch(updateContactAction(member_id, inputFields, formData, contact_type)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContectInfo);

