import React, { useEffect, useState } from "react";
import adminDefaultImg from "../images/male-icon.png";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { connect } from "react-redux";
import { logoutAction } from "../actions/loginAction";
import defaultValues from "../constant/defaultValues";
import { includes, isEmpty } from "lodash";
function TopMenu(props) {
  const {
    isSuperAdmin,
    isEndUser,
    memberCompanyID,
    logout,
    user,
    company_plan,
    isPreKYCUser,
    company_status
  } = useAuth();

  const { IMAGE_PATH } = defaultValues;
  const account =
    user && user.company_logo
      ? IMAGE_PATH.BASE_URL + "/company_logo/" + user.company_logo
      : adminDefaultImg;



  const [c_status, setCompanyStatus] = useState(-1);
  const [inCompleteProfile, setInCompleteProfile] = useState(false);


  useEffect(() => {
    console.log("props.company_info ======== ", props);
    if (!isEmpty(props.company_info)) {
      setCompanyStatus(props.company_info.status);
      if (!includes([1, 10], props.company_info.status)) {
        setInCompleteProfile(true);
      } else {
        setInCompleteProfile(false);
      }
    } else {
      setCompanyStatus(company_status);
      if (!includes([1, 10], company_status)) {
        setInCompleteProfile(true);
      } else {
        setInCompleteProfile(false);
      }
    }


  }, [props.company_info, props.company_info.status, isPreKYCUser]);

  const Logout = async (e) => {
    await props
      .logoutAct()
      .then((response) => {
        if (response == true) {
          logout();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="bg-white p-2 topmenut-nav">
        <div className="d-flex justify-content-between mx-3">
          <div className="col-xxl-4 d-flex align-items-center">
            <i
              className="fa-solid fa-bars fs-3"
              onClick={() => {
                props.collapseSidebar();
              }}
            ></i>

            {/* <div className="form-group">
                                    <input type="email" className="form-control border-0 nav-input-search" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" />
                                </div> */}
          </div>

          <div className="col-xxl-2  d-flex align-items-center justify-content-end ">
            {/* <i className="fa-regular fa-bell text-danger fs-4 mx-3"></i> */}
            {
              user && user.role_slug.slug === "PRE_KYC" && inCompleteProfile && <div className="d-flex justify-content-end">
                <div className="list-group">
                  <Link to={`/updateprofile/${memberCompanyID}`} className="rounded">
                    <p className="list-group-item list-group-item-danger text-center">
                      Complete Your Profile
                    </p>
                  </Link>


                </div>
              </div>
            }

            <div className="nav-item navbar-dropdown dropdown-user dropdown mx-2 cursor-pointer">
              <p
                className="nav-link dropdown-toggle  nav-account-img"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {!isSuperAdmin && (
                  <img src={account} alt="logo" className="rounded-circle" />
                )}

                {isSuperAdmin && (
                  <img
                    src={adminDefaultImg}
                    alt="logo"
                    className="rounded-circle"
                  />
                )}
                {/* {isSuperAdmin && <img src={adminDefaultImg} alt="logo" className="rounded-circle" />} */}
              </p>

              <ul className="dropdown-menu dropdown-menu-end">
                <li className="py-2 dropdown-menu-border">
                  <div className="flex-grow-1 dropdown-item ">
                    <p className="profile-name">{user && user.name}</p>
                    <p className="m-0">{user && user.email}</p>

                    {!isSuperAdmin && (
                      <p className="m-0 subscription-plan">
                        {company_plan == null ? "(Free" : "(" + company_plan} - Plan)
                      </p>
                    )}
                  </div>
                </li>

                {!isSuperAdmin && (
                  <li className="dropdown-item dropdown-menu-border py-2">
                    <Link
                      to={`/updateprofile/${memberCompanyID}`}
                      reloadDocument
                    >
                      <span>My Profile</span>
                    </Link>
                  </li>
                )}

                {/* {!isSuperAdmin && (< li className="dropdown-item dropdown-menu-border py-2">
                                    <Link to={`/updateprofile/${memberCompanyID}`} reloadDocument>
                                        <span>{(company_plan == null) ? "Free" : company_plan} - Plan</span>
                                    </Link>
                                </li>)} */}

                <li
                  className="dropdown-item py-2 dropdown-menu-border"
                  onClick={Logout}
                >
                  {/* <button onClick={logout}>Log Out</button> */}
                  <span>
                    <i className="fa-solid fa-arrow-right-from-bracket me-1"></i>
                    Log Out{" "}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.logout,
    company_info: state.update.updateProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logoutAct: () => dispatch(logoutAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
