import React from 'react'

function Button({ children, style, onClick, className = "", type, ...props }) {
  return (

    <button
      type={type}
      className={`btn primary-btn ${className}`}
      style={style}
      onClick={onClick}
      {...props} >
      {children}
    </button>

  )
}

export default Button
